import React from "react";
import styled from "styled-components/macro";
import Fade from "react-reveal/Fade";
import { Helmet } from "react-helmet";

import { Header } from "../Components/Header";
import * as gvars from "../styles2/GlobalVariables";
import GlobalStyle from "../styles2/GlobalStyles";
import geometric from "../Images/geometric-bg.png";
import Section from "./../Components/Section";
import OpeningSection from "../Components/New/OpeningSection";
import ProductFeatureRow from "../Components/New/ProductFeatureRow";
import BannerRow from "../Components/New/BannerRow";
import Button from "../Components/Button";
import TriColumnFeaturesBlock from "../Components/New/TriColumnFeaturesBlock";
import TriColumnFeaturesSingleColumn from "../Components/New/TriColumnFeaturesSingleColumn";
import TestimonialBlock from "../Components/New/TestimonalBlock";
import ContactUsSection from "../Components/New/ContactUsSection";
import MockupIntroSection from "../Components/New/MockupIntroSection";

import Iphone from "../Components/Iphone";
import iphoneContent from "../Images/iphone-content.jpg";

/***
 * IMAGES
 */
import background from "../Images/bitmaps/agent-giving-keys.jpg";
import DevicesProductMockups from "../Images/illustrations/prsto-mockup.png";
import logo from "../Images/Product Logos/prsto-logo.png";
import DevelopmentPortalUI from "../Images/illustrations/development-portal.png";
import DevelopmentPortalUI2x from "../Images/illustrations/development-portal@2x.png";
import CustJourneyUI from "../Images/illustrations/cust-journey.png";
import CustJourneyUI2x from "../Images/illustrations/cust-journey@2x.png";
import DigitalContractIllustration from "./../Images/illustrations/digital-contract.svg";
import PlatformDesignPhoto from "./../Images/bitmaps/platform-design.jpg";
import TenantJourneyPhoto from "./../Images/bitmaps/couple-using-tech.jpg";
import PaperlessPhoto from "./../Images/bitmaps/paperless.jpg";

import Tenants from "./../Images/illustrations/tenants.svg";
import Agents from "./../Images/illustrations/agents.svg";
import Freeholders from "./../Images/illustrations/freeholder.svg";

const PrstoWrapper = styled.div``;

const Prsto = () => {
  return (
    <>
      <Header />
      <Fade>
        <PrstoWrapper>
          <OpeningSection bgImage={background} page={"prsto"}>
            <div className="orange-border">
              <img src={logo} />
            </div>
            <Fade>
              <h2 className="orange-border">
                From Marketing to Move-In, PRSto is a revolutionary next
                generation platform for build to rent and PRS developments
              </h2>
            </Fade>

            <Fade>
              <p>
                Manage new and existing tenants’ journeys with ease, through a
                fully digital, mobile ready, end-to-end experience designed to
                simplify and fast track the tenancy process.
              </p>
            </Fade>
          </OpeningSection>

          <MockupIntroSection>
            <img
              id="device-mockup-illustrations"
              src={DevicesProductMockups}
              alt="3 devices showing Dwellant's different product dashboards"
            />
            <h2>
              Developed to deliver residents a continuous high-end digital home
              experience.
            </h2>
            <Button href="#contact-us-section">Contact Us Now</Button>
          </MockupIntroSection>

          <h1 className="outer-grid desktop-inner-grid orange">
            Developed to offer agents and tenants one continuous high-end
            digital tenancy journey.
          </h1>

          <ProductFeatureRow>
            <div className="text-col">
              <h2>
                <span>1.</span>Market your development
              </h2>
              <p className="desktop-large">
                Integrated with marketing websites and property portals to
                showcase your development.
              </p>
            </div>

            <img
              className="image-col"
              style={{ opacity: "0.3 !important" }}
              src={DevelopmentPortalUI}
              srcSet={`${DevelopmentPortalUI2x} 2x`}
              alt="Booking Business Lounge UI"
            />
          </ProductFeatureRow>

          <ProductFeatureRow right>
            <div className="text-col">
              <h2>
                <span>2.</span>Manage their journey
              </h2>
              <p className="desktop-large">
                Guides tenants seamlessly through the application process and
                all key milestones
              </p>
            </div>

            <img
              className="image-col"
              src={CustJourneyUI}
              srcSet={`${CustJourneyUI2x} 2x`}
              alt="Customer Journey UI"
            />
          </ProductFeatureRow>
          <ProductFeatureRow>
            <div className="text-col">
              <h2>
                <span>3.</span>Maximise move-ins
              </h2>
              <p className="desktop-large">
                Fully digital process for signing of tenancy agreements,
                payments and managing move-ins.
              </p>
            </div>
            <img
              className="image-col"
              src={DigitalContractIllustration}
              alt="Digital Contract"
            />
          </ProductFeatureRow>

          <BannerRow>
            <h2>
              Delivering an engaging, personalised digital experience to the
              build to rent and private rental sector.
            </h2>
            <Button
              icon
              href="#contact-us-section"
              options={{ verticalOffset: -40 }}
              color="orange"
            >
              Contact Us Now
            </Button>
          </BannerRow>

          <TriColumnFeaturesBlock>
            <TriColumnFeaturesSingleColumn>
              <img
                src={PlatformDesignPhoto}
                className="img-row"
                alt="Photo tablet, mobile and laptop"
              />

              <div className="text-container">
                <h2>Platform Design</h2>
                <p className="large">
                  Designed to be engaging, modern and mobile-friendly.
                </p>

                <div className="text-row">
                  <h3>Key Functions</h3>
                  <p>
                    Fully integrated with both your existing development site
                    and property portals such as RightMove and Zoopla to
                    maximise marketing potential
                  </p>
                  <p>Responsive design to suit Android, iOS and PC</p>
                  <p>
                    Bespoke branding delivers a tailored experience for your
                    tenants
                  </p>
                  <p>Fully integrated with your accounting package</p>
                </div>
                <div className="text-row">
                  <h3>Product features</h3>
                  <p>Integrates with your online calendar, such as Outlook</p>
                  <p>Monitors every step of the tenant journey</p>
                  <p>
                    Enables concise communication at every stage of the process
                  </p>
                </div>
              </div>
            </TriColumnFeaturesSingleColumn>

            <TriColumnFeaturesSingleColumn>
              <img
                src={TenantJourneyPhoto}
                className="img-row"
                alt="Photo of elderly couple using tablet"
              />

              <div className="text-container">
                <h2>Tenant Journey</h2>
                <p className="large">
                  Tenants are guided through the entire application process.
                </p>

                <div className="text-row">
                  <h3>Key Functions</h3>
                  <p>
                    One place for tenants to add all their required information
                  </p>
                  <p>Bespoke property search to deliver personalised results</p>
                  <p>
                    Automatic prompts and reminders at all key stages of the
                    process
                  </p>
                </div>
                <div className="text-row">
                  <h3>Product features</h3>
                  <p>
                    Provides a user-friendly interface and transparent process
                    for the tenant
                  </p>
                  <p>
                    Allows agents to instantly check and track the progress of
                    each application
                  </p>
                  <p>
                    Simple and secure process to gather and store required
                    information
                  </p>
                </div>
              </div>
            </TriColumnFeaturesSingleColumn>

            <TriColumnFeaturesSingleColumn>
              <img
                src={PaperlessPhoto}
                className="img-row"
                alt="Photo of person using tech in abstract way"
              />

              <div className="text-container">
                <h2>Paperless Process</h2>
                <p className="large">
                  PRSto provides a secure, fully digital, paperless experience
                </p>

                <div className="text-row">
                  <h3>Key Functions</h3>
                  <p>
                    One place for tenants and agents to communicate and upload
                    information
                  </p>
                  <p>Take secure, instant, electronic payments</p>
                  <p>Digital signing of pre-tenancy and AST agreements</p>
                </div>
                <div className="text-row">
                  <h3>Product features</h3>
                  <p>
                    Easy to upload documents, and performs reference and right
                    to rent checks
                  </p>
                  <p>
                    Tenants can reserve a property and make instant payment for
                    holding deposits
                  </p>
                  <p>
                    Digital tenancy agreements with custom clauses and editable
                    areas
                  </p>
                </div>
              </div>
            </TriColumnFeaturesSingleColumn>
          </TriColumnFeaturesBlock>

          <BannerRow orange margin>
            <h2>
              PRSto transforms a time consuming and complex process into one,
              seamless experience for everyone
            </h2>
          </BannerRow>

          <ProductFeatureRow customerBenefits right>
            <img
              className="image-col"
              src={Tenants}
              alt="Residents illustration"
            />

            <div className="text-col">
              <h2>Tenants</h2>
              <ul className="big">
                <li>Any device, any place, anytime access</li>
                <li>Instant notifications</li>
                <li>Easy to use automated tenant journey</li>
                <li>Completely paperless process</li>
              </ul>
            </div>
          </ProductFeatureRow>

          <ProductFeatureRow customerBenefits left>
            <img className="image-col" src={Agents} alt="Staff illustration" />

            <div className="text-col">
              <h2>Agents</h2>
              <ul className="big">
                <li>Reduction in low-value tasks via enhanced self-service</li>
                <li>
                  Makes every step of tenant application clear and visible
                </li>
                <li>Provides automated messaging and prompts</li>
                <li>
                  Manage and approve viewings, applications and reservations
                </li>
                <li>Fully GDPR compliant</li>
              </ul>
            </div>
          </ProductFeatureRow>

          <ProductFeatureRow customerBenefits right>
            <img
              className="image-col"
              src={Freeholders}
              alt="Freeholers illustration shaking hands"
            />

            <div className="text-col">
              <h2>Developer</h2>
              <ul className="big">
                <li>Consistent and positive brand experience</li>
                <li>Integrated approach to marketing and branding</li>
                <li>Easy to view performance and KPI tools</li>
                <li>
                  Faster and more effective to reach full building occupancy
                </li>
              </ul>
            </div>
          </ProductFeatureRow>

          <ContactUsSection></ContactUsSection>
          <GlobalStyle />
          <Helmet>
            {/* <!-- Primary Meta Tags --> */}
            <title>PRSto || Dwellant</title>
            <meta name="title" content="PRSto || Dwellant" />
            <meta
              name="description"
              content="From Marketing to Move-In, PRSto is a revolutionary next generation platform for build to rent and PRS developments"
            />

            {/* <!-- Open Graph / Facebook --> */}
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://www.dwellant.com/" />
            <meta property="og:title" content="PRSto || Dwellant" />
            <meta
              property="og:description"
              content="From Marketing to Move-In, PRSto is a revolutionary next generation platform for build to rent and PRS developments"
            />
            <meta
              property="og:image"
              content="https://i.ibb.co/JyFcFXW/ogimage.jpg"
            />

            {/* <!-- Twitter --> */}
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content="https://www.dwellant.com/" />
            <meta
              property="twitter:title"
              content="Bespoke Buildings || Dwellant"
            />
            <meta
              property="twitter:description"
              content="From Marketing to Move-In, PRSto is a revolutionary next generation platform for build to rent and PRS developments"
            />
            <meta
              property="twitter:image"
              content="https://i.ibb.co/JyFcFXW/ogimage.jpg"
            />
          </Helmet>
        </PrstoWrapper>
      </Fade>
    </>
  );
};

export default Prsto;
