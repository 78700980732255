import React from 'react';
import styled from 'styled-components/macro';
import * as gvars from '../../styles2/GlobalVariables';

const ThumbnailBlockSingleStyled = styled.div`
	background-color: white;
	padding-bottom: ${gvars.spacing.mSpace};
	margin-left: auto;
	margin-right: auto;
	margin-bottom: ${gvars.spacing.lSpace};
	box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.09);
	.img-row {
		flex: 0 0 45%;
		background-image: url('${(props) => props.image && props.image}');
		background-size: cover;
		background-position: center;

		clip-path: polygon(0 0, 100% 0, 100% 75%, 0% 100%);
		margin-bottom: ${gvars.spacing.lSpace};
		height: 200px;
		object-fit: cover;
		width: 100%;
	}
	.text-container {
		padding: 0 ${gvars.spacing.mSpace};
	}
	h2 {
		text-transform: uppercase;
		color: ${gvars.colours.orange};
		margin-bottom: ${gvars.spacing.sSpace};
	}
	h3 {
		color: ${gvars.colours.orange};
		margin-bottom: 1rem;
	}
	h4 {
		color: ${gvars.colours.darkgrey};
		margin-bottom: 0.4rem;
	}
	p:not(.large) {
		color: ${gvars.colours.darkgrey};
	}
	p.large {
		margin-bottom: ${gvars.spacing.lSpace};
	}
	.text-row {
		margin-bottom: ${gvars.spacing.mSpace};
		padding-bottom: ${gvars.spacing.mSpace};
		border-bottom: 1px solid ${gvars.colours.offwhite};
		p:last-of-type {
			margin-bottom: 0;
		}
		&:last-of-type {
			margin-bottom: 0;
			border-bottom: none;
			padding-bottom: 0;
		}
	}
	.text-row.multiple-p {
		p {
			margin-bottom: 1.5rem;
		}
	}

	img.ISOCertificate {
		margin-top: ${gvars.spacing.mSpace};
	}

	.title-container,
	.subtitle-container {
		display: flex;
		align-items: center;
		margin-bottom: 1rem;
		img.icon {
			margin-right: 0.5rem;
			width: 25px;
			height: 25px;
			float: left;
		}
		h2,
		h4 {
			margin-bottom: 0;

			display: block;
		}
	}

	.title-container {
		margin-bottom: 1rem;
	}
	.subtitle-container {
		margin-bottom: 0.6rem;
	}
	.subtitle-container.only-title {
		margin-bottom: 1.2rem;
		&:last-of-type {
			margin-bottom: 0;
		}
	}

	/*************
 * DESKTOP QUERIES
*************/
	@media ${gvars.device.laptop} {
		display: flex;
		padding-bottom: 0;

		h2 {
			font-size: 1.25rem;
		}
		h3 {
			margin-bottom: 1.2rem;
		}
		.img-row {
			clip-path: none;
			height: inherit;
			margin-bottom: 0;

			width: 45%;
		}
		.text-container {
			padding: 3rem 3rem;
		}
		.text-row {
			padding-bottom: 0;
			border-bottom: none;
			margin-bottom: 2.2rem;
		}
		.text-row.multiple-p {
			p {
				margin-bottom: 1.4rem;
			}
			p:last-of-type {
				margin-bottom: 0;
			}
		}
	}

	@media ${gvars.device.desktopL} {
		h2 {
			font-size: 1.45rem;
		}

		.title-container,
		.subtitle-container {
			img.icon {
				width: 34px;
				height: 34px;
			}
		}
	}
`;

export default class ThumbnailBlockSingle extends React.Component {
	render() {
		return (
			<ThumbnailBlockSingleStyled image={this.props.image}>
				{this.props.children /* this injects the content from wherever its used as a HOC, see bespoke.js as an example or remove this to see */}
			</ThumbnailBlockSingleStyled>
		);
	}
}

// export default OpeningSection;
