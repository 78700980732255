import React from "react";
import Fade from "react-reveal/Fade";
import { Helmet } from "react-helmet";

import { Header } from "../Components/Header";
import Heading from "../Components/SubComponents/Heading";
import Highlight from "../Components/SubComponents/Highlight";
import Subheading from "../Components/SubComponents/Subheading";
import Row from "../Components/Row";
import Col from "../Components/Col";
import Section from "../Components/Section";
import Button from "../Components/Button";
import Item from "../Components/Item";
import Thumbnail from "../Components/Thumbnail";
import Logo from "../Components/Logo";
import Logos from "../Components/Logos";

import geometric from "../Images/geometric-bg.png";
import knightFrank2 from "../Images/Client Logos/knightfrank2.png";
import Iphone from "../Components/Iphone";
import iphoneContent from "../Images/iphone-content.jpg";

import DevicesProductMockups from "../Images/illustrations/home-opener-illustration2.png";
import ConnectingEveryone from "../Images/illustrations/connecting-everyone.svg";
import ClearComunication from "../Images/illustrations/clear-communication.svg";
import ConciergeIllustration from "../Images/illustrations/concierge-home-illustration.svg";
import OpeningSection from "../Components/New/OpeningSection";

const ResidentialBlockManagementSoftware = () => {
  return (
    <>
      <Header />
      <OpeningSection bgImage={geometric}>
        <h1 className="orange-border">
          Residential Block Management <span className="orange">Software</span>
        </h1>
        <p>
          Dwellant’s residential block management software is a holistic,
          cloud-based system for the 21st century. Like no other software, it
          brings together all stakeholders in a residential building or
          development.
        </p>
      </OpeningSection>

      <Section slim>
        <Row>
          <Col width="4" mobile="12">
            <img
              src={DevicesProductMockups}
              alt="Dwellant’s residential block management software is a holistic, cloud-based system for the 21st century."
            />
          </Col>
          <Col width="8" mobile="12">
            <Heading>
              <Highlight>Residential</Highlight> Block Management
            </Heading>
            <p>
              Residential Block Management represents a conveyor-belt of very
              specialised and high volume work for its practitioners. As a
              by-product of this intensive and often detailed activity, its
              customers can become disillusioned with their block management
              company and the sector as a whole.
            </p>
            <p>
              At Dwellant we have thought long and hard about how we can remove
              worries about the management of a residential block or a larger
              development properties, by creating residential block management
              software that is comprehensive and easy to use.
            </p>
            <p>
              Dwellant’s residential block management software has been designed
              for those who manage residential blocks, developments or schemes,
              and equally for those who live in apartments. It is equally
              intuitive and easy to use for the manager, the residents and those
              who provide services to the property. Its functionality has been
              developed after many years spent with property managers and
              residents to understand what is important to them. Our solution is
              a platform that is interactive, with multi-layer access levels,
              intuitive to use by anyone and an invaluable tool to make block
              management as easy as possible.
            </p>
            <img
              src={ConnectingEveryone}
              alt="Dwellant’s block management software is a holistic, cloud-based system for the 21st century like no other software."
            />
          </Col>
        </Row>
      </Section>

      <Section>
        <Row>
          <Col width="8" mobile="12">
            <Heading>
              What Does <Highlight>Dwellant</Highlight> Do?
            </Heading>
            <p>
              Dwellant’s residential block management software has features for
              leaseholders, property managers and those owning or managing a
              building. Residential block management requires a wealth of specific
              skills; it requires specialist expertise, first-class
              communication, consistently high energy levels, and in depth
              knowledge of property maintenance and its underlying legal
              framework. These requirements are brought together and put to work
              by the software.
            </p>
            <p>
              Multi functional user features make Dwellant’s residential block
              management software a pleasure to use. User configurable
              dashboards and reporting tools help manage day to day work tasks.
              Task management is linked to automated diary reminders and email
              alerts so light work is made of logging routine queries,
              maintenance management and health & safety compliance.
            </p>
            <p>
              Work instructions and maintenance contracts with third parties are
              issued by the block manager through Dwellant’s residential block
              management software. The work instruction process is simplified
              through automated reminders and email communication with all
              parties. Health and safety requirements in relation to a specific
              works instruction (risk assessments and method statements for
              instance) are also manageable through Dwellant. Contractor
              invoices are automatically matched to the original works orders,
              meaning payments are swift and simple. Invoice authorisation can
              be as simple or multi-layered as the block management company
              wishes.
            </p>
          </Col>
          <Col width="4" mobile="12">
            <img
              src={ClearComunication}
              alt="Dwellant’s residential block management software is a holistic, cloud-based system for the 21st century."
            />
          </Col>
        </Row>
      </Section>

      <Section slim invert>
        <Row>
          <Col width="3" mobile="12">
            <Heading>Block Management Software</Heading>
          </Col>
          <Col width="9" mobile="12">
            <Item
              stacked
              icon="bullhorn"
              heading="Communication"
              description="Communication with and from residents and leaseholders is easier than ever. The portal uses email alerts and SMS messaging so that all those registered on the website can receive updates and news as they are released. Residents can send maintenance requests or queries directly to the website, and these inquiries will be automatically distributed to the person best placed to deal with them. Swift and efficiently."
            />
            <Item
              stacked
              icon="file-invoice-dollar"
              heading="Service Charges"
              description="Access to service charge information and service charge payments have never been more transparent. Secure access levels allow for privacy and confidentiality. Information about service charge budgets and services provided by the residential management company can be easily found and reviewed."
            />
            <Item
              stacked
              icon="tasks"
              heading="Residential Block Management Software UK"
              description="Task management and the management of Health and Safety is an integral part of a property managers work. Dwellant provides dashboards and email alerts to remind of tasks, routine maintenance inspections and works."
            />
            <Button icon>Speak to us</Button>
          </Col>
        </Row>
      </Section>

      <Section>
        <Row>
          <Col width="8" mobile="12">
            <Heading>
              <Highlight>Smart</Highlight> Automated{" "}
              <Highlight color="green">Management</Highlight>
            </Heading>
            <p>
              Throughout the works ordering and invoicing/payment processes, all
              financial data is linked live to the service charge accounts
              software package that the block manager is using, so that works
              are not ordered without the requisite funds being in place. The
              best residential block management software packages have an open API
              engine.
            </p>
            <p>
              The management of health and safety frequency-driven tasks (for
              example, fire risk assessments) and resulting actions (for
              example, installation of signage) is a fundamental part of a block
              manager’s day to day work. Dwellant’s residential block management
              software provides dashboards and email alerts to remind of these
              tasks, routine maintenance inspections and actions that require
              quotations and works at the building.
            </p>
            <p>
              Communication with and from residents is easier than ever. For
              residents, our residential block management software is a web portal,
              accessible from any PC or smart device connected to the internet.
              Registration is straightforward and there are choices to make when
              receiving updates about the building. Email alerts and SMS are
              popular choices to receive news as it is released.
            </p>
            <p>
              All residents - not just leaseholders - are encouraged to sign up
              and use the Dwellant portal for their building. Residents can send
              maintenance requests or queries directly through the portal, and
              these enquiries will be distributed automatically to the person
              best placed to deal with them, swiftly and efficiently. Our
              residential block management software has been designed to be fully
              inclusive.
            </p>
          </Col>
          <Col width="4" mobile="12">
            <Iphone
              image={iphoneContent}
              alt="How Dwellant looks for residents on their mobile devices"
            />
          </Col>
        </Row>
      </Section>

      <Section>
        <Row>
          <Col width="8" mobile="12">
            <Heading>
              The Platform <Highlight>For You</Highlight>
            </Heading>
            <p>
              Access to service charge information and service charge payments
              has never been more transparent. Secure access levels allow for
              privacy and confidentiality. Information about service charge
              budgets and accounts, and indeed any of the services provided by
              the residential block management company can be easily found and
              reviewed through the Dwellant portal.
            </p>
            <p>
              Block management often involves the organisation and execution of
              complex projects. Such programmes of major works bring together
              professional groups (building, surveying, engineering) and
              residents groups, all of whom have to collaborate and communicate
              effectively together to ensure the success of the project.
              Dwellant provides the residential block management software platform
              to do just that.
            </p>
            <p>
              The best software is continually developed, through listening to
              user experiences and their breadth of knowledge. As our customers’
              businesses grow using Dwellant’s residential block management
              software, so do we. Our success is intrinsically linked to the
              success of our customers who come to rely on Dwellant. Our portal
              is dynamic so where new features are introduced to one customer,
              this is made available to all users.
            </p>
          </Col>
          <Col width="4" mobile="12">
            <img
              src={ConciergeIllustration}
              alt="Dwellant’s residential block management software UK is a holistic, cloud-based system for the 21st century."
            />
          </Col>
        </Row>
      </Section>

      <Section slim invert>
        <Row>
          <Col width="12">
            <Item
              icon="shield-alt"
              heading="ISO 27001"
              description="Software security is a top priority at Dwellant. Our ISO 27001 certification gives our clients and users the assurance that all data and user functionality is protected by the highest security features. Our in-house developers maintain Dwellant’s residential block management software and ensure security remains paramount."
            />
            <Item
              icon="key"
              heading="GDPR"
              description="Data Protection is addressed via a signed agreement between Dwellant and the client. This states that the client has the legal right to disclose all personal data that it discloses to Dwellant under or in connection with this agreement and further that the client shall fully comply with the Data Protection Act 1998 and GDPR Act 2016."
            />
            <Item
              icon="lock"
              heading="Secure"
              description="Dwellant warrants that it will act only on instructions of the client in relation to the processing of any Personal Data performed by Dwellant on behalf of the client. That  it has in place appropriate security measures (both technical and organisational) against unlawful or unauthorised processing of Personal Data. The web service is protected using security systems and technologies which include a Hardware Firewall configured with the minimum standard rule set and a software firewall configured to protect systems and restrict traffic to the minimum protocols. Further is administrative access given through a secure site and appropriate rules for password security enabled. Regular security monitoring and configuration checks are carried out. Dwellant provides market-leading residential block management software to ensure your and your clients’ data is handled correctly."
            />
            <Button icon>Speak to us</Button>
          </Col>
        </Row>
      </Section>
      <Helmet>
        <title>Residential Block Management Software || Dwellant</title>
        <meta
          name="description"
          content="Dwellant’s residential block management software is a holistic,
                cloud-based system for the 21st century. Like no other software,
                it brings together all stakeholders in a residential building or
                development."
        />
      </Helmet>
    </>
  );
};

export default ResidentialBlockManagementSoftware;
