export const colours = {
  orange: "#f89743",
  green: "#5ba63f",
  grey: "#404040",
  darkgrey: "#787878",
  white: "#ffffff",
  offwhite: "#ececec",
  offblack: "#1d1d1d",
  black: "#000000",
};

export const sizes = {
  sSize: "1rem",
  mSize: "1.125rem",
  lSize: "1.25rem",
  xlSize: "1.625rem",
};

export const spacing = {
  ssSpace: "0.5rem",
  sSpace: "1rem",
  mSpace: "1.8rem",
  lSpace: "2.4rem",
  xlSpace: "3.9rem",
};

// This doesn't get used directly and is only used for the 'device' const below
export const size = {
  mobileM: "360px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1030px",
  laptopL: "1440px",
  desktopL: "1920px",
  desktopXL: "2560px",
};

export const device = {
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktopL: `(min-width: ${size.desktopL})`,
  desktopLL: `(min-width: ${size.desktopXL})`,
};
