import React from 'react';

import styled from 'styled-components/macro';
import * as gvars from '../../styles2/GlobalVariables';

const MockupIntroSectionStyled = styled.div`
	z-index: 2;
	${(props) => props.offset && 'margin-top: -10rem;'}

	position: relative;
	text-align: center;
	margin-bottom: ${gvars.spacing.xlSpace};
	padding-bottom: ${gvars.spacing.xlSpace};
	border-bottom: 1px solid ${gvars.colours.offwhite};
	h1 {
		margin-bottom: ${gvars.spacing.sSpace};
		color: ${gvars.colours.orange};
	}
	h2 {
		margin-bottom: ${gvars.spacing.sSpace};
	}
	#device-mockup-illustrations {
		margin-bottom: ${gvars.spacing.mSpace};
	}

	@media ${gvars.device.tablet} {
		${(props) => props.offset && 'margin-top: -15rem;'}
	}

	@media ${gvars.device.laptop} {
		${(props) => props.offset && 'margin-top: -20%;'}
		p.desktop-inner-grid {
			margin-left: auto;
			margin-right: auto;
		}
	}
`;

export default class MockupIntroSection extends React.Component {
	render() {
		return (
			<MockupIntroSectionStyled offset={this.props.offset} className={`outer-grid ${this.props.className}`}>
				{this.props.children /* this injects the content from wherever its used as a HOC, see bespoke.js as an example or remove this to see */}
			</MockupIntroSectionStyled>
		);
	}
}
