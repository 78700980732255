import React from 'react';
import Icon from './Icon';
import Heading from './SubComponents/Heading';

const Item = (props) => {
    let classNames = props.className ? [props.className] : [];

    if (props.stacked) {
        classNames.push(`item--stacked`)
    }

    const iconStyles = {
        fontSize: props.iconSize || "3rem",
    };

    const classNamesString = classNames.join(" ");

    return (
        <div className={`item ${classNamesString}`}>
            <div className="flex-align-center">
                {props.icon && (
                    <Icon fw name={props.icon} className="item-icon" color={props.color || "highlight"} style={iconStyles} />
                )}
                <div className="item-content">
                    <Heading size="h3" color={props.color ? props.color : "highlight"} className={`item__heading ${props.stacked}`}>{props.heading}</Heading>
                    {!props.stacked && (
                        <div className="item__description">
                            {props.description}
                            {props.children}
                        </div>
                    )}
                </div>
            </div>
            {props.stacked && (
                <div className="item__description">
                    {props.description}
                    {props.children}
                </div>
            )}
            {props.stage && (
                <Icon name="arrow-down" size="1.4rem" color="grey" className="item__arrow-down" />
            )}
        </div>
    )
}

export default Item;