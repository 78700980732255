import React from 'react';
import styled from 'styled-components/macro';
import * as gvars from '../../styles2/GlobalVariables';

import Input from '../Input';

import Boxes from '../../Images/boxes.png';

const PackageCalculator2Styled = styled.div`
	text-align: center;
	margin-top: 5rem;
	padding-top: 5rem;
	border-top: 1px solid ${gvars.colours.offwhite};
	.packageCalculator {
		position: relative;
		background: none;
		max-width: 400px;
		margin-left: auto;
		margin-right: auto;
		text-align: center;
		padding: 0;
		padding-top: 1.4rem;
		h4 {
			margin-bottom: 0.5rem;
		}
	}
	h2.title {
		margin-bottom: 0.5rem;
	}
	input {
		border: 1px solid #e8e8e8;
	}
	button {
		width: 100%;
	}
	.boxes {
		margin-left: auto;
		margin-right: auto;
		left: 0;
		right: 0;
		bottom: -4rem;
		margin-top: 2rem;
		margin-bottom: 5rem;
	}

	.results-box {
		position: absolute;
		z-index: 2;
		bottom: 12rem;
		background: white;
		box-shadow: 0px 3px 13px 0px rgba(0, 0, 0, 0.08);
		padding: 1rem 3rem;
		opacity: 0;
		transition: opacity 0.4s;
		h2 {
			color: ${gvars.colours.orange};
			margin-bottom: 0.6rem;
		}
		h3 {
			text-transform: none;
		}
		p {
			margin-bottom: 0.2rem;
		}
	}
	@media ${gvars.device.laptop} {
		max-width: 500px;
	}
`;
export default class PackageCalculator2 extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			result: 0,
			units: '',
		};
	}

	onComponentDidMount() {}

	calculateUnits = (e) => {
		const units = e.target.value;
		this.setState(() => ({ units: units }));
	};
	calculateResult = () => {
		const result = this.state.units * 8;
		this.setState(() => ({ result: result }));
	};
	render() {
		return (
			<PackageCalculator2Styled className="outer-grid">
				<h2 className="title">How many packages will you need to process this year?</h2>
				<p>Dwellant processed 2.4 million packages in 2020. How many packages will your building need to process in 2021?</p>
				<div className={`packageCalculator form`}>
					<h4>How many units are in your development?</h4>
					<Input type="text" className="packageCalculator__input" value={this.state.units} onChange={this.calculateUnits} icon="building" name="Units" />
					<button className="btn btn--icon" onClick={this.calculateResult}>
						Calculate
						<i class="fa fa-fw fa-caret-right" style={{ fontSize: '1.25rem', marginLeft: '8px' }} aria-hidden="true"></i>
					</button>
					<div className="results-box" style={{ opacity: this.state.result ? 1 : 0 }}>
						<p>Your building will need to process approximately:</p>
						<h2>{this.state.result} packages</h2>
						<h3>This year</h3>
					</div>
					<img className="boxes" src={Boxes} alt="Boxes" />
				</div>
			</PackageCalculator2Styled>
		);
	}
}
