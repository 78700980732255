import React from "react";
import geometric from "../Images/geometric-bg.png";
import Fade from "react-reveal/Fade";

import { Header } from "../Components/Header";
import Heading from "../Components/SubComponents/Heading";
import Highlight from "../Components/SubComponents/Highlight";
import Subheading from "../Components/SubComponents/Subheading";
import Row from "../Components/Row";
import Col from "../Components/Col";
import Section from "../Components/Section";
import Button from "../Components/Button";
import OpeningSection from "../Components/New/OpeningSection";

import { Helmet } from "react-helmet";

import DevicesProductMockups from "../Images/illustrations/home-opener-illustration2.png";

const PropertyAssetManagementSoftware = () => {
  return (
    <>
      <Header />
      <OpeningSection bgImage={geometric}>
        <h1 className="orange-border">Property Asset Management <span className="orange">Software</span></h1>
        <p>
          Dwellant’s software is a holistic, cloud-based system for the
          21st century. Like no other property asset management software
          Dwellant brings together all stakeholders in a residential
          building or development with intelligence and elegance.
        </p>
      </OpeningSection>
      <Section>
        <Row>
          <p>
            Managing the assets within a building can be a daunting task,
            particularly with new built developments featuring more and more
            complex mechanical installations. The demands on those responsible
            for multi-occupancy building maintenance are ever increasing.
            Newly built developments often have integral, complex heating and
            cooling networks, expensive plant and equipment, the task of
            managing these and keeping them well looked after and run
            efficiently and economically falls to the property professionals.
            Today’s health and safety and sustainability compliance requires
            detailed technical and legislative knowledge of those who maintain
            property.
          </p>

          <p>
            At Dwellant, we thought long and hard about how we can assist with
            streamlining asset maintenance processes, reducing the stress
            associated with its high volume and high expectation nature. We
            created a system that provides a market-leading property asset
            management software - an all-encompassing, including platform that
            is genuinely easy to use.
          </p>

          <img
            src={DevicesProductMockups}
            alt="Dwellant’s property asset management software is a holistic, cloud-based system for the 21st century."
          />

          <p>
            Our property asset management software has been specifically
            developed for those who manage commercial and residential property
            with a multitude of assets. Dwellant is equally intuitive and easy
            to use for the building’s manager (on-site and off-site), the
            occupants (e.g. residents, commercial tenants) and any maintenance
            service providers. Its functionality has been developed after many
            years spent with property professionals, to understand what is
            important to them. Our solution is a platform that is interactive,
            with multi-layer access levels, intuitive to use by anyone and an
            invaluable tool to make building maintenance as easy as possible.
          </p>

          <p>
            Dwellant understands the demands on the property manager and the
            needs of building developers, leaseholders, tenants and suppliers
            and has created a property asset management software that is
            versatile, simple to use and dynamic.
          </p>

          <p>
            Property asset management software like Dwellant’s in-the-cloud
            platform, features crucial elements for enabling digital
            transformation in the building manager’s workplace.{" "}
          </p>

          <p>
            Dwellant’s cloud-based Core property asset management software can
            revolutionise how the property management team delivers high
            volume maintenance and management, ultimately benefiting all of
            the building’s stakeholders.
          </p>

          <h3>Workflow and Task Management </h3>
          <p>
            The engine room of Dwellant powers a wide range of crucial
            workflow and task management activities. For the building
            maintenance and management team, this is their go-to platform for
            routine duties and longer term planning. This ‘core’ of the
            software presents itself to the building or property manager as a
            dashboard, clearly showing upcoming tasks, those overdue and tasks
            completed. Nothing is left to memory or to chance, so maintenance
            tasks are never unknowingly neglected.{" "}
          </p>
          <p>
            Maintenance items can originate from many sources, including a
            report from a resident or tenant, a site inspection by a property
            manager or building manager, or from a third party contractor.
            However the item is reported, it will be logged on Dwellant and
            seen through to completion. A tenant reporting a maintenance issue
            on Dwellant will be kept up to date via the property asset
            management software, all the way to its resolution. Similarly, a
            contractor noticing a maintenance issue may be assigned the task
            of resolving it.
          </p>
          <p>
            Once a maintenance issue is reported and recorded on property
            asset management software the building tenants can follow what is
            happening with the repair. Updates by the property team and
            contractor can be made visible so to provide peace of mind to the
            tenants that maintenance is in progress. The progress report and
            lists of all maintenance activities can easily be downloaded and
            reviewed for performance measurement. Tenant Associations,
            Residential Management Boards and Landlords can have access to
            shared maintenance statistics and reports, thus always have
            visibility of management activity.
          </p>

          <h3>Expenditure control</h3>
          <p>
            Spending service charge money on building maintenance has to be
            closely monitored, not least as expenditure will need to fall
            within funds available for the year. With budgetary constraints
            and statutory expenditure limits ( as per Section 20 of the
            Landlord and Tenant Act) also programmed into Dwellant, building
            managers are able to stay on the right side of landlord and tenant
            legislation. ‘Section 20’ expenditure limits relating to
            qualifying works and qualifying long term agreements are
            automatically and continuously monitored by the property asset
            management software, for every invoice, for every contract and for
            every project. A clever flagging system prompts the manager to
            validate expenditure and reminds of purchasing limits.
          </p>

          <h3>Health & safety built in</h3>
          <p>
            Property asset management software without in-built health and
            safety and compliance capabilities may lead to unsafe working
            practices with potentially adverse results. Dwellant has an
            integrated option to link risk assessments and method statements
            to the work order process. This way on-site and office staff as
            well as contractors can all access and review documents as needed.
            Relevant permit to work documents can be issued quickly and
            accurately through the property asset management software.
          </p>
          <p>
            Dwellant’s platform allows maintenance contractors to view any
            identified health and safety hazards on site, including the
            presence of asbestos and any unprotected areas. The health and
            safety information is held on the property asset management
            software for all relevant parties to see and share.
          </p>
          <p>
            The ‘frequent event’ function for routine surveys and patrol
            checks provides the team on-site with structure to their working
            day. Whether these are for common parts site inspections, security
            patrols, or health and safety checks, site managers can download a
            monthly report for onward transmission to head office management
            and ultimately to any other party who requires them. The results
            of such inspections and surveys provide useful data for regular
            tool-box talks and training. Any reports are immediately
            accessible and downloadable from Dwellant’s property asset
            management software thus ideal to be used for team, client or
            contractor meetings.
          </p>
          <p>
            A health and safety dashboard allows a building manager to see
            outstanding health and safety tasks and actions, whether they
            relate to fire safety, water hygiene, asbestos, utility safety or
            working at height. Actions resulting from risk assessments,
            surveys and inspection reports are extracted by the property asset
            management software and the necessary parties can view them,
            delegate them, mark them as ‘in progress’ and close them off once
            completed. The use of one system for on-site as well as off-site
            management means delegation to those at the coal face helps to
            spread the workload and accelerate its completion. As necessary,
            risk assessors themselves, the fire service and others can be
            given access to this Dwellant dashboard to view the courses of
            action prior to a health and safety incident or an accident.
            Near-misses as well as accidents can be recorded on Dwellant, to
            aid safer working practices in the future. Automated, repeat
            messages from the property asset management software to team
            members facilitate the collation of such data for internal
            statistical analysis, safety reporting and prompting of actions
            outstanding.{" "}
          </p>

          <h3>Contractor management</h3>
          <p>
            Supply chain management is one of Dwellant’s most popular
            functions amongst maintenance managers. Valued contractors are
            provided with a login to use Dwellant to receive works orders.
            Firstly, they need to provide the necessary accreditation and
            certification, which they can do electronically on the platform.
            The onus is on the contractors to update their credentials as
            often as they are renewed, usually annually. Once approved,
            contractors are then able to receive and progress quotation
            requests and work orders. Annual regular maintenance contracts are
            also managed by the property asset management software. Automated
            reminders prompt the management team for contract renewals and
            reviews. Renewal is simple, and quotes for contracts are equally
            easy to obtain and accept. The property asset management software
            retains the contract history so the manager can review previous
            suppliers, prices and terms.
          </p>

          <h3>Intelligent invoice processing</h3>
          <p>
            Not all property asset management software can complete the
            circle. Dwellant does with aplomb. Once a maintenance contractor
            has completed a job, he can bring up the job on Dwellant, mark it
            as complete, upload any proof as required (e.g. a photograph) and
            upload an invoice there and then. The invoice - which should be
            coded perfectly as it was done when the order was placed - goes
            directly into the building manager’s workflow. The contractor can
            even email his invoice and it will be auto-read, matched
            automatically to the corresponding works order and key data
            populated instantly, all by our AI engine. The contractor need not
            do any data entry. No paper. No postage. No lost invoices - the
            app does all the hard work. At any time, the contractor can see on
            the dashboard of the property asset management software the status
            of his payment - so no chasing is required.
          </p>
          <p>
            From the property manager’s point of view, Dwellant’s prescriptive
            but tried-and-tested systems ensure invoices are coded to the
            correct funds, schedules and nominal codes. Once the property or
            building manager has approved them, they are endorsed by a
            superior or superiors ahead of payment. Throughout, communication
            is facilitated through messaging flags carrying crucial
            information to allow approval and finally invoice payment. The
            property asset management software is monitoring input error too.
            Where invoice amounts differ from contracted sums or work order
            details, this is automatically flagged for further scrutiny.
          </p>
          <p>
            For regular and predictable building maintenance, contracts can be
            set up on Dwellant, thereby ensuring invoices are auto-approved
            (within set expenditure tolerances) and go directly to the
            accounts department for payment. The data from all invoices
            processed in Dwellant are seamlessly uploaded to the building
            maintenance manager’s back-end accounts system ready for physical
            payment to the supplier.{" "}
          </p>
          <p>
            Dwellant has provided its customers with a property asset
            management software that handles maintenance issue reporting
            through to invoice payment and everything in between.
          </p>
          <h3>On-site staff</h3>
          <p>
            Arguably the most important resource paid for by the service
            charge payers is the on-site staff. They are customer facing and
            usually kept very busy, especially with maintenance related tasks.
            Dwellant provides a property asset management software that
            ensures the on-site staff are genuinely connected to the overall
            management of the building. The use of our software means one
            system, coordinating the efforts of on-site and office based
            staff. It means office based property managers can delegate tasks
            to maintenance focussed site staff, who can then liaise directly
            with the contractors. In this day and age of on-site staff running
            the show, the greater the investment in the site staff, the less
            stressed the property manager becomes and the better service the
            residents will receive.
          </p>

          <h3>Residents and tenants</h3>
          <p>
            Whether a property manager is managing homes or workplaces, he/she
            will be dealing with demanding owners and occupiers who expect
            maintenance issues to be dealt with efficiently and the cost of
            resolution reasonable. Dwellant’s property asset management
            software facilitates a close relationship between building
            management and its tenants.
          </p>
          <p>
            Dwellant’s property asset management software gives users the
            ability to be self-sufficient. Why spend time on the phone with a
            property manager when you can report a maintenance issue through
            the platform and enable others in the building to see that they
            need not raise the same issue? To report such a maintenance issue,
            a user can simply send an email to the platform which in turn
            creates a task for the property manager. The tasks are visibly
            logged and can be tracked by those who need to act or need to know
            about the progress of the maintenance issue.
          </p>
          <p>
            Commonly asked questions - maintenance related or otherwise - can
            be answered via an online residents’ guide, a guide that is
            constantly updated and brings to life the wider, local community.
          </p>
          <p>
            Residents and commercial tenants can access their building’s
            branded platform from anywhere, anytime, from a PC or smart device
            connected to the internet. This means that they can keep up to
            date with news, important messages or progress with building
            works. The maintenance team has the ability through the platform
            to issue emergency notifications via email or SMS, thus alerting
            of any incident or important update in real time.
          </p>
          <h3>Reporting and KPIs</h3>
          <p>
            The system holds a huge capacity for reporting downloads and
            uploads on all levels. Senior management and operational managers
            alike can view maintenance activity and performance against set
            targets live, and can download reports across different business
            sectors with data analysis tools, ready for presentations or group
            reporting.
          </p>
          <p>
            On a building level, the on-site estate manager can see live
            activities and outstanding maintenance tasks for the team. Simple
            metrics reports keep the manager updated on critical maintenance
            and performance statistics.
          </p>
          <p>
            The property manager will be interested in statistics to monitor
            his own performance and identify areas for improvement. The system
            permits managers to keep an eye on their maintenance and other
            KPIs by running status reports on day to day activities as well as
            specific business focuses.
          </p>

          <p>
            Further operational metrics reporting relates to the number of
            open maintenance. There will always be some open requests as most
            jobs take more than a day to complete (especially if waiting time
            is included).
          </p>
          <p>
            Reports can also be seen on the open requests, their content and
            for how many days they have been kept open. This can provide a
            picture of the efficiency of the individual manager or the team,
            and the speed of open requests, average age in days the request
            has been worked on.
          </p>
          <p>
            Most buildings have events that are repeated at regular intervals
            e.g. fire alarm tests, lift alarm test, boiler maintenance etc.
            The key events are set up on the property asset management
            software when the property comes online. They can be added to and
            edited as needed. Each repeated event has a date and time and
            reminders are issued by the system to alert when an event is
            upcoming or overdue. Events can be simply closed by responding to
            the system email and for example attaching the relevant
            maintenance report, or by closing the event within the system. All
            documentation relating to the event and maintenance can be
            attached to the file, then retrieved or viewed by all staff or
            managers or surveyors. Property managers often use the property
            asset management software to send updates and newsletters to
            leaseholders, residents or staff.{" "}
          </p>

          <h3>On-site Tools</h3>
          <p>
            Dwellant plug-in CSite allows property managers and on-site staff
            to conduct site surveys on a tablet. Actions populate Dwellant
            upon synchronisation. Csite is the ultimate property asset
            management software tool for property and estate managers. Once
            you have set up a template for each building, there are three
            simple steps to complete.
          </p>
          <ol>
            <li>
              Survey: when you carry out a site visit, you can now complete
              your survey via smartphone or tablet, adding photos directly to
              your notes.
            </li>
            <li>
              Synchronise: when online, synchronise with Dwellant and actions
              appear, ready for delegation.
            </li>
            <li>
              Report: a site visit report is created and available in Word,
              PDF and CSV formats. The documents and photos are always
              available for audit checks.
            </li>
          </ol>
          <p>
            The Csite site inspector operates anywhere with offline use. The
            app is downloaded on a phone or tablet and is ready to use. Using
            the app saves valuable time for the site surveyor. The survey and
            report are geo-location tracked and logged immediately, saving 90%
            office admin time. All data be stored where you can make it work
            for you and your clients.
          </p>
          <p>
            The management tasks following on from your site visit are simple.
            The Csite app turns tasks into work orders there and then. The app
            and its functions are easy to learn and use. New staff are swiftly
            trained to use the tool. Csite ensures consistency of data across
            all teams and buildings. It provides safe and secure storage of
            surveys and reliable end-to-end reporting.
          </p>
          <p>
            Csite works on any Android and IOS device; it removes all need for
            site inspection write ups. The inspector can work offline and
            synchronise the report and all photos and data once back in
            reception range or back in the office. Questions linked to assets
            and historic asset data can be saved and followed up easily, and
            any work orders that result from the queries can be issued
            immediately. The app allows the creation of template content and
            specific questions to ensure continuity across your portfolios.
          </p>
          <p>
            A simple link to the Dwellant portfolio allows integration and
            seamless, automatic reporting of all on-site issues. Defects
            management has never been so easy. Geo and time stamp validate the
            report and make it usable for explicit uses including witness
            statements. Once the site survey is finalised, instructions are
            ready to be assigned to the contractor. The synchronised content
            from the site inspections is kept ready on Dwellant to be expanded
            on or shared with others.{" "}
          </p>

          <h3>
            The property asset management software software for training
          </h3>
          <p>
            All onsite staff will need to be aware of the requirements for a
            building’s maintenance. To teach knowledge and awareness the team
            will require guidance and training. Dwellant’ s property
            management inspection app can serve as a training instrument that
            gets engagement and attention from all staff. The app can
            facilitate the on-site staff’s genuine interested in the
            building’s good maintenance and provision of services and their
            positive input in the overall management of the building. The use
            of the property management inspection app will bring together the
            efforts of both on-site and office based staff for the benefit of
            the property and its stakeholders. It means office based property
            managers can through the use of the property management inspection
            app delegate tasks to maintenance focussed site staff, who can
            then liaise directly with the contractors as and when needed. In
            this day and age of on-site staff being the heart and soul of a
            development, the greater the investment in the site staff, the
            less stressed the property manager becomes and the better service
            the residents and the landlord will receive. The Csite property
            asset management software is your platform for achieving this
            goal.
          </p>

          <h3>Security and Data Protection </h3>

          <p>
            Software security is a top priority at Dwellant. Our ISO 27001
            certification gives our clients and users the assurance that all
            data and user functionality is protected by the highest security
            features. Our in-house developers maintain Dwellant’s suite of
            products, ensuring security remains paramount.
          </p>
          <p>
            Data Protection is addressed via a signed agreement between
            Dwellant and the client. This states that the client has the legal
            right to disclose all personal data that it discloses to Dwellant
            under or in connection with this agreement and further that the
            client shall fully comply with the Data Protection Act 1998 and
            GDPR Act 2016.{" "}
          </p>
          <p>
            Dwellant warrants that it will act only on instructions of the
            client in relation to the processing of any Personal Data
            performed by Dwellant on behalf of the client. That it has in
            place reasonably appropriate security measures (both technical and
            organisational) against unlawful or unauthorised processing of
            Personal Data. The web service is protected using security systems
            and technologies which include a Hardware Firewall configured with
            the minimum standard rule set and a software firewall configured
            to protect systems and restrict traffic to the minimum protocols.
            Further is administrative access given through a secure site and
            appropriate rules for password security enabled. Regular security
            monitoring and configuration checks are carried out. Dwellant
            provides market-leading software to ensure your and your clients’
            data is handled correctly.{" "}
          </p>
          <p>
            Dwellant’s software is a holistic, cloud-based system for the 21st
            century. Like no other property asset management software Dwellant
            brings together all stakeholders in a residential building or
            development with intelligence and elegance.{" "}
          </p>
        </Row>
      </Section>
      <Helmet>
        <title>Property Asset Management Software || Dwellant</title>
        <meta
          name="description"
          content="Dwellant’s software is a holistic, cloud-based system for the 21st century. Like no other property asset management software Dwellant brings together all stakeholders in a residential building or development with intelligence and elegance."
        />
      </Helmet>
    </>
  );
};

export default PropertyAssetManagementSoftware;
