import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import "./styles/App.scss";
import Index from "./Pages/Index";
import Bespoke from "./Pages/Bespoke";
import ConciergeHv from "./Pages/ConciergeHv";
import Contractors from "./Pages/Contractors";
import CSite from "./Pages/CSite";
import Dwellant from "./Pages/Dwellant";
import Prsto from "./Pages/Prsto";
import Team from "./Pages/Team";
import Vision from "./Pages/Vision";
import XeroSignup from "./Pages/XeroSignup";
import Xero from "./Pages/Xero";
import Footer from "./Components/Footer";
import Playground from "./Pages/Playground";
import ResidentialBlockManagementSoftware from "./SeoPages/ResidentialBlockManagementSoftware";
import BuildingManagementSoftware from "./SeoPages/BuildingManagementSoftware";
import CommercialPropertyManagementSoftware from "./SeoPages/CommercialPropertyManagementSoftware";
import BuildingMaintenanceSoftwarePrograms from "./SeoPages/BuildingMaintenanceSoftwarePrograms";
import BuildingMaintenanceTrackingSoftware from "./SeoPages/BuildingMaintenanceTrackingSoftware";
import PropertyAssetManagementSoftware from "./SeoPages/PropertyAssetManagementSoftware";
import BuyPropertyManagementSoftware from "./SeoPages/BuyPropertyManagementSoftware";
import PropertyManagementInspectionApp from "./SeoPages/PropertyManagementInspectionApp";
import SoftwareForPropertyMaintenanceCompanies from "./SeoPages/SoftwareForPropertyMaintenanceCompanies";
import SamplePage from "./SeoPages/SamplePage";
import SeoIndex from "./SeoPages/SeoIndex";
import NoMatch from "./Pages/NoMatch";
import ScrollToTop from "./Components/ScrollToTop";

import * as gvars from "./styles2/GlobalVariables";
import GlobalStyle from "./styles2/GlobalStyles";

function App() {
    return (
        <>
        <Router>
            <ScrollToTop>
                <div>
                    <Switch>
                        <Route path="/" exact component={Index} />
                        <Route
                            path="/Bespoke"
                            component={Bespoke}
                            basename="/olitester.github.io"
                        />
                        <Route path="/Concierge-HV" component={ConciergeHv} />
                        <Route path="/Contractors" component={Contractors} />
                        <Route path="/CSite" component={CSite} />
                        <Route path="/Dwellant" component={Dwellant} />
                        <Route path="/PRSto" component={Prsto} />
                        <Route path="/Team" component={Team} />
                        <Route path="/Vision" component={Vision} />
                        <Route path="/Xero" component={Xero} />
                        <Route path="/Playground" component={Playground} />

                        {/* SEO Pages */}
                        <Route path="/seo-index" component={SeoIndex} />
                        <Route
                            path="/building-maintenance-software-programs"
                            component={BuildingMaintenanceSoftwarePrograms}
                        />
                        <Route
                            path="/building-management-software"
                            component={BuildingManagementSoftware}
                        />
                        <Route
                            path="/building-maintenance-tracking-software"
                            component={BuildingMaintenanceTrackingSoftware}
                        />
                        <Route
                            path="/buy-property-management-software"
                            component={BuyPropertyManagementSoftware}
                        />
                        <Route
                            path="/commercial-property-management-software"
                            component={CommercialPropertyManagementSoftware}
                        />
                        <Route
                            path="/property-asset-management-software"
                            component={PropertyAssetManagementSoftware}
                        />
                        <Route
                            path="/property-management-inspection-app"
                            component={PropertyManagementInspectionApp}
                        />
                        <Route
                            path="/residential-block-management-software"
                            component={ResidentialBlockManagementSoftware}
                        />
                        <Route
                            path="/software-for-property-maintenance-companies"
                            component={SoftwareForPropertyMaintenanceCompanies}
                        />
                        <Route path="/sample-page" component={SamplePage} />
                        <Route path="/xerosignup" component={XeroSignup} />
                        <Route component={NoMatch} />
                    </Switch>
                </div>
                <Footer />
            </ScrollToTop>
        </Router>
        <GlobalStyle />
        </>
    );
}

export default App;
