import React from "react";
import SocialIcons from "./SubComponents/SocialIcons";
import Contacts from "./SubComponents/Contacts";
import ContactForm from "./ContactForm";
import Row from "./Row";
import Col from "./Col";

import styled from "styled-components/macro";
import * as gvars from "../styles2/GlobalVariables";

const FooterStyled = styled.div`
    @media ${gvars.device.desktop} {
        .footer__address p {
            font-size: 0.9rem;
        }
        li {
            margin-bottom: 0.2rem;
            margin-right: 1.3rem;

            &:last-child {
                margin-right: 0 !important;
            }
        }

        .right-col i {
            font-size: 2.2rem !important;
        }
    }
`;

const Footer = (props) => {
    return (
        <FooterStyled>
            <footer className="footer bg-grey color-white">
                {props.contact && (
                    <div className="section section--row" id="contact">
                        <div className="container">
                            <ContactForm />
                        </div>
                    </div>
                )}

                <div className="footer__baseline">
                    <div className="container">
                        <Row className="flex-align-flex-end">
                            <Col width="6" mobile="12">
                                <div className="footer__address">
                                    <p>
                                        103 Vox Studios <br />
                                        1-45 Durham Street <br />
                                        London <br />
                                        SE11 5JH
                                    </p>
                                </div>
                                <Contacts />
                            </Col>
                            <Col width="6" mobile="12">
                                <SocialIcons />
                                <div className="footer__copyright text-right text-center-mobile">
                                    Copyright &copy; 2023 CoDwellers.com Ltd.
                                    <strong
                                        style={{
                                            display: "block",
                                            margin: "5px 0",
                                        }}
                                    >
                                        All rights reserved | Company No:
                                        6668360 | VAT No: 940 6693 07
                                    </strong>
                                    ICO Reference: Z1496500
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </footer>
        </FooterStyled>
    );
};

export default Footer;
