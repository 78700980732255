import React, { useState, useRef } from 'react';
import Icon from './Icon';
// https://s3-eu-west-1.amazonaws.com/dwellantvideo/Dwellant-Concierge-HV.mp4

const Video = (props) => {
    const [playing, setPlaying] = useState(false);
    const videoRef = useRef(null);

    let classNames = props.className ? ['video', props.className] : ['video'];
    let styles = {};

    if (!props.children) {
        classNames.push("video--playButtonOnly")
    }

    if (playing) {
        classNames.push("video--playing")
    }

    const classNamesString = classNames.join(" ");

    const playVideo = () => {
        setPlaying(true);
        videoRef.current.play();
    }

    return (
        <div className={classNamesString} style={styles}>
            <div className="video__inner" style={{ backgroundImage: `url('${props.bgImage}')` }}>
                <div className="video__overlay">
                    {props.children && (
                    <>
                        <div className={`video__description ${!props.noTextShadow && "text-shadow"}`}>
                            {props.children}
                        </div>
                        <div className="video__divider"></div>
                    </>
                    )}
                    <div className="video__playButton-container">
                        <div class="video__playButton clickable" onClick={playVideo}>
                            <Icon name="play" size="4rem" />
                        </div>
                    </div>
                </div> 
                <video ref={videoRef} controls>
                    <source src={props.src} />
                </video>
            </div>
        </div>
    )
}

export default Video;
