import React from "react";
import { Helmet } from "react-helmet";

import Heading from "../Components/SubComponents/Heading";
import Highlight from "./../Components/SubComponents/Highlight";
import Subheading from "./../Components/SubComponents/Subheading";
import Row from "../Components/Row";
import Col from "./../Components/Col";
import Section from "./../Components/Section";
import Button from "./../Components/Button";
import Thumbnail from "./../Components/Thumbnail";
import Logo from "./../Components/Logo";
import Logos from "./../Components/Logos";

import { Header } from "../Components/Header";
import geometric from "../Images/geometric-bg.png";
import knightFrank2 from "../Images/Client Logos/knightfrank2.png";
import Iphone from "../Components/Iphone";
import iphoneContent from "../Images/iphone-content.jpg";
import ContactForm from "../Components/ContactForm";

import DevicesProductMockups from "../Images/illustrations/home-opener-illustration2.png";
import ConnectingEveryone from "../Images/illustrations/connecting-everyone.svg";
import ClearComunication from "../Images/illustrations/clear-communication.svg";

// Integration logos
import EstateSoftware from '../Images/integration-logos/mri_logo_new.svg';
import PropMan from '../Images/integration-logos/propman.png';
import RiverLake from '../Images/integration-logos/riverlake.png';
import SafeContractor from '../Images/integration-logos/safecontractor.jpg';
import Sage50 from '../Images/integration-logos/sage50.png';
import Salesforce from '../Images/integration-logos/salesforce.png';
import TraceSolutions from '../Images/integration-logos/tracesolutions.png';
import Xero from '../Images/integration-logos/xero.png';
import OpeningSection from "../Components/New/OpeningSection";
import ContactUsSection from '../Components/New/ContactUsSection';

const BuildingMaintenanceSoftwarePrograms = () => {
  return (
    <>
      <Header />
      
      <OpeningSection bgImage={geometric}>
        <h1 className="orange-border">
        Building Maintenance Software <span className="orange">Programs</span>
        </h1>

        <p>
        Dwellant’s software is a holistic, cloud-based system for the
          21st century. Like no other building maintenance software
          program like it- it brings together all stakeholders in a
          residential building or development with intelligence and
          elegance.
        </p>
      </OpeningSection>

      <Section slim>
        <Row>
          <Col width="4" mobile="12">
            <img
              src={DevicesProductMockups}
              alt="Dwellant’s building maintenance software programs are a holistic, cloud-based system for the 21st century."
            />
          </Col>
          <Col width="8" mobile="12">
            <Heading>
              <Highlight>Building</Highlight> Maintenance Software
            </Heading>
            <p>
              Residential and commercial building maintenance is a complex job
              which calls for expertise from a variety of professions. The
              property management industry at times still relies on manual and
              paper-based systems to support their work. Businesses rely on
              different systems from different software providers for each of
              their work areas, making it hard to keep track of all areas of
              the business and avoid duplicating efforts. The demands on those
              responsible for multi-occupancy building maintenance are ever
              increasing. Building maintenance software programs that provide
              a holistic platform for a broad customer case are in demand.
            </p>
            <p>
              At Dwellant, we thought long and hard about how we can
              streamline property maintenance processes, reducing the stress
              associated with its high volume and high expectation nature. We
              created a system that provides a market-leading building
              maintenance software program - an all-encompassing, including
              platform that is genuinely easy to use.
            </p>
            <p>
              Our building maintenance software program has been specifically
              designed for those who manage commercial and residential
              buildings, involving multiple stakeholders. Dwellant is equally
              intuitive and easy to use for the building’s manager (on-site
              and off-site), the occupants (e.g. residents, commercial
              tenants) and any maintenance service providers. Its
              functionality has been developed after many years spent with
              property managers, to understand what is important to them. Our
              solution is a platform that is interactive, with multi-layer
              access levels, intuitive to use by anyone and an invaluable tool
              to make building maintenance as easy as possible.
            </p>
            <img
              src={ConnectingEveryone}
              alt="Dwellant’s building maintenance management software is a holistic, cloud-based system for the 21st century like no other."
            />
          </Col>
        </Row>
      </Section>

      <Section bg="offwhite" type="logos">
        <Logos heading="Integrations">
          <Logo
            href="#"
            image={EstateSoftware}
            caption="MRI"
          />
          <Logo
            href="#"
            image={PropMan}
            caption="PropMan"
          />
          <Logo
            href="#"
            image={RiverLake}
            caption="River Lake"
          />
          <Logo
            href="#"
            image={SafeContractor}
            caption="Safe Contactor"
          />
          <Logo
            href="#"
            image={Sage50}
            caption="Sage 50"
          />
          <Logo
            href="#"
            image={Salesforce}
            caption="Salesforce"
          />
          <Logo
            href="#"
            image={TraceSolutions}
            caption="Trace Solutions"
          />
          <Logo
            href="#"
            image={Xero}
            caption="Xero"
          />
        </Logos>
      </Section>
      <Section invert>
        <Row>
          <Col width="8" mobile="12">
            <Heading>
              What Does <Highlight>Dwellant</Highlight> Do?
            </Heading>
            <p>
              Through close working with building management professionals,
              Dwellant understands the demands on the property manager and the
              needs of building developers, leaseholders, tenants and
              suppliers and has created a building maintenance software
              program that is versatile, simple to use and dynamic.
            </p>
            <p>
              Building maintenance software program like Dwellant’s
              in-the-cloud platform, feature crucial elements for enabling
              digital transformation in the building manager’s workplace.
            </p>
            <p>
              Dwellant’s cloud-based Core building maintenance software
              program can revolutionise how the property management team
              delivers high volume maintenance and management, ultimately
              benefiting the building’s occupiers.
            </p>
          </Col>
          <Col width="4" mobile="12">
            <img
              src={ClearComunication}
              alt="Dwellant’s building maintenance software is a holistic, cloud-based system for the 21st century like no other."
            />
          </Col>
        </Row>
      </Section>

      <Section>
        <Row>
          <Col width="8" mobile="12">
            <Heading>Building Maintenance Management Software</Heading>
            <p>
              The engine room of Dwellant powers a wide range of crucial
              workflow and task management activities. For the building
              maintenance and management team, this is their go-to platform
              for routine duties and longer term planning. This ‘core’ of the
              software presents itself to the building or property manager as
              a dashboard, clearly showing upcoming tasks, those overdue and
              tasks completed. Nothing is left to memory or to chance, so
              maintenance tasks are never unknowingly neglected.
            </p>
            <p>
              Maintenance items can originate from many sources, including a
              report from a resident or tenant, a site inspection by a
              property manager or building manager, or from a third party
              contractor. However the item is reported, it will be logged on
              Dwellant and seen through to completion. A resident reporting a
              maintenance issue on Dwellant will be kept up to date via the
              building maintenance software program, all the way to its
              resolution. Similarly, a contractor noticing a maintenance issue
              may be assigned the task of resolving it - through Dwellant’s
              building maintenance software program.
            </p>

            <Heading>
              <Highlight>Expenditure</Highlight> Control
            </Heading>
            <p>
              Spending service charge money on building maintenance has to be
              closely monitored, not least as it will need to fall within
              funds available and the service charge budget set for the year.
              With statutory expenditure limits ( as per Section 20 of the
              Landlord and Tenant Act) also programmed into Dwellant, building
              managers are able to stay on the right side of landlord and
              tenant legislation. ‘Section 20’ expenditure limits relating to
              qualifying works and qualifying long term agreements are
              automatically and continuously monitored by the building
              maintenance software program, for every invoice, for every
              contract and for every project. A clever flagging system prompts
              the manager to validate expenditure and reminds of purchasing
              limits.
            </p>
          </Col>
          <Col width="4" mobile="12">
            <Iphone
              image={iphoneContent}
              alt="How Dwellant looks for residents on their mobile devices"
            />
          </Col>
        </Row>
      </Section>

      <Section invert>
        <Row>
          <Col width="8" mobile="12">
            <Heading>
              <Highlight>Capital expenditure</Highlight> planning and planned
              maintenance
            </Heading>
            <p>
              Dwellant provides the ideal building maintenance software
              program for longer term planned maintenance or major works or
              capital expenditure projects. Dwellant allows a building manager
              to prepare for a planned maintenance programme, years in advance
              if necessary.
            </p>
            <p>
              The implementation of planned maintenance is integral to the
              successful management of a building. And communication of such a
              plan to the service charge payers is equally important. Dwellant
              transparently holds reports in documentation vaults online -
              easy to find, easy to view, easy to digest.
            </p>
            <p>
              Task dashboards can include schedule major works and capital
              expenditure projects and permit project planning ahead of time.
              The building maintenance software program will facilitate the
              planning, communication and execution of each project.
            </p>

            <Heading>
              <Highlight>Health &amp; safety</Highlight> built in
            </Heading>
            <p>
              Building maintenance software programs without in-built health
              and safety and compliance capabilities may lead to unsafe
              working practices with potentially catastrophic results.
              Dwellant has an integrated option to link risk assessments and
              method statements to the work order process. This way on-site
              staff, office staff and contractors can all access and review
              documents as needed, and relevant permit to work documents can
              be issued quickly and accurately.
            </p>
            <p>
              Our platform allows maintenance contractors to view any
              identified health and safety hazards on site, including the
              presence of asbestos and any unprotected areas. The health and
              safety information is held on the building maintenance software
              program for all relevant parties to see and share.
            </p>
            <p>
              The ‘frequent event’ function for routine surveys and patrol
              checks provides the team on-site with structure to their working
              day. Whether these are for common parts site inspections,
              security patrols, or health and safety checks, site managers can
              download a monthly report for onward transmission to head office
              management and ultimately to the client as needed. The results
              of such inspections and surveys provide useful data for regular
              tool-box talks and training. Any reports are immediately
              accessible and downloadable from Dwellant’s building maintenance
              software program thus ideal to be used for team or client or
              contractor meetings.
            </p>
            <p>
              A health and safety dashboard allows a building manager to see
              outstanding health and safety tasks and actions, whether they
              relate to fire safety, water hygiene, asbestos, utility safety
              or working at height. Actions resulting from risk assessments,
              surveys and inspection reports are extracted by the building
              maintenance software program and the necessary parties can view
              them, delegate them, mark them as ‘in progress’ and close them
              off once completed. The use of one system for on-site as well as
              off-site management means delegation to those at the coal face
              helps to spread the workload and accelerate its completion. As
              necessary, risk assessors themselves, the Health & Safety
              Executive (HSE), the fire service and others can be given access
              to this Dwellant dashboard to view the courses of action prior
              to a health and safety incident or an accident. Near-misses as
              well as accidents can be recorded on Dwellant, to aid safer
              working practices in the future.
            </p>
            <p>
              Automated, repeat messages from the building maintenance
              software program to team members facilitate the collation of
              such data for internal statistical analysis, safety reporting
              and prompting of actions outstanding.
            </p>
          </Col>
        </Row>
      </Section>

      <Section>
        <Row>
          <Col width="8" mobile="12">
            <Heading>
              <Highlight>Contractor</Highlight> management
            </Heading>
            <p>
              Supply chain management is one of Dwellant’s most popular
              functions amongst maintenance managers. Valued contractors are
              provided with a login to use Dwellant to receive works orders.
              Firstly, they need to provide the necessary accreditation and
              certification, which they can do electronically on the platform.
              The onus is on the contractors to update their credentials as
              often as they are renewed, usually annually. Once approved,
              contractors are then able to receive and progress quotation
              requests and work orders. Annual regular maintenance contracts
              are also managed by the building maintenance software program.
              Automated reminders prompt the management team for contract
              renewals and reviews. Renewal is simple, and quotes for
              contracts are equally easy to obtain and accept. The building
              maintenance software program retains the contract history so the
              manager can review previous suppliers, prices and terms.
            </p>

            <Heading>
              Intelligent <Highlight>Invoice Processing</Highlight>
            </Heading>
            <p>
              Not all can complete the circle. Dwellant does with aplomb. Once
              a maintenance contractor has completed a job, he can bring up
              the job on Dwellant, mark it as complete, upload any proof as
              required (e.g. a photograph) and upload an invoice there and
              then. The invoice - which should be coded perfectly as it was
              done when the order was placed - goes directly into the building
              manager’s workflow. The contractor can even email his invoice
              and it will be auto-read, matched automatically to the
              corresponding works order and key data populated instantly, all
              by our AI engine. The contractor need not do any data entry. No
              paper. No postage. No lost invoices - the app does all the hard
              work. At any time, the contractor can see on the dashboard of
              the building maintenance software program the status of his
              payment - so no chasing is required.
            </p>
            <p>
              From the property manager’s point of view, Dwellant’s
              prescriptive but tried-and-tested systems ensure invoices are
              coded to the correct funds, schedules and nominal codes. Once
              the property or building manager has approved them, they are
              endorsed by a superior or superiors ahead of payment.
              Throughout, communication is facilitated through messaging flags
              carrying crucial information to allow approval and finally
              invoice payment. The building maintenance software program is
              monitoring input error too. Where invoice amounts differ from
              contracted sums or work order details, this is automatically
              flagged for further scrutiny.
            </p>
            <p>
              For regular and predictable building maintenance, contracts can
              be set up on Dwellant, thereby ensuring invoices are
              auto-approved (within set expenditure tolerances) and go
              directly to the accounts department for payment. The data from
              all invoices processed in Dwellant are seamlessly uploaded to
              the building maintenance manager’s back-end accounts system
              ready for physical payment to the supplier.
            </p>
            <p>
              Dwellant has provided its customers with a building maintenance
              software program that handles maintenance issue reporting
              through to invoice payment and everything in between.
            </p>
          </Col>
        </Row>
      </Section>

      <Section invert>
        <Row>
          <Col width="8" mobile="12">
            <Heading>
              <Highlight>On-site</Highlight> Staff
            </Heading>
            <p>
              Arguably the most important resource paid for by the service
              charge payers is the on-site staff. They are customer facing and
              usually kept very busy, especially with maintenance related
              tasks. Dwellant provides a building maintenance software program
              that ensures the on-site staff are genuinely connected to the
              overall management of the building. The use of our software
              means one system, coordinating the efforts of on-site and office
              based staff. It means office based property managers can
              delegate tasks to maintenance focussed site staff, who can then
              liaise directly with the contractors. In this day and age of
              on-site staff running the show, the greater the investment in
              the site staff, the less stressed the property manager becomes
              and the better service the residents will receive.
            </p>

            <Heading>
              <Highlight>Residents</Highlight> and Tenants
            </Heading>
            <p>
              Whether a property manager is looking after homes or workplaces,
              he/she will be dealing with demanding owners and occupiers who
              expect maintenance issues to be dealt with efficiently and the
              cost of resolution reasonable. Whether homes or offices,
              Dwellant provides a sense of ‘community’. Our software
              facilitates a closeness between building management and end
              users, but also a closeness between the community members. Our
              software gives the community a voice, a platform to help make
              decisions, including discussion forums and voting tools.
            </p>
            <p>
              Dwellant’s building maintenance software program gives users the
              ability to be self-sufficient. Why spend time on the phone with
              a property manager when you can report a maintenance issue
              through the platform and enable others in the building to see
              that they need not raise the same issue? To report such a
              maintenance issue, a user can simply send an email to the
              platform which in turn creates a task for the property manager.
              The tasks are visibly logged and can be tracked by those who
              need to act or need to know about the progress of the
              maintenance issue.
            </p>
            <p>
              Commonly asked questions - maintenance related or otherwise -
              can be answered via an online residents’ guide, a guide that is
              constantly updated and brings to life the wider, local
              community.
            </p>
            <p>
              Practically speaking, any Dwellant user can choose our building
              maintenance software program to make online payments from
              anywhere in the world. These may be for service charges, ground
              rent, or for individual ‘home’ services. Accessed through a
              secure personal vault showing all previous transactions, users
              can rest assured that their details are safe.
            </p>
            <p>
              Residents and commercial tenants can access their building’s
              branded platform from anywhere, anytime, from a PC or smart
              device connected to the internet. This means that they can keep
              up to date with news, important messages or progress with
              building works. The management team has the ability through the
              software to issue emergency notifications via email or SMS, thus
              alerting of any incident or important update in real time.{" "}
            </p>
          </Col>
        </Row>
      </Section>
      
      <ContactUsSection>
        <>
          <div className="block">
            <h2>Contact our dedicated sales team on</h2>
            <div className="contact-info-large">020 3397 2211</div>
          </div>
          <div className="block">
            <h2>Or Email us at</h2>
            <div className="contact-info-large">
              <a href="mailto:sales@dwellant.com" target="_blank">
                sales@dwellant.com
              </a>
            </div>
          </div>
        </>
      </ContactUsSection>

      <Section invert>
        <Row>
          <Col width="8" mobile="12">
            <Heading>
              <Highlight>Buying</Highlight> and Selling
            </Heading>
            <p>
              ‘Placemaking’ is more than a buzzword. For a residential or
              commercial scheme to earn and maintain an identity and develop a
              sense of community, a portal such as the one Dwellant provides
              can go beyond the practical aspects of building maintenance
              reporting and fulfillment.
            </p>
            <p>
              Each building or scheme using Dwellant can have set up for it a
              unique website immediately giving it an online presence. The
              more the users use the website, the greater the chance of
              developing a sense of place - a place in which to live, work,
              enjoy, and thrive. Anyone looking to rent or buy in your area,
              will be attracted to your ‘place’ as it has an identity, is
              community focussed and is clearly well maintained.
            </p>
            <p>
              First impressions count and a prospective purchaser (and their
              solicitor) will have a hundred and one questions to receive
              satisfactory answers to, before they embark on their expensive
              transaction. They tend to ask these of the current owner, who
              turns to the property manager for the answers.{" "}
            </p>
            <p>
              Dwellant’s building maintenance software program provides the
              ultimate efficiency in preparing and issuing legal packs. LPE1
              and LPE2 forms are completed by the manager or administration
              department directly into the forms embedded in the software. The
              Dwellant Document Vault holds the requested files. When the
              LPE1/LPE2 forms are completed, the recipient (usually the
              solicitor) receives a weblink generated by Dwellant. Clicking
              through, the solicitor will be able to download the completed
              LPE forms and PDF copies of all requested documents. As soon as
              the web link is opened, proof of opening is given to the sender.
            </p>
            <p>
              Dwellant’s pre-contract enquiry service above is market-leading.
              A pack can be prepared in as little as 20 minutes, meaning last
              minute requests for information that may be holding up a sale
              can be delivered fast to save the day! Online payment is
              available too. The LPE functionality will help any agent to beat
              the industry wide capped cost for a solicitors inquiry pack!
            </p>

            <Heading>
              <Highlight>Communication</Highlight> Hub
            </Heading>
            <p>
              This first-class building maintenance software program enjoys
              various communication tools. This is to ensure maintenance
              issues are dealt with quickly and accurately.
            </p>
            <p>
              Dwellant provides a communications hub for on-site teams and a
              vital link between head office and remote workers. We have seen
              that Dwellant can be used to visibly maximise the effectiveness
              of on-site staff. Data and tasks are shared and distributed
              between the team members. As the software is cloud-based and
              becomes the single system for coordinating the efforts of
              on-site and office based staff, those ultimately responsible for
              executing tasks can delegate with confidence and monitor
              progress remotely.
            </p>
            <p>
              An on-site team using Dwellant’s building maintenance software
              program can access residents’ data in real time. All data for
              on-site operations can be retained and managed securely via
              access passwords and layered access levels. To communicate
              efficiently with residents, the on-site team can make use of the
              building maintenance software program posting tool. This allows
              for swift mail merging to multiple parties - hundreds of
              individuals at a time. The platform uses email or SMS to reach
              customers, providing links to retrieve further information from
              the Dwellant platform. Using the building maintenance software
              program, emergency messages can also be issued using the SMS
              alert tool.
            </p>
            <p>
              For both the property management and on-site teams, Dwellant
              provides a CRM-style interface that facilitates first line
              response to customers. Whatever the source of the enquiry, the
              building maintenance software program allows external
              notifications (e.g. a maintenance issue reported) to be entered
              straight into the system. These can be converted swiftly into
              work orders and everyone who needs to know can track progress
              through to completion, including residents, tenants and
              contractors. The reporter of the issue can track progress as a
              Dwellant user at any time, from anywhere with an internet
              connection.
            </p>
            <p>
              Dwellant’s very own process form creation allows your own
              bespoke policies to be set up, building by building or
              company-wide. This helps to streamline processes or rolling out
              standard letters across the business. Whether it is for a
              licence to alter consent, starting an insurance claim or simply
              requesting a new key fob, the building maintenance software
              program will create the forms allowing property and building
              managers to make faster and more informed decisions. A uniform
              approach saves time and gives reassurance to the business that
              all staff work to the same standards.
            </p>
          </Col>
        </Row>
      </Section>

      <Section invert>
        <Row>
          <Col width="8" mobile="12">
            <Heading>
              <Highlight>Reporting</Highlight> and KPIs
            </Heading>
            <p>
              The system holds a huge capacity for reporting downloads and
              uploads on all levels.
            </p>
            <p>
              Senior management and operational managers alike can view
              activity and performance against set targets live, and can
              download reports across different business sectors with data
              analysis tools, ready for presentations or group reporting.
            </p>
            <p>
              On a building level, the on-site estate manager can see live
              activities and outstanding tasks for the property and his team.
              Simple metrics reports keep the manager updated on critical
              activities and site statistics.
            </p>
            <p>
              Typical reports include for example building unit owners
              registered as users on the building maintenance software
              program. Normally this is related to the number of units,
              depending on whether partners and tenants register separately,
              and whether commercial tenants have been invited to register.
            </p>
            <p>
              The property manager will be interested in statistics to monitor
              his own performance and identify areas for improvement. The
              system permits managers to keep an eye on their KPIs by running
              status reports on day to day activities as well as specific
              business focuses from the building maintenance software program.
            </p>
            <p>
              A useful report is one produced on supplier contracts. A
              contract describes regular, repetitive work to be carried out in
              the building e.g. cleaning, gardening. The building maintenance
              software program monitors renewal dates and reminds the team and
              manager when contracts need to be renewed. Checking the data
              regularly informs the manager of where new contracts need to be
              placed or renewed - very useful when consolidating.
            </p>
            <p>
              High volume invoice processing needs close monitoring and KPIs
              are frequently set for invoice statuses. Dwellant’s metrics
              reports show data on invoices by ‘open’, ‘value’, ‘disputed’,
              and ‘average days to payment’.
            </p>
            <p>
              Further operational metrics reporting relates to the number of
              open requests. There will always be some open requests as most
              jobs take more than a day to complete (especially if waiting
              time is included).{" "}
            </p>
            <p>
              Reports can also be seen on the open requests, their content and
              for how many days they have been kept open. This can provide a
              picture of the efficiency of the individual manager or the team,
              and the speed of open requests, average age in days the request
              has been worked on.
            </p>
            <p>
              Most buildings have events that are repeated at regular
              intervals e.g. fire alarm tests, lift alarm test, boiler
              maintenance etc. The key events are set up on the building
              maintenance software program when the property comes online.
              They can be added to and edited as needed. Each repeated event
              has a date and time and reminders are issued by the system to
              alert when an event is upcoming or overdue. Events can be simply
              closed by responding to the system email and for example
              attaching the relevant maintenance report, or by closing the
              event within the system. All documentation relating to the event
              and maintenance can be attached to the file, then retrieved or
              viewed by all staff or managers or surveyors.
            </p>
            <p>
              Property managers often use the building maintenance software
              program to send updates and newsletters to leaseholders,
              residents or staff. Residents can in turn comment on the
              newsletter or pose questions. Unanswered newsletter responses
              will be flagged on the dashboard.
            </p>
            <p>
              Where packages are stored by the concierge team, the building
              maintenance software program can be used to report on those
              packages that have not yet been collected. This report can be
              utilised by the concierge to create a list to call residents and
              remind them to visit reception and collect the items that have
              arrived for them. A mail merge can use this data to target all
              late collections en masse. It can also serve to record
              measurement of parcel turnaround and residents’ speed of pick up
              - and encourage efficient management of the parcel room.
            </p>

            <Heading>
              <Highlight>IT</Highlight> Integration and Implementation
            </Heading>
            <p>
              The building maintenance software program has been built with an
              open API engine, so despite its comprehensiveness that its own
              modules provided, we are ready to integrate with your own
              existing software to provide a unique combination that serves
              exactly the needs of your business.
            </p>
            <p>
              Dwellant integrates with all recognised accounts/service charge
              software solutions, meaning service charge data is shared
              bi-directionally. Dwellant’s Data-Gateway makes this possible.
              Furthermore, Dwellant’s sophisticated API engine means its
              workflow and task management abilities - particularly for
              maintenance management - have surpassed the traditional estate
              management software capabilities therefore integration with
              Dwellant gives their property managers and other users a vastly
              enhanced service.
            </p>
            <p>
              For contractors too, Dwellant’s smart API engine enables
              contractors’ own CRM/works management system to be integrated
              bi-directionally. That means any work order placed on Dwellant
              emerges on the contractor’s own system ready to be assigned to
              an engineer. The same applies to health and safety advisors’
              systems. Two way data exchange means no duplication of effort.
            </p>
            <p>
              The dedicated Dwellant implementation team looks after every
              step of the process so you do not need to worry about onboarding
              the building maintenance software program. Before the project
              starts an implementation plan is agreed with set timelines and
              key dates. The team explains each step and ensures the client
              has all the information needed to commence set up. All critical
              data and documents received will be securely loaded and can then
              be viewed in the document vault.
            </p>
          </Col>
        </Row>
      </Section>

      <Section>
        <Row>
          <Col width="8" mobile="12">
            <Heading>
              <Highlight>On-site</Highlight> Tools
            </Heading>
            <p>
              Dwellant plug-in CSite allows property managers and on-site
              staff to conduct site surveys on a tablet. Actions populate
              Dwellant upon synchronisation. Concierge HV and MV seamlessly
              manage your on-site key, package and visitor obligations. All
              Dwellant plug-ins fully integrate with our central cloud-based
              software.
            </p>
            <Heading>
              <Highlight color="green">CSite</Highlight>
            </Heading>
            <p>
              Csite is the ultimate building inspection tool for property and
              estate managers who wish to carry out swift and accurate site
              inspections.
            </p>
            <p>
              Once you have set up a template for each building, there are
              three simple steps to complete.
            </p>
            <ol>
              <li>
                <strong>Survey:</strong> when you carry out a site visit, you
                can now complete your survey via smartphone or tablet, adding
                photos directly to your notes.
              </li>
              <li>
                <strong>Synchronise:</strong> when online, synchronise with
                Dwellant and actions appear, ready for delegation.
              </li>
              <li>
                <strong>Report:</strong> a site visit report is created and
                available in Word, PDF and CSV formats. The documents and
                photos are always available for audit checks.
              </li>
            </ol>
            <p>
              The Csite site inspector operates anywhere with offline use. The
              app is downloaded on a phone or tablet and is ready to use.
              Using the app saves valuable time for the site surveyor. The
              survey and report are geo-location tracked and logged
              immediately, saving 90% office admin time. All data be stored
              where you can make it work for you and your clients.
            </p>
            <p>
              The management tasks following on from your site visit are
              simple. The Csite app turns tasks into work orders there and
              then. The app and its functions are easy to learn and use. New
              staff are swiftly trained to use the tool. Csite ensures
              consistency of data across all teams and buildings. It provides
              safe and secure storage of surveys and reliable end-to-end
              reporting.
            </p>
            <p>
              Csite works on any Android and IOS device; it removes all need
              for site inspection write ups. The inspector can work offline
              and synchronise the report and all photos and data once back in
              reception range or back in the office. Questions linked to
              assets and historic asset data can be saved and followed up
              easily, and any work orders that result from the queries can be
              issued immediately. The app allows the creation of template
              content and specific questions to ensure continuity across your
              portfolios.
            </p>
            <p>
              A simple link to the Dwellant portfolio allows integration and
              seamless, automatic reporting of all on-site issues. Defects
              management has never been so easy. Geo and time stamp validate
              the report and make it usable for explicit uses including
              witness statements. Once the site survey is finalised,
              instructions are ready to be assigned to the contractor. The
              synchronised content from the site inspections is kept ready on
              Dwellant to be expanded on or shared with others.
            </p>

            <Heading>
              <Highlight>Concierge</Highlight> HV/MV
            </Heading>

            <p>
              Concierge HV (high volume) is your essential high volume
              package, key and visitor management software. (MV is medium
              volume, which is a scaled down version that uses tablet
              technology rather than a pc).
            </p>

            <p>
              If online shopping deliveries, visitor tracking and key
              management have left your concierge overwhelmed, you’re at risk
              of losing crucial time, trust and ultimately income. Our fast,
              efficient and secure automated system has been designed with
              your on-site staff in mind.
            </p>

            <p>
              Paperless package and key handling reduces human error. Due to
              instant parcel notification and fast pick up, approximately 40%
              of space is saved in your parcel room. Concierge HV includes
              automated email chasers for parcels. There are also chasers for
              keys ensuring borrowers do not hang on to them. Instant stock
              check reports and logs help management to keep track of the
              borrower’s identity and when the keys are set for return. Easy
              to produce audit reports support the team and manager in
              monitoring everything that comes in and goes out from the
              concierge desk.
            </p>

            <p>
              Using the scan-and-process tool the team process residents
              packages as they are dropped off by the postman or courier
              companies. Keys are also scanned in and out using secure tagging
              systems.
            </p>

            <p>
              With instant notifications, residents receive the news about
              their parcel as soon as it arrives at the front desk. Residents
              leave instructions for handling of keys and parcels, all
              information is safely stored on the data hub. All updates are
              received live by the resident, through instant notifications,
              SMS and email. The Dwellant software makes collection as well as
              distribution of keys and parcels secure and easy. Key release
              functionality includes built-in registered key release policy
              specific to residents’ contacts, so that a resident can give
              easy access to his/her apartment, to a friend or relative.
            </p>

            <p>
              On-site passes are issued and kept track of simply and securely.
              Site access and permits to contractors is simply managed by the
              front desk team.
            </p>

            <p>
              A secure audit trail is key to effective front desk package
              management. The digital audit trail avoids any manual errors
              saving time and money. Auto reminders and notifications save the
              concierge team and manager valuable time, particularly during
              peak periods such as Christmas.{" "}
            </p>

            <p>
              The quick-click-process is a smart application that reduces the
              package management process from minutes to seconds. The system
              is cloud-based as paper processes are open to human error which
              leaves data vulnerable.
            </p>

            <p>
              Latest scanning technology is part of the system. Packages and
              keys are managed with reliable speed and accuracy. Residents
              have full control over their data and who has access to their
              keys and parcels. Residents conveniently log in to update and
              monitor their own account.
            </p>

            <p>
              The system is fully GDPR compliant. All data is completely
              protected for total peace of mind. Continued support is provided
              by the customer service team who are available via chat or on
              the telephone. On free dedicated training days we will show your
              staff on site how the system works in their workplace. All
              support materials are stored online and are available upon
              request.
            </p>

            <p>
              With parcels, keys and visitor logs securely managed via
              Dwellant, the concierge team can focus on the front desk
              service. Deliveries are no longer a headache, as they take
              seconds to process. Residents can have peace of mind that that
              no more parcels or keys are lost or misplaced.
            </p>

            <p>
              With seamless service and effective use of space for packages,
              fewer staff are needed at peak periods, allowing for service
              charge budget savings.
            </p>

            <Heading>
              <Highlight>Bespoke</Highlight> Buildings
            </Heading>
            <p>
              Bespoke Buildings is a web portal dedicated to your estate, its
              residents and its community. It is designed to deliver an
              elegant digital experience for the estate team and residents
              alike. Bespoke will help residents unlock the full value and
              identity of their home by giving them anytime access to their
              building and priceless local knowledge. It allows the site staff
              and managing agent to seamlessly communicate with all
              leaseholders and residents, keeping them informed of important
              building news, including maintenance issues. Bespoke import { Header } from './../Components/Header';
isimport OpeningSection from './../Components/New/OpeningSection';
import ContactUsSection from './../Components/New/ContactUsSection';

              developed to deliver residents a continuous high-end digital
              home experience.
            </p>
            <p>
              Through automated functionality the on-site team can control
              facility and activity bookings. Residents are able to reserve
              their move-ins and have access to a wealth of online information
              about the apartment, the property and its surrounds. Bespoke
              forms can be used to for registrations and collating of
              residents’ data and queries.
            </p>
            <p>
              Pre-set work flows automate replies and help the site team to
              deliver a great resident experience.
            </p>
            <p>
              The interactive features on the website allow residents to have
              their home in their hands. Unique features create a community
              feel second to none.
            </p>
            <p>
              The site design is engaging, modern and mobile-friendly.
              Services provided by the team are easy to identify and access,
              at the click of a button. Through the website calendar,
              residents can make bookings for the facilities on site (such as
              the cinema) or activities such as exercise classes from the
              comfort of their home, or whilst away travelling.
            </p>
            <p>
              A dynamic location guide introduces the residents to their new
              home and immediate neighbourhood. Updates about local events,
              theatre shows and restaurants, are fed into the website. Bespoke
              provides a guide on where to eat, exercise, drink, relax and how
              to enjoy the community. The portal allows access to all
              home-related documents, user manuals, information about the
              communal parts of the property as well as the services provided
              by the concierge team. Live local transport information is fed
              into the website too.
            </p>

            <p>
              Key functions of the webpage are driven by the fully opted-in
              content. Residents have an individual user profile which is the
              gateway to all documentation related to the property and leisure
              activities offered on site.
            </p>

            <p>
              The building maintenance software program’s responsive design is
              created to suit Android, iOS and PC. The structure of each page
              content is bespoke to each development and its residents.
            </p>

            <p>
              Forms and booking facilities are customised for each
              development. Locked content for special features are tailored to
              residents’ profile. Residents can raise requests directly to the
              on-site management team and receive responses via email or SMS.
              The management team can turn around any queries swiftly and make
              responses visible to an individual or groups of residents.
            </p>

            <p>
              Product features include the integration with external services
              e.g. Salesforce. The webpage’s communication is set up according
              to residents’ preferences. It is easy to set up and manage
              through user-friendly interfaces designed for the on-site
              building team. The software fully integrates with the property’s
              door entry system, providing residents with secure control of
              their home. It gives easy to upload unit-specific documents and
              manuals. Local landmarks and businesses are identified via
              linked Google pages.
            </p>
            <p>
              Bespoke provides a rounded digital experience that serves the
              residents and staff of the property to create a warm and
              welcoming community.
            </p>
          </Col>
        </Row>
      </Section>

      <Section invert>
        <Row>
          <Col width="8" mobile="12">
            <Heading>
              <Highlight>Security</Highlight> and Data Protection
            </Heading>
            <p>
              Software security is a top priority at Dwellant. Our ISO 27001
              certification gives our clients and users the assurance that all
              data and user functionality is protected by the highest security
              features. Our in-house developers maintain Dwellant’s suite of
              products, ensuring security remains paramount.
            </p>
            <p>
              Data Protection is addressed via a signed agreement between
              Dwellant and the client. This states that the client has the
              legal right to disclose all personal data that it discloses to
              Dwellant under or in connection with this agreement and further
              that the client shall fully comply with the Data Protection Act
              1998 and GDPR Act 2016.
            </p>
            <p>
              Dwellant warrants that it will act only on instructions of the
              client in relation to the processing of any Personal Data
              performed by Dwellant on behalf of the client. That it has in
              place reasonably appropriate security measures (both technical
              and organisational) against unlawful or unauthorised processing
              of Personal Data. The web service is protected using security
              systems and technologies which include a Hardware Firewall
              configured with the minimum standard rule set and a software
              firewall configured to protect systems and restrict traffic to
              the minimum protocols. Further is administrative access given
              through a secure site and appropriate rules for password
              security enabled. Regular security monitoring and configuration
              checks are carried out. Dwellant provides market-leading
              software to ensure your and your clients’ data is handled
              correctly.
            </p>
          </Col>
        </Row>
      </Section>
      <Helmet>
        <title>Building Maintenance Software Programs || Dwellant</title>
        <meta
          name="description"
          content="Dwellant’s software is a holistic, cloud-based system for the
                21st century. Like no other building maintenance software
                program like it- it brings together all stakeholders in a
                residential building or development with intelligence and
                elegance."
				/>
			</Helmet>
		</>
	);
};

export default BuildingMaintenanceSoftwarePrograms;
