import React, { useState, useEffect } from 'react';
import iphone from '../Images/iphone.png'

const Iphone = (props) => {
    const [animating, setAnimating] = useState(false)

    useEffect(() => {
        const startAnimation = () => {
          setAnimating(animating => !animating);
        }
        setTimeout(() => {
            startAnimation()
            setInterval(() => {
                startAnimation();
            }, 6000);
        }, 2000);
      }, []);

    return (
        <div className={`iphone align-mobile-center ${animating ? "iphone--animating" : ""}`} onClick={() => setAnimating(!animating)}>
            <img className="iphone__image" src={iphone} alt="An iPhone" />
            <div className="iphone__content">
                <img className="iphone__content-image" src={props.image} alt={props.alt} />
            </div>
        </div>
    )
}

export default Iphone;