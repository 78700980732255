import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../Images/logo.svg";
import Fade from "react-reveal/Fade";
import SocialIcons from "./SubComponents/SocialIcons";
import Contacts from "./SubComponents/Contacts";
import Icon from "./Icon";
import Button from "./Button";

import styled from "styled-components/macro";
import * as gvars from "../styles2/GlobalVariables";
import GlobalStyle from "../styles2/GlobalStyles";

// PLEASE NOTE
/*
This component uses both the original styles in the 'styles' folder and a little in-file styling via
styled-components
*/
const HeaderStyled = styled.div`
  header {
    &.header--sticky {
      .header__logo {
        width: 35%;
      }
    }
  }

  @media ${gvars.device.laptop} {
    header {
      .header__logo {
        width: 15vw;
      }
      .header__container {
      }

      &.header--sticky {
        .header__logo {
          width: 10.6vw !important;
        }
      }
    }
  }
`;

export const Header = (props) => {
  const [stickyState, setSticky] = useState(false);
  const [navOpen, setNavOpen] = useState(false);

  let timer;

  window.onscroll = () => {
    if (timer) {
      window.clearTimeout(timer);
    }

    timer = window.setTimeout(function () {
      // If window is scrolled down, add sticky class to header. Otherwise remove it.
      // console.log(window.scrollY);
      setSticky(window.pageYOffset > 100);
    }, 100);
  };

  const sticky = props.fixedTopNav ? true : stickyState;

  const menuButtonOpen = () => {
    //Runs when the menu opens
    setNavOpen(true);
    document.body.style.overflow = "hidden";
  };

  const menuButtonClose = () => {
    //Runs when the menu closes
    setNavOpen(false);
    document.body.style.overflow = "auto";
  };

  return (
    <>
      <HeaderStyled>
        <header
          className={`header ${sticky ? "header--sticky" : ""} ${
            navOpen ? "header--navOpen" : ""
          }`}
        >
          <div className="header__container container flex-align-center flex-space-between outer-grid">
            <Link to="/">
              <img
                className="header__logo img-responsive"
                src={logo}
                alt="Dwellant Logo"
                width="200vw"
              />
            </Link>
            <div className="header__right">
              <a href="https://secure.dwellant.com/Account/SignInOrRegister">
                <button className="btn btn__login desktop">
                  Log In / Sign Up
                </button>
              </a>
              <Icon
                name="bars"
                className="clickable"
                size="2rem"
                color="white"
                onClick={menuButtonOpen}
              />
            </div>
          </div>
          <div className="nav">
            {/* SEO pages */}
            <div style={{ display: "none" }}>
              <Link to="/xerosignup">Xero Signup</Link>
            </div>
            <div style={{ display: "none" }}>
              <Link to="/building-maintenance-software-programs">
                Building Maintenance Software Programs
              </Link>
            </div>
            <div style={{ display: "none" }}>
              <Link to="/building-management-software">
                Building Management Software
              </Link>
            </div>
            <div style={{ display: "none" }}>
              <Link to="/building-maintenance-tracking-software">
                Building Maintenance Tracking Software
              </Link>
            </div>
            <div style={{ display: "none" }}>
              <Link to="/buy-property-management-software">
                Buy Property Management Software
              </Link>
            </div>
            <div style={{ display: "none" }}>
              <Link to="/commercial-property-management-software">
                Commercial Property Management Software
              </Link>
            </div>
            <div style={{ display: "none" }}>
              <Link to="/property-asset-management-software">
                Property Asset Management Software
              </Link>
            </div>
            <div style={{ display: "none" }}>
              <Link to="/property-management-inspection-app">
                Property Management Inspection App
              </Link>
            </div>
            <div style={{ display: "none" }}>
              <Link to="/residential-block-management-software">
                Residential Block Management Software
              </Link>
            </div>
            <div style={{ display: "none" }}>
              <Link to="/software-for-property-maintenance-companies">
                Software For Property Maintenance Companies
              </Link>
            </div>
            {/* SEO pages */}
            <div className="nav-container container flex-col">
              <div className="nav__top flex-align-center flex-space-between">
                <Link to="/">
                  <img
                    className="header__nav-logo img-responsive"
                    src={logo}
                    alt="Dwellant Logo"
                    height="60"
                  />
                </Link>
                <Icon
                  name="times"
                  className="clickable"
                  size="2rem"
                  color="white"
                  onClick={menuButtonClose}
                />
              </div>

              <div className="flex-grow flex-align-center">
                <div className="row flex-grow">
                  <nav className="col-6 col-mobile-12 h6 flex-col">
                    <Fade bottom delay={600} cascade duration={500}>
                      <a href="https://secure.dwellant.com/Account/SignInOrRegister">
                        <button className="btn btn__login mobile">
                          Log In / Sign Up
                        </button>
                      </a>
                      <ul className="no-list-styles">
                        <li>
                          <Link
                            className="nav__link"
                            to="/"
                            onClick={menuButtonClose}
                          >
                            Home
                          </Link>
                        </li>

                        <li>
                          <Link
                            className="nav__link"
                            to="/Concierge-HV"
                            onClick={menuButtonClose}
                          >
                            Concierge HV
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="nav__link"
                            to="/CSite"
                            onClick={menuButtonClose}
                          >
                            CSite
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="nav__link"
                            to="/Bespoke"
                            onClick={menuButtonClose}
                          >
                            Bespoke
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="nav__link"
                            to="/PRSto"
                            onClick={menuButtonClose}
                          >
                            PRSto
                          </Link>
                        </li>

                        <li>
                          <Link
                            className="nav__link"
                            to="/Contractors"
                            onClick={menuButtonClose}
                          >
                            Contractors
                          </Link>
                        </li>

                        <li>
                          <Link
                            className="nav__link"
                            to="/team"
                            onClick={menuButtonClose}
                          >
                            The Team
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="nav__link"
                            to="/xero"
                            onClick={menuButtonClose}
                          >
                            Xero
                          </Link>
                        </li>
                      </ul>
                    </Fade>
                  </nav>
                  <div className="col-6 col-mobile-0 flex-col flex-justify-end flex-align-end">
                    <Fade
                      bottom
                      when={navOpen}
                      delay={900}
                      cascade
                      duration={500}
                    >
                      <div className="footer__address">
                      103 Vox Studios, 1-45 Durham Street, London, SE11 5JH
                      </div>
                      <Contacts delay={900} />
                      <SocialIcons delay="900" />
                      <div className="footer__copyright text-right text-center-mobile">
                        &copy; 2017 - 2023 CoDwellers.com Ltd, All Rights
                        Reserved.
                      </div>
                    </Fade>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      </HeaderStyled>
    </>
  );
};
