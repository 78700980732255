import React from 'react';

const Logo = (props) => {
    return (
        <a className="logo" href={props.href}>
            <img src={props.image} alt="Knight Frank" />
            <figcaption className="logo__caption">{props.caption}</figcaption>
        </a>
    )
}

export default Logo;