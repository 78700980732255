import React from 'react';

const Icon = props => {
	let classNames = props.brand ? 'fab' : 'fa';
	let styles = { fontSize: '3rem' };

	if (props.className) {
		classNames += ` ${props.className}`;
	}

	if (props.fw) {
		classNames += ' fa-fw';
	}

	if (props.name) {
		classNames += ` fa-${props.name}`;
	} else {
		// placeholder
		classNames += ' fa-address-book';
	}

	if (props.color) {
		classNames += ` color-${props.color}`;
	}

	if (props.size) {
		styles.fontSize = props.size;
	}

	if (props.margin) {
		if (props.margin === 'right') {
			styles.marginRight = '8px';
		} else if (props.margin === 'left') {
			styles.marginLeft = '8px';
		} else {
			styles.marginLeft = '8px';
			styles.marginRight = '8px';
		}
	}

	// if (props.customSize) {
	// 	styles.fontSize = null;
	// }

	return <i className={classNames} style={styles} onClick={props.onClick}></i>;
};

export default Icon;
