import React from 'react';

import Slider from "react-slick";
import Heading from './SubComponents/Heading';


const Logos = (props) => {
    let classNames = props.className ? [props.className] : [];
    let styles = {};

    const classNamesString = classNames.join(" ");

    var sliderSettings = {
        dots: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 3000,
        speed: 1000,
        slidesToShow: 6,
        slidesToScroll: 1,
        arrows: false,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 4
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 3
                }
            }
        ]
    };

    return (
        <div className={classNamesString} styles={styles}>
            <Heading small>{props.heading}</Heading>
            <Slider {...sliderSettings}>
                {props.children}
            </Slider>
        </div>
    )
}

export default Logos;
