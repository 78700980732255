import React from 'react';

const Row = (props) => {
    let style = props.style ? props.style : {};

    if (props.marginBottom) {
        style.marginBottom = props.marginBottom.length ? props.marginBottom : '32px'
    }

    return (
        <div
            className={`row ${props.className || ""}`}
            style={style}
        >
            {props.children}
        </div>
    )
}

export default Row;