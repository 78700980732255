import React from 'react';

const Subheading = (props) => {
    let classNames = props.className ? [props.className] : [];
    let styles = props.styles || {};

    if (props.color) {
        classNames.push(`color-${props.color}`)
    }

    const classNamesString = classNames.join(" ");

    return (
        <div
            className={`section__subheading ${classNamesString}`} style={styles}
        >
            {props.children}
        </div>
    )
}

export default Subheading;