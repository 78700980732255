import React from 'react';
import { Header } from '../Components/Header';
import Fade from 'react-reveal/Fade';
import { Helmet } from 'react-helmet';
import styled from 'styled-components/macro';
import * as gvars from '../styles2/GlobalVariables';
import GlobalStyle from '../styles2/GlobalStyles';
import OpeningSection from '../Components/New/OpeningSection';
import ProductFeatureRow from '../Components/New/ProductFeatureRow';
import BannerRow from '../Components/New/BannerRow';

import Button from '../Components/Button';
import TriColumnFeaturesBlock from '../Components/New/TriColumnFeaturesBlock';
import TriColumnFeaturesSingleColumn from '../Components/New/TriColumnFeaturesSingleColumn';

import ThumbnailBlockContainer from '../Components/New/ThumbnailBlockContainer';
import ThumbnailBlockSingle from '../Components/New/ThumbnailBlockSingle';

import TestimonialBlock from '../Components/New/TestimonalBlock';
import ContactUsSection from '../Components/New/ContactUsSection';

/***
 * IMAGES
 */
import background from '../Images/bitmaps/csite-bg.jpg';
import logo from '../Images/Product Logos/csite-logo.svg';

import SurveyUI from '../Images/illustrations/survey.svg';
import SyncUI from '../Images/illustrations/sync.svg';
import DocsUI from './../Images/illustrations/doc-reports.svg';

import PhoneTablet from './../Images/bitmaps/phone-tablet.jpg';
import Offline from './../Images/bitmaps/offline.jpg';
import AssetTracking from './../Images/bitmaps/asset-tracking.jpg';

import TabletIcon from './../Images/icons/tablet.svg';
import QuestionIcon from './../Images/icons/question.svg';
import SuitcaseIcon from './../Images/icons/suitcase.svg';
import DocWriteIcon from './../Images/icons/doc-write.svg';
import OfflineIcon from './../Images/icons/offline.svg';
import ChatIcon from './../Images/icons/chat.svg';
import AlertIcon from './../Images/icons/alert.svg';
import InstructionIcon from './../Images/icons/instructions.svg';
import AssetIcon from './../Images/icons/asset-tracker.svg';
import AuditTrailIcon from './../Images/icons/audit-trail.svg';
import SyncedIcon from './../Images/icons/synced.svg';
import GeoTaggedIcon from './../Images/icons/geo-tagged.svg';

import SiteInspector from './../Images/illustrations/site-inspector.svg';
import Staff from './../Images/illustrations/staff.svg';

import Lock from './../Images/bitmaps/lock.jpg';
import Lock2x from './../Images/bitmaps/lock@2x.jpg';
import PeopleUsingComputer from './../Images/bitmaps/people-using-computer.jpg';
import PeopleUsingComputer2x from './../Images/bitmaps/people-using-computer@2x.jpg';
import PersonUsingTablet from './../Images/bitmaps/responsive.jpg';
import PersonUsingTablet2x from './../Images/bitmaps/responsive@2x.jpg';
import ISOCertificate from './../Images/bitmaps/iso-certificate.png';

const CSiteWrapper = styled.div`
	@media ${gvars.device.laptop} {
	}
`;

const CSite = () => {
	return (
		<>
			<Header />
			<Fade>
				<CSiteWrapper>
					<OpeningSection bgImage={background} page={'csite'}>
						<div className="orange-border">
							<img src={logo} />
						</div>

						<h2 className="orange-border">CSite: Site inspections via your phone or tablet to save time, improve quality and ensure consistency.</h2>
						<p>Bin the paper-to-PC survey lag and start using CSite: our offline site survey tool that facilitates instant reporting and immediate digital audit trails.</p>
					</OpeningSection>

					<h1 className="outer-grid desktop-inner-grid orange">Developed to capture assets and surveys to your buildings profile in 3 steps.</h1>

					<ProductFeatureRow>
						<div className="text-col">
							<h2>
								<span>1.</span>Survey
							</h2>
							<p className="desktop-large">Complete your survey via phone or tablet, adding photos directly to your notes.</p>
						</div>

						<img className="image-col" src={SurveyUI} alt="Booking Business Lounge UI" />
					</ProductFeatureRow>
					<ProductFeatureRow right>
						<div className="text-col">
							<h2>
								<span>2.</span>Sync
							</h2>
							<p className="desktop-large">When online, sync your report and share with Dwellant Core (free version) and your colleagues.</p>
						</div>

						<img className="image-col" src={SyncUI} alt="Issues requests UI" />
					</ProductFeatureRow>
					<ProductFeatureRow>
						<div className="text-col">
							<h2>
								<span>3.</span>Report
							</h2>
							<p className="desktop-large">Word, PDF and CSV files are created and are always available for audit checks.</p>
						</div>

						<img className="image-col" src={DocsUI} alt="Booking Business Lounge UI" />
					</ProductFeatureRow>

					<BannerRow>
						<h2>Crafted for mobile devices with on-site use and functionality in mind.</h2>
						<Button icon href="#contact-us-section" options={{ verticalOffset: -40 }}>
							Contact Us Now
						</Button>
					</BannerRow>

					<ThumbnailBlockContainer>
						<ThumbnailBlockSingle image={PhoneTablet}>
							<div className="img-row"></div>

							<div className="text-container">
								<div className="title-container">
									<img className="icon" src={TabletIcon} />
									<h2>Phone and tablet</h2>
								</div>

								<p className="large">Works across any Android or iOS device, no need for write-ups.</p>

								<div className="text-row">
									<h3>Key Functions</h3>

									<div className="subtitle-container only-title">
										<img className="icon" src={QuestionIcon} />
										<h4>Template content and questions to increase continuity</h4>
									</div>
								</div>
								<div className="text-row  multiple-p">
									<h3>Product features</h3>

									<div className="subtitle-container only-title">
										<img className="icon" src={SuitcaseIcon} />
										<h4>Links to your Dwellant portfolio</h4>
									</div>

									<div className="subtitle-container only-title">
										<img className="icon" src={DocWriteIcon} />
										<h4>Automatic reporting</h4>
									</div>
								</div>
							</div>
						</ThumbnailBlockSingle>

						<ThumbnailBlockSingle image={Offline}>
							<div className="img-row"></div>

							<div className="text-container">
								<div className="title-container">
									<img className="icon" src={OfflineIcon} />
									<h2>Works offline</h2>
								</div>

								<p className="large">Sync when you are in the office.</p>

								<div className="text-row">
									<h3>Key Functions</h3>

									<div className="subtitle-container only-title">
										<img className="icon" src={ChatIcon} />
										<h4>Task setting linked to specific asset</h4>
									</div>
								</div>
								<div className="text-row  multiple-p">
									<h3>Product features</h3>

									<div className="subtitle-container only-title">
										<img className="icon" src={AlertIcon} />
										<h4>Defect management</h4>
									</div>

									<div className="subtitle-container only-title">
										<img className="icon" src={InstructionIcon} />
										<h4>Instructions ready to assign to contractors</h4>
									</div>
								</div>
							</div>
						</ThumbnailBlockSingle>

						<ThumbnailBlockSingle image={AssetTracking}>
							<div className="img-row"></div>

							<div className="text-container">
								<div className="title-container">
									<img className="icon" src={AssetIcon} />
									<h2>Asset tracking</h2>
								</div>

								<p className="large">Questions link to assets and historic answers.</p>

								<div className="text-row">
									<h3>Key Functions</h3>

									<div className="subtitle-container only-title">
										<img className="icon" src={AuditTrailIcon} />
										<h4>Digital audit trail</h4>
									</div>
								</div>
								<div className="text-row  multiple-p">
									<h3>Product features</h3>

									<div className="subtitle-container only-title">
										<img className="icon" src={SyncedIcon} />
										<h4>Synced and expanded content on Dwellant</h4>
									</div>

									<div className="subtitle-container only-title">
										<img className="icon" src={GeoTaggedIcon} />
										<h4>Geo and time stamp</h4>
									</div>
								</div>
							</div>
						</ThumbnailBlockSingle>
					</ThumbnailBlockContainer>

					<TestimonialBlock>
						<p class="quote-text">
							I really love the time CSite has saved me. I can complete a survey on-site meaning I’ve tripled the work I can do in a day. I’ve now got the option to add photos and notes directly into
							my report.
						</p>

						<p class="name">
							<strong>Andrew Godfrey</strong>
							<br></br>
							Development Manager
						</p>
					</TestimonialBlock>

					<BannerRow orange margin>
						<h2>CSite offers a wide range of time-saving benefits for you and your entire team.</h2>
					</BannerRow>

					<ProductFeatureRow customerBenefits right>
						<img className="image-col" src={SiteInspector} alt="Site inspector illustration" />
						<div className="text-col">
							<h2>Site inspector</h2>
							<ul>
								<li>Operates anywhere with offline use</li>
								<li>Cuts time on site</li>
								<li>Legally binding: Geo-location tracked and logged</li>
								<li>90% time saving in office admin</li>
								<li>Stores data where you can make it work for you</li>
							</ul>
						</div>
					</ProductFeatureRow>

					<ProductFeatureRow customerBenefits left>
						<img className="image-col" src={Staff} alt="Staff illustration" />

						<div className="text-col">
							<h2>Management</h2>
							<ul>
								<li>Turns tasks into work orders</li>
								<li>Easy to learn, use and train staff</li>
								<li>Ensures consistency of data across your teams</li>
								<li>Provides safe and secure storage of surveys</li>
								<li>Reliable end-to-end reporting to contractor completion</li>
							</ul>
						</div>
					</ProductFeatureRow>

					<BannerRow orange>
						<h2>CSite is a fully approved system that’s quick to install and very simple to use.</h2>
					</BannerRow>

					<TriColumnFeaturesBlock>
						<TriColumnFeaturesSingleColumn>
							<img src={Lock} srcSet={`${Lock2x} 2x`} className="img-row" alt="Photo of padlock" />

							<div className="text-container">
								<h2>Security & Data Protection</h2>

								<div className="text-row">
									<p>A system you can trust, Bespoke Buildings is part of our ISO 27001 certified network.</p>
									<p>Along with being GDPR compliant, it meets the highest requirements of European security, giving you full peace of mind for secure audit trails.</p>
									<img className="ISOCertificate" src={ISOCertificate} alt="ISO certficate" />
								</div>
							</div>
						</TriColumnFeaturesSingleColumn>

						<TriColumnFeaturesSingleColumn>
							<img src={PeopleUsingComputer} srcSet={`${PeopleUsingComputer2x} 2x`} className="img-row" alt="People using a computer" />

							<div className="text-container">
								<h2>Implementation & Training</h2>

								<div className="text-row">
									<p>
										It’s quick and easy to get your team up and running. Members of our Dwellant team will deliver training on-site early and late to cover shift changes, so no-one is left out. A
										special video provides training for new staff as they join.
									</p>
								</div>
							</div>
						</TriColumnFeaturesSingleColumn>

						<TriColumnFeaturesSingleColumn>
							<img src={PersonUsingTablet} srcSet={`${PersonUsingTablet2x} 2x`} className="img-row" alt="Person using a tablet" />

							<div className="text-container">
								<h2>Choose iOS or Android</h2>

								<div className="text-row">
									<p>There’s no need to invest in new software as Dwellant will run with your existing Windows PC or wireless Android tablet and/or phone.</p>
									<p>It’s reassuring to know your residents can use the bespoke page on both iOS and Android tablets.</p>
								</div>
							</div>
						</TriColumnFeaturesSingleColumn>
					</TriColumnFeaturesBlock>

					<ContactUsSection></ContactUsSection>
					<GlobalStyle />
					<Helmet>
						{/* <!-- Primary Meta Tags --> */}
						<title>CSite || Dwellant</title>
						<meta name="title" content="CSite || Dwellant" />
						<meta
							name="description"
							content="CSite: Site inspections via your phone or tablet to save time, improve quality and ensure consistency. Bin the paper-to-PC survey lag and start using CSite: our offline site survey tool that facilitates instant reporting and immediate digital audit trails."
						/>

						{/* <!-- Open Graph / Facebook --> */}
						<meta property="og:type" content="website" />
						<meta property="og:url" content="https://www.dwellant.com/" />
						<meta property="og:title" content="CSite || Dwellant" />
						<meta
							property="og:description"
							content="CSite: Site inspections via your phone or tablet to save time, improve quality and ensure consistency. Bin the paper-to-PC survey lag and start using CSite: our offline site survey tool that facilitates instant reporting and immediate digital audit trails."
						/>
						<meta property="og:image" content="https://i.ibb.co/JyFcFXW/ogimage.jpg" />

						{/* <!-- Twitter --> */}
						<meta property="twitter:card" content="summary_large_image" />
						<meta property="twitter:url" content="https://www.dwellant.com/" />
						<meta property="twitter:title" content="CSite || Dwellant" />
						<meta
							property="twitter:description"
							content="CSite: Site inspections via your phone or tablet to save time, improve quality and ensure consistency. Bin the paper-to-PC survey lag and start using CSite: our offline site survey tool that facilitates instant reporting and immediate digital audit trails."
						/>
						<meta property="twitter:image" content="https://i.ibb.co/JyFcFXW/ogimage.jpg" />
					</Helmet>
				</CSiteWrapper>
			</Fade>
		</>
	);
};

export default CSite;
