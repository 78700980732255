import React from 'react';
import styled from 'styled-components/macro';
import * as gvars from '../../styles2/GlobalVariables';

const BannerRowStyled = styled.div`
	text-align: center;
	color: white;
	padding-top: ${gvars.spacing.lSpace};
	padding-bottom: ${gvars.spacing.lSpace};
	background-color: ${props => (props.orange ? gvars.colours.orange : gvars.colours.grey)};
	margin-bottom: ${props => (props.margin ? gvars.spacing.xlSpace : 0)};

	button {
		margin-top: ${gvars.spacing.sSpace};
	}
`;

export default class BannerRow extends React.Component {
	constructor(props) {
		super(props);
	}
	componentDidMount() {
		console.log(this.props.orange);
	}
	render() {
		return (
			<BannerRowStyled orange={this.props.orange} margin={this.props.margin}>
				<div className="outer-grid desktop-inner-grid">{this.props.children /* this injects the content from wherever its used as a HOC, see bespoke.js as an example or remove this to see */}</div>
			</BannerRowStyled>
		);
	}
}

// export default OpeningSection;
