import React from "react";

const Section = props => {
    let sectionClassNames = props.className ? [props.className] : [];
    let sectionStyles = {};

    if (props.color) {
        sectionClassNames.push(`color-${props.color}`);
    }

    if (props.highlight) {
        sectionClassNames.push(`section--highlight-${props.highlight}`);
    }

    if (props.bg) {
        if (props.bg.startsWith("#")) {
            sectionStyles.backgroundColor = props.bg;
        } else {
            sectionClassNames.push(`bg-${props.bg}`);
        }
    }

    if (props.bgAttachment) {
        sectionStyles.backgroundAttachment = props.bgAttachment;
    }

    if (props.bgImage) {
        sectionStyles.backgroundImage = `url('${props.bgImage}')`;
    }

    if (props.bgPosition) {
        sectionStyles.backgroundSize = "auto";
        sectionStyles.backgroundPosition = props.bgPosition;
        sectionStyles.backgroundRepeat = "no-repeat";
    }

    if (props.bgSize) {
        sectionStyles.backgroundSize = props.bgSize;
    }

    if (props.containerless) {
        sectionClassNames.push("containerless");
    }

    if (props.hero) {
        sectionClassNames.push("hero");
    }

    if (props.fullHeight) {
        sectionClassNames.push("section--full-height");
    }

    if (props.textShadow) {
        sectionClassNames.push("text-shadow");
    }

    if (props.type) {
        sectionClassNames.push(`section--${props.type}`);
    }

    if (props.invert) {
        sectionClassNames.push(`section--invert`);
    }

    if (props.slim) {
        sectionClassNames.push(`section--slim`);
    }

    const sectionClassNamesString = sectionClassNames.join(" ");

    return (
        <section
            className={`section ${sectionClassNamesString}`}
            style={sectionStyles}
        >
            <div
                className={
                    props.containerless
                        ? ""
                        : props.fullWidth
                            ? "container--full-width"
                            : "container"
                }
            >
                {props.children}
            </div>
        </section>
    );
};

export default Section;
