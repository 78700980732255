import React from "react";
import { Header } from "../Components/Header";
import Fade from "react-reveal/Fade";
import { Helmet } from "react-helmet";
import styled from "styled-components/macro";
import * as gvars from "../styles2/GlobalVariables";
import OpeningSection from "../Components/New/OpeningSection";
import ProductFeatureRow from "../Components/New/ProductFeatureRow";
import BannerRow from "../Components/New/BannerRow";

import Button from "../Components/Button";
import ThumbnailBlockContainer from "../Components/New/ThumbnailBlockContainer";
import ThumbnailBlockSingle from "../Components/New/ThumbnailBlockSingle";
import TriColumnFeaturesBlock from "../Components/New/TriColumnFeaturesBlock";
import TriColumnFeaturesSingleColumn from "../Components/New/TriColumnFeaturesSingleColumn";

import TestimonialBlock from "../Components/New/TestimonalBlock";
import ContactUsSection from "../Components/New/ContactUsSection";

import Video from "../Components/Video";
import ConciergeHvVideoThumbnail from "./../Images/Product Logos/conciergehv-video-thumbnail-dark.png";
import Heading from "../Components/SubComponents/Heading";
import Highlight from "../Components/SubComponents/Highlight";
import Subheading from "../Components/SubComponents/Subheading";

import VideoBG from "../Videos/concierge-bg.mp4";

/***
 * IMAGES
 */
import background from "../Images/bitmaps/person-using-concierge.jpg";
import logo from "../Images/Product Logos/conciergehv-logo.png";

import PackageTrackingUI from "../Images/illustrations/package-tracking.svg";
import PackageTrackingUI2x from "../Images/illustrations/package-tracking@2x.png";
import PackageNotificationUI from "../Images/illustrations/package-notification.svg";
import PackageNotificationUI2x from "../Images/illustrations/package-notification@2x.png";
import SecurePackage from "./../Images/illustrations/secure-package.svg";
import SecurePackage2x from "./../Images/illustrations/secure-package@2x.png";

import PackageTracking from "./../Images/bitmaps/package-tracking.jpg";
import SecurityPass from "./../Images/bitmaps/security-pass.jpg";
import HandingOverPackage from "./../Images/bitmaps/handing-over-package.jpg";

import PackageTrackingIcon from "./../Images/icons/package-tracking.svg";
import KeyCardIcon from "./../Images/icons/key-card.svg";
import KeyIcon from "./../Images/icons/key.svg";
import PlaneIcon from "./../Images/icons/plane.svg";
import CogsIcon from "./../Images/icons/cogs.svg";
import DocAlertIcon from "./../Images/icons/document-alert.svg";
import PenIcon from "./../Images/icons/pen.svg";
import BarcodeIcon from "./../Images/icons/barcode.svg";
import PeopleIcon from "./../Images/icons/people.svg";
import AlertIcon from "./../Images/icons/alert.svg";
import LockIcon from "./../Images/icons/lock.svg";
import SupportIcon from "./../Images/icons/tools.svg";

import Residents from "./../Images/illustrations/residents.svg";
import Staff from "./../Images/illustrations/staff.svg";
import Freeholders from "./../Images/illustrations/freeholder.svg";

import Lock from "./../Images/bitmaps/lock.jpg";
import Lock2x from "./../Images/bitmaps/lock@2x.jpg";
import PeopleUsingComputer from "./../Images/bitmaps/people-using-computer.jpg";
import PeopleUsingComputer2x from "./../Images/bitmaps/people-using-computer@2x.jpg";
import PersonUsingTablet from "./../Images/bitmaps/responsive.jpg";
import PersonUsingTablet2x from "./../Images/bitmaps/responsive@2x.jpg";
import ISOCertificate from "./../Images/bitmaps/iso-certificate.png";

import PackageCalculator2 from "./../Components/New/PackageCalculator2";

const ConciergeWrapper = styled.div`
  .intro-section {
    text-align: center;
    #intro-title {
      margin-bottom: ${gvars.spacing.sSpace};
    }
    p {
      margin-bottom: ${gvars.spacing.lSpace};
    }
  }

  #background-video {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    object-fit: cover;

    height: 100vh;
  }

  #explainer-video {
    width: 100%;
    margin-bottom: ${gvars.spacing.xlSpace};
    max-width: 768px;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  .explainer-video {
    width: 100%;
    margin-bottom: ${gvars.spacing.xlSpace};
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
  @media ${gvars.device.laptop} {
    #background-video {
      display: block;
    }
  }
  @media ${gvars.device.desktopL} {
    #explainer-video {
      max-width: 1200px;
    }

    .explainer-video {
      max-width: 1200px;
    }
  }
  @media ${gvars.device.desktopLL} {
    #explainer-video {
      max-width: 1600px;
    }
    .explainer-video {
      max-width: 1600px;
    }
  }
`;

const ConciergeHv = () => {
  return (
    <>
      <Header />
      <Fade>
        <ConciergeWrapper>
          <div>
            <OpeningSection bgImage={background} page="conciergehv">
              <div className="orange-border">
                <img src={logo} alt="Concierge logo" />
              </div>

              <h2 className="orange-border">
                Concierge HV: your essential high volume package, key and
                visitor management software.
              </h2>
            </OpeningSection>
            <video id="background-video" loop autoPlay poster={background}>
              <source src={VideoBG} type="video/mp4" />
              Sorry, your browser doesn't support embedded videos.
              <img
                src={background}
                alt="Background for concierge video"
                title="Your browser does not support the <video> tag"
              ></img>
            </video>
          </div>
          <Video
            className="explainer-video"
            src="https://s3-eu-west-1.amazonaws.com/dwellantvideo/Dwellant-Concierge-HV.mp4"
            bgImage={ConciergeHvVideoThumbnail}
          >
            <Heading color="white">
              Package <Highlight>Intelligence</Highlight>
            </Heading>
            <Subheading color="white">
              Watch the process from scan to collection
            </Subheading>
          </Video>

          <div className="outer-grid desktop-inner-grid intro-section">
            <h1 id="intro-title" className="orange">
              Developed to keep track of packages and keys in a simple, 3-step
              process.
            </h1>
            <p>
              If online shopping deliveries, visitor tracking and key management
              have left your concierge overwhelmed, you’re at risk of losing
              crucial time, trust and ultimately income. Our fast, efficient and
              secure automated system has been designed with you in mind.
            </p>
          </div>

          <ProductFeatureRow>
            <div className="text-col">
              <h2>
                <span>1.</span>Scan and Process
              </h2>
              <p className="desktop-large">
                Scan and process residents’ packages and keys as they arrive or
                return.
              </p>
            </div>

            <img
              className="image-col"
              src={PackageTrackingUI}
              srcSet={`${PackageTrackingUI2x} 2x`}
              alt="Booking Business Lounge UI"
            />
          </ProductFeatureRow>
          <ProductFeatureRow right>
            <div className="text-col">
              <h2>
                <span>2.</span>Instant Notifications
              </h2>
              <p className="desktop-large">
                Notifies residents instantly, instructing actions via SMS and
                email.
              </p>
            </div>

            <img
              className="image-col"
              src={PackageNotificationUI}
              srcSet={`${PackageNotificationUI2x} 2x`}
              alt="Issues requests UI"
            />
          </ProductFeatureRow>
          <ProductFeatureRow>
            <div className="text-col">
              <h2>
                <span>3.</span>Secure Collection
              </h2>
              <p className="desktop-large">
                Easy and safe parcel retrieval, secure distribution and
                collection of keys.
              </p>
            </div>

            <img
              className="image-col"
              src={SecurePackage}
              srcSet={`${SecurePackage2x} 2x`}
              alt="Booking Business Lounge UI"
            />
          </ProductFeatureRow>

          <PackageCalculator2 />

          <BannerRow>
            <h2>
              Features and functionality are built in for cost effective
              property management.
            </h2>
            <Button
              icon
              href="#contact-us-section"
              options={{ verticalOffset: -40 }}
            >
              Contact Us Now
            </Button>
          </BannerRow>

          <ThumbnailBlockContainer>
            <ThumbnailBlockSingle image={PackageTracking}>
              <div className="img-row"></div>

              <div className="text-container">
                <div className="title-container">
                  <img
                    className="icon"
                    alt="Package tracking icon"
                    src={PackageTrackingIcon}
                  />
                  <h2>Package Tracking</h2>
                </div>

                <p className="large">
                  On demand fast and secure management of arrivals and
                  collections.
                </p>

                <div className="text-row">
                  <h3>Key Functions</h3>

                  <div className="subtitle-container">
                    <img className="icon" alt="Plane icon" src={PlaneIcon} />
                    <h4>Instant Notification</h4>
                  </div>
                  <p>
                    Residents awaiting a package receive an instant email or
                    SMS.
                  </p>
                </div>
                <div className="text-row  multiple-p">
                  <h3>Product features</h3>

                  <div className="subtitle-container">
                    <img className="icon" alt="Cogs icon" src={CogsIcon} />
                    <h4>Quick Click Process</h4>
                  </div>
                  <p>
                    A smart application that reduces the process from 5 minutes
                    to 3.5 seconds.
                  </p>
                  <div className="subtitle-container">
                    <img
                      className="icon"
                      alt="DocAlert icon"
                      src={DocAlertIcon}
                    />
                    <h4>Cloud Based</h4>
                  </div>
                  <p>
                    Paper processes are open to human error which leaves data
                    vulnerable.
                  </p>
                </div>
              </div>
            </ThumbnailBlockSingle>

            <ThumbnailBlockSingle image={SecurityPass}>
              <div className="img-row"></div>

              <div className="text-container">
                <div className="title-container">
                  <img className="icon" alt="Keycard icon" src={KeyCardIcon} />
                  <h2>On Site Passes</h2>
                </div>

                <p className="large">
                  Issues and keeps track of Site Access Permits to contractors.
                </p>

                <div className="text-row">
                  <h3>Key Functions</h3>

                  <div className="subtitle-container">
                    <img className="icon" alt="Pen icon" src={PenIcon} />
                    <h4>Secure Audit Trail</h4>
                  </div>
                  <p>
                    Digital audit trail avoids any manual errors saving time and
                    money.
                  </p>
                </div>
                <div className="text-row  multiple-p">
                  <h3>Product features</h3>

                  <div className="subtitle-container">
                    <img
                      className="icon"
                      alt="Barcode icon"
                      src={BarcodeIcon}
                    />
                    <h4>Scanning Technology</h4>
                  </div>
                  <p>
                    Packages and keys are managed with reliable speed and
                    accuracy.
                  </p>
                  <div className="subtitle-container">
                    <img className="icon" alt="People icon" src={PeopleIcon} />
                    <h4>Resident Control</h4>
                  </div>
                  <p>
                    Residents conveniently log in to update and monitor their
                    own data.
                  </p>
                </div>
              </div>
            </ThumbnailBlockSingle>

            <ThumbnailBlockSingle image={HandingOverPackage}>
              <div className="img-row"></div>

              <div className="text-container">
                <div className="title-container">
                  <img className="icon" alt="Key icon" src={KeyIcon} />
                  <h2>Key Release</h2>
                </div>

                <p className="large">
                  Built-in registered key release policy specific to residents’
                  contacts.
                </p>

                <div className="text-row">
                  <h3>Key Functions</h3>

                  <div className="subtitle-container">
                    <img className="icon" alt="Alert icon" src={AlertIcon} />
                    <h4>Auto Reminders</h4>
                  </div>
                  <p>Automated reminder notifications, save valuable time.</p>
                </div>
                <div className="text-row  multiple-p">
                  <h3>Product features</h3>

                  <div className="subtitle-container">
                    <img className="icon" alt="Lock" src={LockIcon} />
                    <h4>GDPR Compliant</h4>
                  </div>
                  <p>
                    All data is completely protected for total peace of mind.
                  </p>
                  <div className="subtitle-container">
                    <img
                      className="icon"
                      alt="Support icon"
                      src={SupportIcon}
                    />
                    <h4>Continued Support</h4>
                  </div>
                  <p>
                    Free dedicated training and support materials available on
                    request.
                  </p>
                </div>
              </div>
            </ThumbnailBlockSingle>
          </ThumbnailBlockContainer>

          <TestimonialBlock>
            <p class="quote-text">
              Parcels fully running for all the buildings in one week! Very good
              feedback from residents and staff. Everything works really well
              and fast! No issues at all.
            </p>

            <p class="name">
              <strong>Francis Watkinson</strong>
              <br></br>
              Development Manager
            </p>
          </TestimonialBlock>

          <BannerRow orange margin>
            <h2>
              Choose Concierge HV and enjoy the many benefits that our software
              delivers.
            </h2>
          </BannerRow>

          <ProductFeatureRow customerBenefits right>
            <img className="image-col" src={Staff} alt="Staff illustration" />
            <div className="text-col">
              <h2>Staff/Building Management</h2>
              <ul>
                <li>Paperless package and key handling reduces human error</li>
                <li>Up to 40% space saving</li>
                <li>Automated chasing for parcels and keys</li>
                <li>Instant stock check reports and logs</li>
                <li>Easy to produce audit reports</li>
              </ul>
            </div>
          </ProductFeatureRow>

          <ProductFeatureRow customerBenefits left>
            <img
              className="residents-image image-col"
              src={Residents}
              alt="Residents illustration"
            />
            <div className="text-col">
              <h2>Residents</h2>
              <ul>
                <li>Fast package collection with no queuing</li>
                <li>Instantly know when packages arrive</li>
                <li>Residents control their own online profiles</li>
                <li>Keep track of when keys are borrowed and returned</li>
                <li>Delivers security and peace of mind</li>
              </ul>
            </div>
          </ProductFeatureRow>

          <ProductFeatureRow customerBenefits right>
            <img
              className="image-col"
              src={Freeholders}
              alt="Freeholers illustration shaking hands"
            />

            <div className="text-col">
              <h2>Freeholder</h2>
              <ul>
                <li>Concierge HV team can focus on service not deliveries</li>
                <li>Convenience and efficiency for residents</li>
                <li>No more lost packages or keys</li>
                <li>Effective use of space for packages</li>
                <li>Fewer staff needed at peak periods</li>
              </ul>
            </div>
          </ProductFeatureRow>

          <BannerRow orange>
            <h2>
              Concierge HV is an approved system that’s quick to install and
              simple to use.
            </h2>
          </BannerRow>

          <TriColumnFeaturesBlock>
            <TriColumnFeaturesSingleColumn>
              <img
                src={Lock}
                srcSet={`${Lock2x} 2x`}
                className="img-row"
                alt="Padlock"
              />

              <div className="text-container">
                <h2>Security & Data Protection</h2>

                <div className="text-row">
                  <p>
                    A system you can trust, Concierge HV is ISO 27001 certified
                    – the gold standard of security.
                  </p>
                  <p>
                    Along with being GDPR compliant, it meets the highest
                    requirements of European security, giving you full peace of
                    mind.
                  </p>
                  <img
                    className="ISOCertificate"
                    src={ISOCertificate}
                    alt="ISO certficate"
                  />
                </div>
              </div>
            </TriColumnFeaturesSingleColumn>

            <TriColumnFeaturesSingleColumn>
              <img
                src={PeopleUsingComputer}
                srcSet={`${PeopleUsingComputer2x} 2x`}
                className="img-row"
                alt="People using a computer"
              />

              <div className="text-container">
                <h2>Implementation & Training</h2>

                <div className="text-row">
                  <p>
                    It’s quick and easy to get your team up and running. Members
                    of our Dwellant team will deliver training on-site early and
                    late to cover shift changes, so no-one is left out. A
                    special video provides training for new staff as they join.
                  </p>
                </div>
              </div>
            </TriColumnFeaturesSingleColumn>

            <TriColumnFeaturesSingleColumn>
              <img
                src={PersonUsingTablet}
                srcSet={`${PersonUsingTablet2x} 2x`}
                className="img-row"
                alt="Person using a tablet"
              />

              <div className="text-container">
                <h2>Choose iOS or Android</h2>

                <div className="text-row">
                  <p>
                    There’s no need to invest in new software as Dwellant will
                    run with your existing Windows PC or wireless Android tablet
                    and/or phone.
                  </p>
                  <p>
                    It’s reassuring to know you can use Concierge HV on both
                    Windows PCs and Android tablets at the same time when things
                    get busy.
                  </p>
                </div>
              </div>
            </TriColumnFeaturesSingleColumn>
          </TriColumnFeaturesBlock>

          <ContactUsSection></ContactUsSection>
          <Helmet>
            {/* <!-- Primary Meta Tags --> */}
            <title>Concierge HV || Dwellant</title>
            <meta name="title" content="Concierge HV || Dwellant" />
            <meta
              name="description"
              content="Concierge HV: your essential high volume package, key and visitor management software."
            />

            {/* <!-- Open Graph / Facebook --> */}
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://www.dwellant.com/" />
            <meta property="og:title" content="Concierge HV || Dwellant" />
            <meta
              property="og:description"
              content="Concierge HV: your essential high volume package, key and visitor management software."
            />
            <meta
              property="og:image"
              content="https://i.ibb.co/JyFcFXW/ogimage.jpg"
            />

            {/* <!-- Twitter --> */}
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content="https://www.dwellant.com/" />
            <meta property="twitter:title" content="Concierge HV || Dwellant" />
            <meta
              property="twitter:description"
              content="Concierge HV: your essential high volume package, key and visitor management software."
            />
            <meta
              property="twitter:image"
              content="https://i.ibb.co/JyFcFXW/ogimage.jpg"
            />
          </Helmet>
        </ConciergeWrapper>
      </Fade>
    </>
  );
};

export default ConciergeHv;
