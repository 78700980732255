import React from "react";
import styled from "styled-components/macro";
import * as gvars from "../../styles2/GlobalVariables";
import GlobalStyle from "../../styles2/GlobalStyles";
import Fade from "react-reveal/Fade";

import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

const Button2Styled = styled.a`
  background: ${gvars.colours.orange};
  ${(props) => props.type == "white" && "background:none"};
  ${(props) => props.type == "white" && "border:1px solid white"};
  ${(props) => props.type == "orange-outline" && "background:none"};
  ${(props) =>
    props.type == "orange-outline" &&
    `border:1px solid ${gvars.colours.orange}`};

  border-radius: 4px;
  padding: 1rem 2rem;
  font-size: 1.1rem;
  line-height: 1;
  font-family: "Cabin";
  outline: none;
  color: white;
  ${(props) =>
    props.type == "orange-outline" && `color: ${gvars.colours.orange}`};
  display: inline-block;
  margin-top: 1rem;

  @media ${gvars.device.desktopL} {
    padding: 0.9rem 1.6rem;
    font-size: 0.9rem;
  }
`;

const Button2 = (props) => {
  return (
    <Button2Styled type={props.type} target="_blank" href={props.href}>
      {
        props.children /* this injects the content from wherever its used as a HOC, see bespoke.js as an example or remove this to see */
      }
    </Button2Styled>
  );
};

export default Button2;
