import React from 'react';
import { Link } from 'react-router-dom';
import Icon from './Icon';
import animateScrollTo from 'animated-scroll-to';

const Button = props => {
	let classNames = props.className ? [props.className] : [];
	let styles = {};

	if (props.icon) {
		classNames.push('btn--icon');
	}

	if (props.small) {
		classNames.push('btn--small');
	}

	if (props.fullWidth) {
		classNames.push('btn--full-width');
	}

	if (props.fullWidthMobile) {
		classNames.push('btn--mobile-full-width');
	}

	if (props.color) {
		classNames.push(`btn--color-${props.color}`);
	}

	const classNamesString = classNames.join(' ');

	const scrollTo = id => {
		animateScrollTo(document.querySelector(`#${id}`), props.options);
	};

	const idOfElementToScrollTo = props.href ? (props.href.startsWith('#') ? props.href.substring(1, props.href.length) : null) : null;

	const onClick = () => {
		return props.onClick || idOfElementToScrollTo ? scrollTo(idOfElementToScrollTo) : null;
	};

	// This is poorly written in terms of duplication, but I can't figure out how to easily choose the wrapping
	// element type based on prop.
	// https://reactjs.org/docs/jsx-in-depth.html#choosing-the-type-at-runtime
	// The above link touches on it, but only gives React components as an example, while we want either a React
	// Component (Link) or a regular HTML element (button)

	if (props.href && !props.href.startsWith('#')) {
		return (
			<Link className={`btn ${classNamesString}`} style={styles} to={props.href}>
				{props.children}
				{props.icon && <Icon fw name={props.icon.length ? props.icon : 'caret-right'} size="1.25rem" margin="left" />}
			</Link>
		);
	} else {
		return (
			<button className={`btn ${classNamesString}`} style={styles} onClick={onClick}>
				{props.children}
				{props.icon && <Icon fw name={props.icon.length ? props.icon : 'caret-right'} size="1.25rem" margin="left" />}
			</button>
		);
	}
};

export default Button;
