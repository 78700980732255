import React from 'react';
import Icon from '../Icon';
import Fade from 'react-reveal/Fade';

const SocialIcons = props => {
	return (
		<Fade bottom distance="50px" cascade>
			<ul className="socials no-list-styles">
				<li>
					<a className="social" href="https://www.linkedin.com/company/dwellant.com" target="_blank" rel="noopener noreferrer">
						<Icon name="linkedin-in" brand size="2.6rem" />
						<figcaption className="social__caption">LinkedIn</figcaption>
					</a>
				</li>
				<li>
					<a className="social" href="https://twitter.com/dwellant" target="_blank" rel="noopener noreferrer">
						<Icon name="twitter" brand size="2.6rem" />
						<figcaption className="social__caption">Twitter</figcaption>
					</a>
				</li>
				<li>
					<a className="social" href="https://blog.dwellant.com/" target="_blank" rel="noopener noreferrer">
						<Icon name="blog" size="2.6rem" />
						<figcaption className="social__caption">Blog</figcaption>
					</a>
				</li>
				<li>
					<a className="social" href="mailto:sales@dwellant.com">
						<Icon name="at" size="2.6rem" />
						<figcaption className="social__caption">Email</figcaption>
					</a>
				</li>
			</ul>
		</Fade>
	);
};

export default SocialIcons;
