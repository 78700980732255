import React from 'react';

const Highlight = (props) => {
    let highlightClassNames = "color-highlight"

    if(props.color) {
        highlightClassNames = `color-${props.color}`
    }

    if(props.noTextShadow) {
        highlightClassNames += " no-text-shadow"
    }

    if(props.className) {
        highlightClassNames += ` ${props.className}`
    }

    return (
        <span className={highlightClassNames}>
            {props.children}
        </span>
    )
}

export default Highlight;