import React from 'react';
import Fade from 'react-reveal/Fade';

import { Header } from "../Components/Header";

import Heading from '../Components/SubComponents/Heading';
import Highlight from '../Components/SubComponents/Highlight';
import Subheading from '../Components/SubComponents/Subheading';
import Row from '../Components/Row';
import Col from '../Components/Col';
import Products from '../Components/Products';
import Section from '../Components/Section';
import Video from '../Components/Video';
import PackageCalculator from './../Components/PackageCalculator';
import BlueCornerGraphic from './../Images/blue-corner-graphic.png'
import ConciergeHvVideoThumbnail from './../Images/Product Logos/conciergehv-video-thumbnail-dark.png'
import Button from './../Components/Button';
import Iphone from './../Components/Iphone';
import iphoneContent from '../Images/iphone-content.jpg'


const Bespoke = () => {
    return (
        <>
            <Header />
            <Fade>
                <Section color="white" textShadow bgImage="https://source.unsplash.com/random/1920x1080" bgAttachment="fixed">
                    <Row>
                        <Col width="8" mobile="12">
                            <Heading size="h1">The <Highlight noTextShadow>Playground</Highlight></Heading>
                            <Subheading>Somewhere to play around with things - including new Components.</Subheading>
                            <Button href="#contact">Testing a Button with an href</Button>
                        </Col>
                    </Row>
                </Section>
                <Section type="hero"bgPosition="bottom right" bg="#e5e2dc">
                    <Row>
                        <Col width="4" mobile="0">
                            <Iphone image={iphoneContent} alt="How Dwellant looks for residents on their mobile devices" />
                        </Col>
                        <Col width="8" mobile="12">
                            <Heading size="h1"><Highlight noTextShadow>Bespoke</Highlight> Buildings</Heading>
                            <Subheading>Unlock your digital home - access any information, anywhere, using any device, at any time</Subheading>
                            <Button icon>Speak to us</Button>
                        </Col>
                    </Row>
                </Section>
                <Section>
                    <Row>
                        <Col>
                            <Video
                                src="https://s3-eu-west-1.amazonaws.com/dwellantvideo/Dwellant-Concierge-HV.mp4"
                                bgImage={ConciergeHvVideoThumbnail}
                            >
                                <Heading color="white">Package <Highlight>Intelligence</Highlight></Heading>
                                <Subheading color="white">A description related to the video </Subheading>
                            </Video>
                        </Col>
                    </Row>
                </Section>
                <Products />

                <Section bgImage={BlueCornerGraphic} bgPosition="bottom right" bgSize="50%">
                    <PackageCalculator />
                </Section>
            </Fade>
        </>
    )
}

export default Bespoke;