import React from 'react';
import geometric from '../Images/geometric-bg.png';
import Fade from 'react-reveal/Fade';
import { Helmet } from 'react-helmet';

import { Header } from "../Components/Header";
import Heading from "../Components/SubComponents/Heading";
import Highlight from "../Components/SubComponents/Highlight";
import Subheading from "../Components/SubComponents/Subheading";
import Row from "../Components/Row";
import Col from "../Components/Col";
import Section from "../Components/Section";
import Button from "../Components/Button";
import ContactForm from "../Components/ContactForm";

import Logo from './../Components/Logo';
import Logos from './../Components/Logos';
import knightFrank2 from '../Images/Client Logos/knightfrank2.png';

import DevicesProductMockups from "../Images/illustrations/home-opener-illustration2.png";
import ConnectingEveryone from "../Images/illustrations/connecting-everyone.svg";
import ClearComunication from "../Images/illustrations/clear-communication.svg";
import OpeningSection from "../Components/New/OpeningSection";
import ContactUsSection from '../Components/New/ContactUsSection';

const BuildingManagementSoftware = () => {
  return (
    <>
      <Header />
      <OpeningSection bgImage={geometric}>
        <h1 className="orange-border">Building Management <span className="orange">Software</span></h1>
        <p>
          Dwellant’s property management software is a holistic,
          cloud-based system for the 21st century. Like no other software,
          it brings together all stakeholders with ease and effect. To buy
          property management software, you need not look any further!
        </p>
      </OpeningSection>

      <Section slim>
        <Row>
          <Col width="4" mobile="12">
            <img
              src={DevicesProductMockups}
              alt="Dwellant’s building management software is a holistic, cloud-based system for the 21st century like no other software."
            />
          </Col>
          <Col width="8" mobile="12">
            <Heading>
              <Highlight>Building Management</Highlight> Software UK 
            </Heading>
            <p>
              Building Management Software today is the key to successful
              relationships with residents and stakeholders. To provide
              comprehensive block management agents are looking for seamless
              platforms to be able to manage all aspects of their services to
              the building and its tenants.
            </p>
            <p>
              At Dwellant we have thought long and hard about how we can
              remove worries about the management of a residential block or a
              larger developments, by creating resident building management
              software that is comprehensive and easy to use.
            </p>
            <p>
              Dwellant’s building management software has been designed for
              those who manage residential blocks, developments or schemes,
              and equally for those who live in apartments. It is equally
              intuitive and easy to use for the manager, the residents and
              those who provide services to the property. Its functionality
              has been developed after many years spent with property managers
              and residents to understand what is important to them. Our
              solution is a platform that is interactive, with multi-layer
              access levels, intuitive to use by anyone and an invaluable tool
              to make block management as easy as possible.
            </p>
            <p>
              Dwellant’s building management software has features for
              leaseholders, property managers and those owning or managing a
              building. Residential block management requires a wealth of
              specific skills; it requires specialist expertise, first-class
              communication, consistently high energy levels, and in depth
              knowledge of property maintenance and its underlying legal
              framework. These requirements are brought together and put to
              work by the software.
            </p>
            <p>
              Multi functional user features make Dwellant’s building
              management software a pleasure to work with. User configurable
              dashboards and reporting tools help manage day to day work
              tasks. Task management is linked to automated diary reminders
              and email alerts so light work is made of logging routine
              queries, maintenance management and health & safety compliance.
            </p>
            <img
              src={ConnectingEveryone}
              alt="Dwellant’s building management software UK is a holistic, cloud-based system for the 21st century like no other software."
            />
          </Col>
        </Row>
      </Section>

      <Section>
        <Row>
          <Col width="8" mobile="12">
            <Heading>
              <Highlight>Work</Highlight> Orders
            </Heading>
            <p>
              Work instructions and maintenance contracts with third parties
              are issued by the block manager through Dwellant’s building
              management software. The work instruction process is simplified
              through automated reminders and email communication with all
              parties. Health and safety requirements in relation to a
              specific works instruction (risk assessments and method
              statements for instance) are also manageable through the
              platform. Contractor invoices are automatically matched to the
              original works orders, meaning payments are swift and simple.
              Invoice authorisation can be as simple or multi-layered as the
              block management company wishes.
            </p>
            <p>
              Throughout the works ordering and invoicing/payment processes
              executed on the building management software, all financial data
              is linked live to the service charge accounts software package
              that the block manager is using, so that works are not ordered
              without the requisite funds being in place. The best building
              management software packages have an open API engine.
            </p>
            <p>
              The management of health and safety frequency-driven tasks (for
              example, fire risk assessments) and resulting actions (for
              example, installation of signage) is a fundamental part of a
              block manager’s day to day work. Dwellant’s building management
              software provides dashboards and email alerts to remind of these
              tasks, routine maintenance inspections and actions that require
              quotations and works at the building.
            </p>
          </Col>
          <Col width="4" mobile="12">
            <img
              src={ClearComunication}
              alt="Dwellant’s management software for buildings is a holistic, cloud-based system for the 21st century like no other software."
            />
          </Col>
        </Row>
      </Section>
      
      <ContactUsSection>
        <>
          <div className="block">
            <h2>Contact our dedicated sales team on</h2>
            <div className="contact-info-large">020 3397 2211</div>
          </div>
          <div className="block">
            <h2>Or Email us at</h2>
            <div className="contact-info-large">
              <a href="mailto:sales@dwellant.com" target="_blank">
                sales@dwellant.com
              </a>
            </div>
          </div>
        </>
      </ContactUsSection>

      <Section>
        <Row>
          <Col width="8" mobile="12">
            <Heading>
              <Highlight>Communication</Highlight>
            </Heading>
            <p>
              Communication with and from residents is easier than ever. For
              residents, our building management software is a web portal,
              accessible from any PC or smart device connected to the
              internet. Registration is straightforward and there are choices
              to make when receiving updates about the building. Email alerts
              and SMS are popular choices to receive news as it is released.
            </p>
            <p>
              All residents - not just leaseholders - are encouraged to sign
              up and use the Dwellant portal for their building. Residents can
              send maintenance requests or queries directly through the
              portal, and these enquiries will be distributed automatically to
              the person best placed to deal with them, swiftly and
              efficiently. Our building management software has been designed
              to be fully inclusive.
            </p>
            <p>
              Access to service charge information and service charge payments
              have never been more transparent. Secure access levels allow for
              privacy and confidentiality. Information about service charge
              budgets and accounts, and indeed any of the services provided by
              the management company can be easily found and reviewed through
              the Dwellant portal.
            </p>
            <p>
              Block management often involves the organisation and execution
              of complex projects. Such programmes of major works bring
              together professional groups (building, surveying, engineering)
              and residents, all of whom have to collaborate and communicate
              effectively together to ensure the success of the project.
              Dwellant provides the building management software platform to
              do just that.
            </p>
          </Col>
        </Row>
      </Section>

      <Section invert>
        <Row>
          <Col width="8" mobile="12">
            <Heading>
              <Highlight>CSite</Highlight> Inspection Tool
            </Heading>
            <p>
              With its interactive Csite building inspection app Dwellant
              provides the building managers with a tool that is accurate and
              saves time. The building management software integrates this
              tool as part of is suite of programs that make a property
              manager’s life so much easier!
            </p>
            <p>There are three simple steps to complete.</p>
            <p>
              Dwellant warrants that it will act only on instructions of the
              client in relation to the processing of any Personal Data
              performed by Dwellant on behalf of the client. That it has in
              place reasonably appropriate security measures (both technical
              and organisational) against unlawful or unauthorised processing
              of Personal Data. The web service is protected using security
              systems and technologies which include a Hardware Firewall
              configured with the minimum standard rule set and a software
              firewall configured to protect systems and restrict traffic to
              the minimum protocols. Further is administrative access given
              through a secure site and appropriate rules for password
              security enabled. Regular security monitoring and configuration
              checks are carried out. Dwellant provides market-leading
              software to ensure your and your clients’ data is handled
              correctly.
            </p>
          </Col>
        </Row>
      </Section>
      <Helmet>
        <title>Building Management Software || Dwellant</title>
        <meta
          type="description"
          content="Dwellant’s building management software is a holistic, cloud-based system for the 21st century. Like no other software like it-  it brings together all stakeholders in a residential building or development, with intelligence, speed and accuracy."
        />
      </Helmet>
    </>
  );
};

export default BuildingManagementSoftware;
