import React from "react";
import { Helmet } from "react-helmet";

import { Header } from "../Components/Header";
import Fade from "react-reveal/Fade";
import styled from "styled-components/macro";
import * as gvars from "../styles2/GlobalVariables";
import OpeningSection from "../Components/New/OpeningSection";
import background from "./../Images/bitmaps/london.jpg";

import Col from "./../Components/Col";
import Section from "./../Components/Section";

import Thumbnail from "./../Components/Thumbnail";

import Adam1 from "./../Images/The Team/adam.jpg";
import Adam2 from "./../Images/The Team/adam.jpg";

import Alex1 from "./../Images/The Team/alex1.jpg";
import Alex2 from "./../Images/The Team/alex2.jpg";

import Attila1 from "./../Images/The Team/attila1.jpg";
import Attila2 from "./../Images/The Team/attila2.jpg";

import Ben1 from "./../Images/The Team/ben.jpg";
import Ben2 from "./../Images/The Team/ben.jpg";

import Bernhard1 from "./../Images/The Team/bernhard1.jpg";
import Bernhard2 from "./../Images/The Team/bernhard2.jpg";

import Charlotte1 from "./../Images/The Team/charlotte.jpg";
import Charlotte2 from "./../Images/The Team/charlotte.jpg";

import Chloe1 from "./../Images/The Team/chloe1.jpg";
import Chloe2 from "./../Images/The Team/chloe2.jpg";

import Danny1 from "./../Images/The Team/danny1.jpg";
import Danny2 from "./../Images/The Team/danny2.jpg";

import Dave1 from "./../Images/The Team/dave1.jpg";
import Dave2 from "./../Images/The Team/dave2.jpg";

import David1 from "./../Images/The Team/david.jpg";
import David2 from "./../Images/The Team/david.jpg";

import Debs1 from "./../Images/The Team/debs1.jpg";
import Debs2 from "./../Images/The Team/debs2.jpg";

import Dot1 from "./../Images/The Team/dot1.jpg";
import Dot2 from "./../Images/The Team/dot2.jpg";

import Ed1 from "./../Images/The Team/ed1.jpg";
import Ed2 from "./../Images/The Team/ed2.jpg";

import Gabriel1 from "./../Images/The Team/gabriel1.jpg";
import Gabriel2 from "./../Images/The Team/gabriel2.jpg";

import Gaetano1 from "./../Images/The Team/gaetano1.jpg";
import Gaetano2 from "./../Images/The Team/gaetano2.jpg";

import Geeta1 from "./../Images/The Team/geeta1.jpg";
import Geeta2 from "./../Images/The Team/geeta2.jpg";

import Glynn1 from "./../Images/The Team/glynn1.jpg";
import Glynn2 from "./../Images/The Team/glynn2.jpg";

import Jon1 from "./../Images/The Team/jon1.jpg";
import Jon2 from "./../Images/The Team/jon2.jpg";

import Jc1 from "./../Images/The Team/jc1.jpg";
import Jc2 from "./../Images/The Team/jc2.jpg";

import Karl1 from "./../Images/The Team/karl1.jpg";
import Karl2 from "./../Images/The Team/karl2.jpg";

import Lizzie1 from "./../Images/The Team/lizzie1.jpg";
import Lizzie2 from "./../Images/The Team/lizzie2.jpg";

import Maciek1 from "./../Images/The Team/maciek1.jpg";
import Maciek2 from "./../Images/The Team/maciek2.jpg";

import Michael1 from "./../Images/The Team/michael1.jpg";
import Michael2 from "./../Images/The Team/michael2.jpg";

import Mo1 from "./../Images/The Team/mo1.jpg";
import Mo2 from "./../Images/The Team/mo2.jpg";

import Natalia1 from "./../Images/The Team/natalia1.jpg";
import Natalia2 from "./../Images/The Team/natalia2.jpg";

import Paul1 from "./../Images/The Team/paul.jpg";
import Paul2 from "./../Images/The Team/paul.jpg";

import Robert1 from "./../Images/The Team/robert1.jpg";
import Robert2 from "./../Images/The Team/robert2.jpg";

import Sachith1 from "./../Images/The Team/sachith1.jpg";
import Sachith2 from "./../Images/The Team/sachith2.jpg";

import Simon1 from "./../Images/The Team/simon1.jpg";
import Simon2 from "./../Images/The Team/simon2.jpg";

import Song1 from "./../Images/The Team/song1.jpg";
import Song2 from "./../Images/The Team/song2.jpg";

import Toks1 from "./../Images/The Team/toks1.jpg";
import Toks2 from "./../Images/The Team/toks2.jpg";

import Wilson1 from "./../Images/The Team/wilson1.jpg";
import Wilson2 from "./../Images/The Team/wilson2.jpg";

const TeamWrapper = styled.div`
  section.section .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  @media ${gvars.device.tablet} {
    section.section .container {
      .col {
        flex: 0 0 50%;
        max-width: 358px;
      }
    }
  }

  @media ${gvars.device.laptop} {
    section.section .container {
      .col {
        flex: 0 0 33%;
        max-width: initial;
      }
    }
  }
`;

const Team = () => {
  return (
    <TeamWrapper>
      <Header />
      <Fade>
        <OpeningSection bgImage={background}>
          <h1 className="orange-border">
            Meet <span className="orange">the team</span>
          </h1>

          <p>
            Our team draws on broad industry experience and networks to create
            the most powerful outcomes for our clients.
          </p>
        </OpeningSection>
      </Fade>
      <Section slim>
        <Col width="4" mobile="12">
          <Thumbnail
            image={Dave1}
            hoverImage={Dave2}
            bgPosition="top center"
            heading="Dave Piggin"
            description="Founder and CEO"
            link="#"
            textCenter
            imageHeight="350px"
            imageMaxWidth="350px"
          />
        </Col>
        <Col width="4" mobile="12">
          <Thumbnail
            image={Dot1}
            hoverImage={Dot2}
            bgPosition="top center"
            hoverBgPosition="center"
            heading="Dorothy Newman"
            link="#"
            textCenter
            imageHeight="350px"
            imageMaxWidth="350px"
          />
        </Col>
        <Col width="4" mobile="12">
          <Thumbnail
            image={Karl1}
            hoverImage={Karl2}
            bgPosition="top center"
            heading="Karl Lewis"
            link="#"
            textCenter
            imageHeight="350px"
            imageMaxWidth="350px"
          />
        </Col>
        <Col width="4" mobile="12">
          <Thumbnail
            image={Adam1}
            hoverImage={Adam2}
            heading="Adam"
            bgPosition="top center"
            hoverBgPosition="top center"
            link="#"
            textCenter
            imageHeight="350px"
            imageMaxWidth="350px"
          />
        </Col>
        <Col width="4" mobile="12">
          <Thumbnail
            image={Alex1}
            hoverImage={Alex2}
            bgPosition="top center"
            heading="Alex"
            link="#"
            textCenter
            imageHeight="350px"
            imageMaxWidth="350px"
          />
        </Col>
        <Col width="4" mobile="12">
          <Thumbnail
            image={Attila1}
            hoverImage={Attila2}
            bgPosition="top center"
            hoverBgPosition="center"
            heading="Attila"
            link="#"
            textCenter
            imageHeight="350px"
            imageMaxWidth="350px"
          />
        </Col>
        <Col width="4" mobile="12">
          <Thumbnail
            image={Ben1}
            hoverImage={Ben2}
            heading="Ben"
            bgPosition="top center"
            hoverBgPosition="top center"
            link="#"
            textCenter
            imageHeight="350px"
            imageMaxWidth="350px"
          />
        </Col>  
        <Col width="4" mobile="12">
          <Thumbnail
            image={Bernhard1}
            hoverImage={Bernhard2}
            heading="Bernhard"
            link="#"
            textCenter
            imageHeight="350px"
            imageMaxWidth="350px"
          />
        </Col>  
        <Col width="4" mobile="12">
          <Thumbnail
            image={Charlotte1}
            hoverImage={Charlotte2}
            heading="Charlotte"
            bgPosition="top center"
            hoverBgPosition="top center"
            link="#"
            textCenter
            imageHeight="350px"
            imageMaxWidth="350px"
          />
        </Col>        
        <Col width="4" mobile="12">
          <Thumbnail
            image={Chloe1}
            hoverImage={Chloe2}
            bgPosition="center"
            hoverBgPosition="center"
            heading="Chloe"
            link="#"
            textCenter
            imageHeight="350px"
            imageMaxWidth="350px"
          />
        </Col>
        <Col width="4" mobile="12">
          <Thumbnail
            image={Danny1}
            hoverImage={Danny2}
            bgPosition="top center"
            heading="Danny"
            link="#"
            textCenter
            imageHeight="350px"
            imageMaxWidth="350px"
          />
        </Col>

        <Col width="4" mobile="12">
          <Thumbnail
            image={David1}
            hoverImage={David2}
            bgPosition="top center"
            hoverBgPosition="top center"
            heading="David"
            link="#"
            textCenter
            imageHeight="350px"
            imageMaxWidth="350px"
          />
        </Col>

        <Col width="4" mobile="12">
          <Thumbnail
            image={Debs1}
            hoverImage={Debs2}
            bgPosition="center"
            hoverBgPosition="center"
            heading="Debashree"
            link="#"
            textCenter
            imageHeight="350px"
            imageMaxWidth="350px"
          />
        </Col>

        <Col width="4" mobile="12">
          <Thumbnail
            image={Ed1}
            hoverImage={Ed2}
            heading="Ed"
            link="#"
            textCenter
            imageHeight="350px"
            imageMaxWidth="350px"
          />
        </Col>

        <Col width="4" mobile="12">
          <Thumbnail
            image={Gabriel1}
            hoverImage={Gabriel2}
            bgPosition="top center"
            heading="Gabriel"
            link="#"
            textCenter
            imageHeight="350px"
            imageMaxWidth="350px"
          />
        </Col>
        <Col width="4" mobile="12">
          <Thumbnail
            image={Gaetano1}
            hoverImage={Gaetano2}
            bgPosition="top center"
            hoverBgPosition="center"
            heading="Gaetano"
            link="#"
            textCenter
            imageHeight="350px"
            imageMaxWidth="350px"
          />
        </Col>
        <Col width="4" mobile="12">
          <Thumbnail
            image={Geeta1}
            hoverImage={Geeta2}
            bgPosition="top center"
            hoverBgPosition="center"
            heading="Gesa"
            link="#"
            textCenter
            imageHeight="350px"
            imageMaxWidth="350px"
          />
        </Col>

        <Col width="4" mobile="12">
          <Thumbnail
            image={Glynn1}
            hoverImage={Glynn2}
            bgPosition="top center"
            heading="Glynn"
            link="#"
            textCenter
            imageHeight="350px"
            imageMaxWidth="350px"
          />
        </Col>        

        <Col width="4" mobile="12">
          <Thumbnail
            image={Jon1}
            hoverImage={Jon2}
            bgPosition="top center"
            hoverBgPosition="center"
            heading="Jon"
            link="#"
            textCenter
            imageHeight="350px"
            imageMaxWidth="350px"
          />
        </Col>

        <Col width="4" mobile="12">
          <Thumbnail
            image={Jc1}
            hoverImage={Jc2}
            bgPosition="center"
            hoverBgPosition="center"
            heading="Jonathan"
            link="#"
            textCenter
            imageHeight="350px"
            imageMaxWidth="350px"
          />
        </Col>

        <Col width="4" mobile="12">
          <Thumbnail
            image={Lizzie1}
            hoverImage={Lizzie2}
            bgPosition="center"
            hoverBgPosition="center"
            heading="Lizzie"
            link="#"
            textCenter
            imageHeight="350px"
            imageMaxWidth="350px"
          />
        </Col>

        <Col width="4" mobile="12">
          <Thumbnail
            image={Maciek1}
            hoverImage={Maciek2}
            bgPosition="center"
            hoverBgPosition="center"
            heading="Maciek"
            link="#"
            textCenter
            imageHeight="350px"
            imageMaxWidth="350px"
          />
        </Col>

        <Col width="4" mobile="12">
          <Thumbnail
            image={Michael1}
            hoverImage={Michael2}
            bgPosition="center"
            hoverBgPosition="center"
            heading="Mike"
            link="#"
            textCenter
            imageHeight="350px"
            imageMaxWidth="350px"
          />
        </Col>

        <Col width="4" mobile="12">
          <Thumbnail
            image={Mo1}
            hoverImage={Mo2}
            bgPosition="center"
            hoverBgPosition="center"
            heading="Mo"
            link="#"
            textCenter
            imageHeight="350px"
            imageMaxWidth="350px"
          />
        </Col>

        <Col width="4" mobile="12">
          <Thumbnail
            image={Natalia1}
            hoverImage={Natalia2}
            bgPosition="center"
            hoverBgPosition="center"
            heading="Natalia"
            link="#"
            textCenter
            imageHeight="350px"
            imageMaxWidth="350px"
          />
        </Col>

        <Col width="4" mobile="12">
          <Thumbnail
            image={Paul1}
            hoverImage={Paul2}
            bgPosition="top center"
            heading="Paul"
            link="#"
            textCenter
            imageHeight="350px"
            imageMaxWidth="350px"
          />
        </Col>
        <Col width="4" mobile="12">
          <Thumbnail
            image={Robert1}
            hoverImage={Robert2}
            bgPosition="top center"
            heading="Robert"
            link="#"
            textCenter
            imageHeight="350px"
            imageMaxWidth="350px"
          />
        </Col>
        <Col width="4" mobile="12">
          <Thumbnail
            image={Sachith1}
            hoverImage={Sachith2}
            bgPosition="center"
            hoverBgPosition="center"
            heading="Sachith"
            link="#"
            textCenter
            imageHeight="350px"
            imageMaxWidth="350px"
          />
        </Col>

        <Col width="4" mobile="12">
          <Thumbnail
            image={Simon1}
            hoverImage={Simon2}
            bgPosition="center"
            hoverBgPosition="center"
            heading="Simon"
            link="#"
            textCenter
            imageHeight="350px"
            imageMaxWidth="350px"
          />
        </Col>

        <Col width="4" mobile="12">
          <Thumbnail
            image={Song1}
            hoverImage={Song2}
            bgPosition="center"
            hoverBgPosition="center"
            heading="Song"
            link="#"
            textCenter
            imageHeight="350px"
            imageMaxWidth="350px"
          />
        </Col>

        <Col width="4" mobile="12">
          <Thumbnail
            image={Toks1}
            hoverImage={Toks2}
            heading="Toks"
            link="#"
            textCenter
            imageHeight="350px"
            imageMaxWidth="350px"
          />
        </Col>
        <Col width="4" mobile="12">
          <Thumbnail
            image={Wilson1}
            hoverImage={Wilson2}
            bgPosition="top center"
            heading="Wilson"
            link="#"
            textCenter
            imageHeight="350px"
            imageMaxWidth="350px"
          />
        </Col>
      </Section>
      <Helmet>
        {/* <!-- Primary Meta Tags --> */}
        <title>Meet the Team || Dwellant</title>
        <meta name="title" content="Meet the Team || Dwellant" />
        <meta
          name="description"
          content="Dwellant: Your complete in-the-cloud Property Management Platform. Our team draws on broad industry experience and networks to create the most powerful outcomes for our clients."
        />

        {/* <!-- Open Graph / Facebook --> */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.dwellant.com/" />
        <meta property="og:title" content="Meet the Team || Dwellant" />
        <meta
          property="og:description"
          content="Dwellant: Your complete in-the-cloud Property Management Platform. Our team draws on broad industry experience and networks to create the most powerful outcomes for our clients."
        />
        <meta
          property="og:image"
          content="https://i.ibb.co/JyFcFXW/ogimage.jpg"
        />

        {/* <!-- Twitter --> */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://www.dwellant.com/" />
        <meta property="twitter:title" content="Meet the Team || Dwellant" />
        <meta
          property="twitter:description"
          content="Dwellant: Your complete in-the-cloud Property Management Platform. Our team draws on broad industry experience and networks to create the most powerful outcomes for our clients."
        />
        <meta
          property="twitter:image"
          content="https://i.ibb.co/JyFcFXW/ogimage.jpg"
        />
      </Helmet>
    </TeamWrapper>
  );
};

export default Team;
