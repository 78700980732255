import React, { useState } from 'react';
import Button from './Button';
import Input from './Input';
import Row from './Row';
import Col from './Col';
import Heading from './SubComponents/Heading';
import Highlight from './SubComponents/Highlight';
import Subheading from './SubComponents/Subheading';
import Boxes from './../Images/boxes.png';
import Odometer from 'react-odometerjs';

const PackageCalculator = props => {
	let classNames = props.className ? [props.className] : [];

	const classNamesString = classNames.join(' ');

	const [result, setResult] = useState(0);
	const [units, setUnits] = useState('');



	const calculateResult = () => setResult(10 * units);

	return (
		<div>
			<Row>
				<Col width="6" mobile="12">
					<Heading>
						<Highlight>Packages</Highlight> Calculator
					</Heading>
					<Subheading>Dwellant processed 2.4 million packages in 2020. How many packages will your building need to process in 2021?</Subheading>
					<div className="flex">
						<img src="https://source.unsplash.com/random/150x200" alt="Unsplash" className="hidden-mobile" />
						<div className={`packageCalculator form ${classNamesString}`}>
							<label className="packageCalculator__label">How many units are in your development?</label>
							<Input type="text" className="packageCalculator__input" value={units} onChange={e => setUnits(e.target.value)} icon="building" name="Units" />
							<Button onClick={calculateResult} icon>
								Calculate
							</Button>
						</div>
					</div>
				</Col>
				<Col width="6" mobile="12" noFade className="text-center">
					<Row>
						<Col width="12" bgImage={Boxes} bgSize="initial" bgRepeat="no-repeat" color="white" className="flex-center text-center flex-col" style={{ minHeight: '300px' }}>
							<div style={{ display: result ? 'flex' : 'none' }}>
								<Heading className="packageCalculator__result">
									<Odometer value={result} format="(,ddd)" />
									<Subheading>Packages</Subheading>
								</Heading>
							</div>
						</Col>
						<Col width="12" fade={{ when: result, delay: 2000 }}>
							<div>Concierge HV gives developments the power to process all these packages.</div>
							<Button icon>Find out more</Button>
						</Col>
					</Row>
				</Col>
			</Row>
		</div>
	);
};

export default PackageCalculator;
