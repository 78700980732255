import React from 'react';
import styled from 'styled-components/macro';
import * as gvars from '../../styles2/GlobalVariables';
import Masonry from 'react-masonry-css';

/****
 *
 * THE HEIGHT OF THIS MUST BE SET AS A PROP E.G
 * <TriColumnFeaturesBlock height="41rem">
 * This is unfortunately because a tri-column layout with different heights can only
 * be achived with flex column which also means the container needs a fixed height
 */

const TriColumnFeaturesBlockStyled = styled.div`
	background-color: ${gvars.colours.offwhite};
	padding-top: ${gvars.spacing.xlSpace};
	padding-bottom: ${gvars.spacing.xlSpace};

	/*************
 * DESKTOP QUERIES
*************/
	@media ${gvars.device.laptop} {
		.my-masonry-grid {
			display: -webkit-box; /* Not needed if autoprefixing */
			display: -ms-flexbox; /* Not needed if autoprefixing */
			display: flex;
			// margin-left: -30px; /* gutter size offset */
			// width: auto;
		}
		.my-masonry-grid_column {
			padding-left: 30px; /* gutter size */
			background-clip: padding-box;
			max-width: 33.33333333%;
		}
	}
`;

const breakpointColumnsObj = {
	default: 3,
	1030: 1,
};

export default class TriColumnFeaturesBlock extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<TriColumnFeaturesBlockStyled height={this.props.height}>
				<Masonry breakpointCols={breakpointColumnsObj} className="my-masonry-grid outer-grid" columnClassName="my-masonry-grid_column">
					{this.props.children /* this injects the content from wherever its used as a HOC, see bespoke.js as an example or remove this to see */}
				</Masonry>
			</TriColumnFeaturesBlockStyled>
		);
	}
}

// export default OpeningSection;
