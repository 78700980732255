import React from "react";
import Fade from "react-reveal/Fade";

import { Header } from "../Components/Header";
import Heading from "../Components/SubComponents/Heading";
import Row from "../Components/Row";
import Col from "../Components/Col";
import Section from "../Components/Section";
import { Link } from "react-router-dom";

const SeoIndex = () => {
  return (
    <>
      <Header />
      <Fade>
        <Section type="hero" invert>
          <Row>
            <Col width="6" mobile="12">
              <Heading size="h1">SEO Pages Index</Heading>

              <Link to="/building-maintenance-software-programs">
                building-maintenance-software-programs
              </Link>
              <Link to="/building-maintenance-tracking-software">
                building-maintenance-tracking-software
              </Link>
              <Link to="/building-management-software">
                building-management-software
              </Link>
              <Link to="/buy-property-management-software">
                buy-property-management-software
              </Link>
              <Link to="/commercial-property-management-software">
                commercial-property-management-software
              </Link>
              <Link to="/property-asset-management-software">
                property-asset-management-software
              </Link>
              <Link to="/property-management-inspection-app">
                property-management-inspection-app
              </Link>
              <Link to="/residential-block-management-software">
                residential-block-management-software
              </Link>
              <Link to="/software-for-property-maintenance-companies">
                software-for-property-maintenance-companies
              </Link>
              <Link to="/sample-page">sample-page</Link>
            </Col>
            <Col width="6" mobile="12">
              <img
                src="https://source.unsplash.com/random/500x600"
                alt="UnSplash"
              />
            </Col>
          </Row>
        </Section>
      </Fade>
    </>
  );
};

export default SeoIndex;
