import React from 'react';
import Button from './Button';
import Input from './Input';
import Fade from 'react-reveal/Fade';
import Highlight from './SubComponents/Highlight';
import Heading from './SubComponents/Heading';

const ContactForm = (props) => {
    return (
        <div className="contact-form">
            <Heading className="text-center">Like to <Highlight>know more?</Highlight></Heading>
            <form className="align-center">
                <Fade bottom distance="50px">
                    <Input name="Name" icon="user" />
                    <Input name="Company" icon="building" />
                    <Input name="Telephone" pattern="\d{1,5}" title="Please enter numbers only" icon="mobile-alt" />
                    <Input name="Email Address" icon="at" />
                    <textarea type="text" rows="7" placeholder="Ask us anything..."></textarea>
                    <Button icon="paper-plane" fullWidth>Send</Button>
                </Fade>
            </form>
        </div>
    )
}

export default ContactForm;
