import React from "react";
import styled from "styled-components/macro";
import * as gvars from "../../styles2/GlobalVariables";
import GlobalStyle from "../../styles2/GlobalStyles";
import Fade from "react-reveal/Fade";

const ProductFeatureRowStyled = styled.div`
  margin-bottom: 4.2rem;

  &:last-of-type {
    margin-bottom: 0;
  }
  text-align: center;
  .text-col {
    text-align: center;
    margin-bottom: ${gvars.spacing.mSpace};
    h2 {
      margin-bottom: ${gvars.spacing.sSpace};
      color: ${(props) =>
        props.customerBenefits ? gvars.colours.orange : "inherit"};
    }
    span {
      margin-right: 0.5rem;
      color: ${gvars.colours.orange};
    }
    ul {
      list-style-type: none;
      padding: 0;
    }
    li {
      margin-bottom: 1rem;
    }
  }
  .image-col {
    text-align: center;

    ${(props) =>
      props.customerBenefits && `margin-bottom: ${gvars.spacing.lSpace}`};
    ${(props) => props.home && `margin-bottom: ${gvars.spacing.mSpace}`};
  }
  img.residents-image {
    margin-left: -5rem;
  }

  /*************
 	* DESKTOP QUERIES
	*************/
  @media ${gvars.device.laptop} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${(props) => props.lastXero && `margin-bottom:4.7rem`};
    .text-col {
      text-align: left;
      width: 40%;
      margin-bottom: 0;

      ${(props) => (props.right ? "margin-left: 3rem" : "margin-right: 3rem")};
      ${(props) => props.right && "order:3"};
      ${(props) => props.left && "order:1"};

      ul {
        list-style-type: disc;
      }
      li {
        margin-bottom: 0.6rem;
        line-height: 1.7rem;
      }

      ul.big {
        li {
          font-size: 1.2rem;
          margin-bottom: 0.7rem;
        }
      }
    }

    .image-col {
      margin-bottom: 0;
      ${(props) => props.left && "order:2"};
      width: ${(props) => (props.rightWidth ? props.rightWidth : "auto")};
      text-align: left;
      ${(props) => props.left && "text-align:right"};
      flex: 0 1 auto;
    }
    img.residents-image {
      margin-left: 0;
    }
  }

  /*************
 	* LARGE MONITOR QUERIES
	*************/
  @media ${gvars.device.desktopL} {
    margin-bottom: 5rem;
    .text-col {
      h2 {
        margin-bottom: 1rem;
      }
      li {
        font-size: 1rem;
        line-height: 1.6rem;
      }
    }
    .image-col {
    }
  }
`;

export default class ProductFeatureRow extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {}
  render() {
    return (
      <ProductFeatureRowStyled
        customerBenefits={this.props.customerBenefits}
        right={this.props.right}
        left={this.props.left}
        rightWidth={this.props.rightWidth}
        home={this.props.home}
        className="outer-grid product-feature-row"
      >
        <Fade>
          {
            this.props
              .children /* this injects the content from wherever its used as a HOC, see bespoke.js as an example or remove this to see */
          }
        </Fade>
      </ProductFeatureRowStyled>
    );
  }
}

// export default OpeningSection;
