import React from "react";
import styled from "styled-components/macro";
import * as gvars from "../../styles2/GlobalVariables";
import HubspotForm from "react-hubspot-form";
import ContactForm from "../ContactForm";

//Images

import Meeting from "../../Images/bitmaps/meeting.jpg";

const ContactUsSectionStyled = styled.div`
  .outer-grid iframe {
    margin: auto;
  }
  background-image: url(${Meeting});
  position: relative;
  background-size: cover;
  color: ${gvars.colours.black};
  .contact-form h2 {
    display: none;
  }
  .contact-info-large {
    font-size: 1.5rem;
    color: ${gvars.colours.orange};
  }
  .block {
    margin-bottom: ${gvars.spacing.mSpace};
  }

  .inner-content {
    padding-top: ${gvars.spacing.xlSpace};
    padding-bottom: ${gvars.spacing.xlSpace};

    position: relative;
    text-align: center;
  }

  h1 {
    margin-bottom: ${gvars.spacing.mSpace};
    color: ${gvars.colours.orange};
  }

  label {
    display: none !important;
  }
  form {
    display: none !important;
  }

  span {
    color: red !important;
  }
  /*************
 	* DESKTOP QUERIES
	*************/
  @media ${gvars.device.laptop} {
    h1 {
      margin-bottom: ${gvars.spacing.sSpace};
    }
    h2 {
      font-size: 1.25rem;
    }
    .contact-info-large {
    }
  }

  @media ${gvars.device.desktopL} {
    h1 {
    }
    h2 {
      font-size: 1.35rem;
    }
    .contact-info-large {
    }

    .inner-content div.block:nth-child(3) {
    }
  }
`;

const BlackOverlay = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: white;
  opacity: 0.93;
`;

export default class ContactUsSection extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {}

  render() {
    return (
      <ContactUsSectionStyled id="contact-us-section">
        <BlackOverlay />
        <div className="inner-content outer-grid">
          <h1>Contact Us</h1>
          {this.props.children}
          <div className="outer-grid desktop-inner-grid">
            <HubspotForm
              portalId="5981185"
              formId="7eccf187-ac25-40f6-ae40-6b03aaaea0f7"
              onSubmit={() => console.log("Submit!")}
              onReady={(form) => console.log("Form ready!")}
              loading={<div>Loading...</div>}
            />
          </div>
        </div>
      </ContactUsSectionStyled>
    );
  }
}

// export default OpeningSection;
