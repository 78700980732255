import React from 'react';
import Icon from './Icon';

const ContactForm = (props) => {
    let classNames = props.className ? ['input', props.className] : ['input'];
    let styles = {};

    if (props.icon) {
        classNames.push('input--icon')
    }

    const classNamesString = classNames.join(" ");

    return (
        <div className={classNamesString} styles={styles}>
            <input
                type={props.type ? props.type : 'text'}
                placeholder={props.name}
                pattern={props.pattern}
                required={props.required}
                title={props.title}
                className={props.bg ? `bg-${props.bg}` : ""}
                onChange={props.onChange}
                value={props.value}
                onFocus={props.onFocus}
            />
            {props.icon && (
                <Icon fw name={props.icon} color="grey" size="1rem" />
            )}
        </div>
    )
}

export default ContactForm;
