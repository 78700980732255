import React from "react";
import { Header } from "../Components/Header";
import Fade from "react-reveal/Fade";
import { Helmet } from "react-helmet";
import styled from "styled-components/macro";
import * as gvars from "../styles2/GlobalVariables";
import GlobalStyle from "../styles2/GlobalStyles";
import OpeningSection from "../Components/New/OpeningSection";
import ProductFeatureRow from "../Components/New/ProductFeatureRow";

import Button from "../Components/Button";
import Button2 from "../Components/New/Button2"; // The result of me not creating my own buttom component that lines up with my code style, don't worry everything else is consistent!
import MockupIntroSection from "../Components/New/MockupIntroSection";

import ContactUsSection from "../Components/New/ContactUsSection";

/***
 * IMAGES
 */
import background from "../Images/bitmaps/skyscraper-bg.jpg";
import logo from "../Images/Product Logos/xero-connected.svg";
import logoColour from "../Images/Product Logos/xero-connected-colour.svg";

import DevicesProductMockups from "../Images/illustrations/xero-mockup.jpg";

import ConnectionIllustration from "../Images/illustrations/connection.svg";
import PortfolioIllustration from "../Images/illustrations/portfolio.svg";
import FinanceIllus from "../Images/illustrations/finance.svg";
import SupplierIllus from "../Images/illustrations/supplier.svg";
import InvoiceIllus from "../Images/illustrations/invoice.svg";
import TrustIllus from "../Images/illustrations/trust.svg";

import IntegrationIllus from "../Images/illustrations/integration.svg";

const XeroWrapper = styled.div`
  @media ${gvars.device.laptop} {
  }
`;

const Divider = styled.div`
  margin-bottom: ${gvars.spacing.xlSpace};

  border-bottom: 1px solid ${gvars.colours.offwhite};
  text-align: center;
`;

const AboutXeroSection = styled.div`
  padding-top: ${gvars.spacing.lSpace};
  h2 {
    margin-bottom: ${gvars.spacing.ssSpace};
  }
  h2,
  p {
    text-align: center;
  }
  img {
    width: 224px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-bottom: ${gvars.spacing.mSpace};
  }
  li {
    font-size: 1rem;
    margin-bottom: ${gvars.spacing.ssSpace};
  }
  .button-section {
    text-align: center;
    margin-bottom: ${gvars.spacing.xlSpace};
  }

  @media ${gvars.device.tablet} {
    img {
      width: 300px;
    }
    .button-section {
      button {
        margin-right: ${gvars.spacing.sSpace};
      }
    }
  }

  @media ${gvars.device.laptop} {
    display: flex;
    padding-top: ${gvars.spacing.xlSpace};
    margin-bottom: ${gvars.spacing.xlSpace};
    img {
      order: 2;
      width: 320px;
    }
    h2,
    p {
      text-align: left;
    }
    p {
      margin-bottom: ${gvars.spacing.lSpace};
    }
    ul {
      li {
        margin-bottom: ${gvars.spacing.sSpace};
      }
    }
    .text-section {
      margin-right: 7rem;
    }
    .button-section {
      margin-bottom: 0;
      text-align: left;
    }
  }
`;

const Xero = () => {
  return (
    <>
      <Header />
      <Fade>
        <XeroWrapper>
          <OpeningSection bgImage={background} page={"csite"}>
            <img src={logo} alt="Xero logo" />
            <h2 className="orange-border">
              Dwellant and Xero: The Complete Property Management Solution.
            </h2>
            <p>
              Dwellant is the first property management platform to allow the
              use of mainstream accounting software for service charge block
              management. Experience unrivalled property management and
              accounting features built into a reliable, secure, in-the-cloud
              platform.
            </p>

            <div className="button-section">
              <Button
                href="#contact-us-section"
                options={{ verticalOffset: -40 }}
              >
                Book Demo
              </Button>
              <Button2
                type="white"
                href="http://www.xero.com/signup/?xtid=x30dwellantlive"
                options={{ verticalOffset: -40 }}
              >
                Try Xero for Free
              </Button2>
            </div>
          </OpeningSection>

          <MockupIntroSection>
            <img
              id="device-mockup-illustrations"
              src={DevicesProductMockups}
              alt="3 devices showing Dwellant's different product dashboards"
            />

            <h1>Why Choose Dwellant and Xero?</h1>
            <p className="desktop-inner-grid">
              Connecting your property management system and your accounting
              software will help your business run more efficiently and with
              greater transparency.
            </p>

            <p className="desktop-inner-grid">
              By choosing Dwellant as your Xero partner you have access to a
              best in class, award-winning property management system built from
              the ground up to manage your portfolio, service charge, suppliers,
              budgets, and residents.
            </p>
          </MockupIntroSection>

          <ProductFeatureRow customerBenefits home left>
            <img
              className="image-col"
              src={ConnectionIllustration}
              alt="Seamless Service Charge Banking"
            />

            <div className="text-col">
              <h2>Seamless Service Charge Banking</h2>

              <ul>
                <li>
                  Xero integrates effortlessly with your client bank accounts
                  and Dwellant seamlessly integrates with Xero
                </li>
                <li>
                  Use one Xero account per building or one Xero account for up
                  to 100 buildings
                </li>
                <li>Always on, live fund balances on Xero.</li>
              </ul>
            </div>
          </ProductFeatureRow>

          <ProductFeatureRow customerBenefits home right>
            <img
              className="image-col"
              src={PortfolioIllustration}
              alt="Portfolio adding illustration"
            />

            <div className="text-col">
              <h2>Easy Portfolio Set-Up</h2>

              <ul>
                <li>
                  Set up whole portfolios and new instructions effortlessly on
                  Xero then simply link to Dwellant in one click{" "}
                </li>
                <li>
                  The blocks, units, leaseholders, service charge schedules,
                  expense types, and key lease information are easily added in
                  Dwellant
                </li>
              </ul>
            </div>
          </ProductFeatureRow>

          <ProductFeatureRow customerBenefits home left>
            <img
              className="image-col"
              src={FinanceIllus}
              alt="General finance illustration"
            />

            <div className="text-col">
              <h2>Fast Budgets and Demands Creation</h2>

              <ul>
                <li>
                  Enter new service charge budgets directly onto Dwellant – easy
                  for the property managers to do themselves. All service charge
                  schedules and expense types are managed on Dwellant.
                </li>
                <li>
                  Service charge applications for payment are generated by
                  Dwellant giving you proof of delivery, and payments are made
                  directly into Xero or through the Dwellant portal – whatever
                  is easier for the leaseholder.
                </li>
              </ul>
            </div>
          </ProductFeatureRow>

          <ProductFeatureRow customerBenefits home right>
            <img
              className="image-col"
              src={SupplierIllus}
              alt="Supplier boxes on a conveyor belt"
            />

            <div className="text-col">
              <h2>Streamlined Supplier Management</h2>

              <ul>
                <li>
                  All supplier details are held on Dwellant for vetting and use
                  across portfolios{" "}
                </li>
                <li>
                  Raise your works orders on Dwellant and suppliers can upload
                  their invoices to match
                </li>
                <li>
                  Invoice management is through Dwellant and if you wish,
                  clients can join the invoice authorisation routine, with
                  direct access to Dwellant
                </li>
                <li>
                  Xero is primed to make all supplier payments with records
                  matching instantly on Dwellant.
                </li>
              </ul>
            </div>
          </ProductFeatureRow>

          <ProductFeatureRow customerBenefits home left>
            <img
              className="image-col"
              src={InvoiceIllus}
              alt="Invoice illustration"
            />

            <div className="text-col">
              <h2>Effortless Credit Control</h2>

              <ul>
                <li>
                  Xero supports standing orders and direct debits – your first
                  line of credit control defence
                </li>
                <li>
                  Dwellant is used to send auto-reminders to non-payers. Xero
                  produces full aged debtor reports for effective credit control
                  management.
                </li>
              </ul>
            </div>
          </ProductFeatureRow>

          <ProductFeatureRow customerBenefits home right>
            <img
              className="image-col"
              src={TrustIllus}
              alt="People shaking hands"
            />

            <div className="text-col">
              <h2>Full Client Transparency</h2>

              <ul>
                <li>
                  Dwellant + Xero encourages openness with your clients. Clients
                  can track transactions on their Xero app or use Dwellant to
                  run more meaningful reports.
                </li>
              </ul>
            </div>
          </ProductFeatureRow>

          <Divider className="inner-content outer-grid"></Divider>
          <MockupIntroSection>
            <img
              id="device-mockup-illustrations"
              src={IntegrationIllus}
              alt="Integration illustration"
            />

            <h1>How to connect Xero with Dwellant</h1>
            <p className="desktop-inner-grid">
              We at Dwellant will guide you along the process of integrating
              Xero with Dwellant. For more information please book a demo with
              our sales rep and we will get back to you as soon as possible!
            </p>
            <Button
              href="#contact-us-section"
              options={{ verticalOffset: -40 }}
            >
              Book a Demo
            </Button>
          </MockupIntroSection>

          <AboutXeroSection className="inner-content outer-grid">
            <img src={logoColour} alt="Xero connected logo" />
            <div className="text-section">
              <h2>About Xero</h2>{" "}
              <p className="desktop-large">
                Xero is world-leading online accounting software built for small
                business.
              </p>
              <ul>
                <li>
                  Get a real-time view of your cashflow. Log in anytime,
                  anywhere on your Mac, PC, tablet of phone to get a real-time
                  view of your cash flow. It’s small business accounting
                  software that’s simple, smart and occasionally magical.
                </li>
                <li>
                  Run your business on the go. Use our mobile app to reconcile,
                  send invoices, or create expense claims - from anywhere.
                </li>
                <li>
                  Get paid faster with online invoicing. Send online invoices to
                  your customers - and get updated when they’re opened.
                </li>
                <li>
                  Reconcile in seconds. Xero imports and categorises your latest
                  bank transactions. Just click ok to reconcile.
                </li>
              </ul>
              <div className="button-section">
                <Button
                  href="#contact-us-section"
                  options={{ verticalOffset: -40 }}
                >
                  Contact Us Now
                </Button>
                <Button2
                  type="orange-outline"
                  href="http://www.xero.com/signup/?xtid=x30dwellantlive"
                  options={{ verticalOffset: -40 }}
                >
                  Try Xero for Free
                </Button2>
              </div>
            </div>
          </AboutXeroSection>

          <ContactUsSection></ContactUsSection>

          <GlobalStyle />
          <Helmet>
            {/* <!-- Primary Meta Tags --> */}
            <title>Xero || Dwellant</title>
            <meta name="title" content="CSite || Dwellant" />
            <meta
              name="description"
              content="Dwellant and Xero: The Complete Property Management Solution."
            />

            {/* <!-- Open Graph / Facebook --> */}
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://www.dwellant.com/" />
            <meta property="og:title" content="Xero || Dwellant" />
            <meta
              property="og:description"
              content="Dwellant and Xero: The Complete Property Management Solution."
            />
            <meta
              property="og:image"
              content="https://i.ibb.co/JyFcFXW/ogimage.jpg"
            />

            {/* <!-- Twitter --> */}
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content="https://www.dwellant.com/" />
            <meta property="twitter:title" content="CSite || Dwellant" />
            <meta
              property="twitter:description"
              content="Dwellant and Xero: The Complete Property Management Solution."
            />
            <meta
              property="twitter:image"
              content="https://i.ibb.co/JyFcFXW/ogimage.jpg"
            />
          </Helmet>
        </XeroWrapper>
      </Fade>
    </>
  );
};

export default Xero;
