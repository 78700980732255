import React, { useState } from 'react';
import Section from './Section';
import Row from './Row';
import Col from './Col';
import Heading from './SubComponents/Heading';
import Highlight from './SubComponents/Highlight';
import { Link } from 'react-router-dom';

const Products = () => {
    const [activeProduct, setActiveProduct] = useState("bespoke");

    return (
        <Section slim invert>
            <Row>
                <Col shrink mobile="12">
                    <Heading>I want a <span className="visible-mobile">...</span></Heading>
                </Col>
                <Col grow mobile="12">
                    <div
                        onMouseOver={() => setActiveProduct("bespoke")}
                        className={`product ${(activeProduct === 'bespoke' || activeProduct === "") && 'product--active'}`}
                    >
                        <Link to="/bespoke"><Heading><Highlight>bespoke portal</Highlight> for my building</Heading></Link>
                    </div>
                    <div
                        onMouseOver={() => setActiveProduct("csite")}
                        className={`product ${activeProduct === 'csite' && 'product--active'}`}
                    >
                        <Link to="/csite"><Heading>smart and simple <Highlight>surveying app</Highlight></Heading></Link>
                    </div>
                    <div
                        onMouseOver={() => setActiveProduct("prsto")}
                        className={`product ${activeProduct === 'prsto' && 'product--active'}`}
                    >
                        <Link to="/prsto"><Heading>revolutionary <Highlight>rent-to-buy platform</Highlight></Heading></Link>
                    </div>
                    <div
                        onMouseOver={() => setActiveProduct("conciergehv")}
                        className={`product ${activeProduct === 'conciergehv' && 'product--active'}`}
                    >
                        <Link to="/concierge-hv"><Heading>digital concierge <Highlight>package system</Highlight></Heading></Link>
                    </div>
                    <div
                        onMouseOver={() => setActiveProduct("core")}
                        className={`product ${activeProduct === 'core' && 'product--active'}`}
                    >
                        <Link to="/"><Heading>description for the <Highlight>core product</Highlight>?</Heading></Link>
                    </div>
                </Col>
            </Row>
        </Section>
    )
}

export default Products;