import React from 'react';
import Fade from 'react-reveal/Fade';

import { Header } from "../Components/Header";
import Heading from '../Components/SubComponents/Heading';
import Highlight from '../Components/SubComponents/Highlight';
import Subheading from '../Components/SubComponents/Subheading';
import Row from '../Components/Row';
import Col from '../Components/Col';
import Section from '../Components/Section';
import Button from '../Components/Button';
import Icon from '../Components/Icon';

const NoMatch = () => {
    return (
        <>
            <Header />
            <Fade>
                <Section type="hero" fullHeight invert>
                    <Row>
                        <Col width="4" mobile="12" className="flex-center">
                            <Icon name="map-signs" size="13rem" color="highlight" />
                        </Col>
                        <Col width="8" mobile="12">
                            <Heading size="h1"><Highlight noTextShadow>404</Highlight> - Page not found</Heading>
                            <Subheading>Looks like you've taken a wrong turn. This is unfortunate.</Subheading>
                            <Button icon href="/">Return Home</Button>
                        </Col>
                    </Row>
                </Section>
            </Fade>
        </>
    )
}

export default NoMatch;