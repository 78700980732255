import React from 'react';

const Heading = (props) => {
    let classNames = props.className ? [props.className] : [];
    let hClassName = "";
    let styles = {};

    if (props.color) {
        classNames.push(`color-${props.color}`)
    }
    
    if (props.textColor) {
        hClassName = `color-${props.textColor}`;
    }

    const iconStyles = {
        fontSize: props.iconSize || "2rem",
    };

    const classNamesString = classNames.join(" ");

    return (
        <div className={`${classNamesString} heading flex-align-center`} style={styles}>
            {props.icon && (
                <i className={`fa fa-fw fa-${props.icon} item-icon color-${props.color || "highlight"}`} style={iconStyles} />
            )}
            {props.size === 'h1' && <h1 className={hClassName}>{props.children}</h1>}
            {(props.size === 'h2' || !props.size) && (
                <h2 className={hClassName}>{props.children}</h2>
            )}
            {props.size === 'h3' && <h3 className={hClassName}>{props.children}</h3>}
            {props.size === 'h4' && <h4 className={hClassName}>{props.children}</h4>}
            {props.size === 'h5' && <h5 className={hClassName}>{props.children}</h5>}
        </div>
    )
}
export default Heading;