import React from 'react';
import Fade from 'react-reveal/Fade';
import Icon from './Icon';
import Button from './Button';

const Thumbnail = (props) => {
    let classNames = props.className ? [props.className] : [];
    let styles = {};
    let imageStyles = {};
    let hoverImageStyles = {};

    if (props.bg) {
        classNames.push(`thumbnail--bg bg-${props.bg}`)
    }

    if (props.color) {
        classNames.push(`color-${props.color}`)
    }

    if (props.image) {
        imageStyles.backgroundImage = `url(${props.image})`;
        classNames.push("thumbnail--image");
    }

    if (props.hoverImage) {
        hoverImageStyles.backgroundImage = `url(${props.hoverImage})`;
        classNames.push("thumbnail--hoverImage");
    }

    if (props.bgPosition) {
        imageStyles.backgroundPosition = props.bgPosition;
        hoverImageStyles.backgroundPosition = props.bgPosition;
    }

    if (props.hoverBgPosition) {
        hoverImageStyles.backgroundPosition = props.hoverBgPosition;
    }

    if (props.horizontal) {
        classNames.push("thumbnail--horizontal");
    }

    if (props.textCenter) {
        classNames.push("thumbnail--text-center");
    }

    if (props.btnCenter) {
        classNames.push("thumbnail--btn-center");
    }

    if (props.btnFullWidth) {
        classNames.push("thumbnail--btn-fullWidth");
    }

    if (props.icon) {
        classNames.push("thumbnail--icon")
    }

    if (props.imageHeight) {
        imageStyles.minHeight = props.imageHeight;
    }

    if (props.imageMaxWidth) {
        imageStyles.maxWidth = props.imageMaxWidth;
        imageStyles.margin = '0 auto';
    }

    const classNamesString = classNames.join(" ");


    const thumbnailContents = (
        <div className={`thumbnail ${classNamesString}`} styles={styles}>
            <div className="thumbnail__image" style={imageStyles}>
                {props.icon && (
                    <Icon name={props.icon} size="6rem" color="highlight" />
                )}
                {props.hoverImage && (
                    <div className="thumbnail__image thumbnail__hoverImage" style={hoverImageStyles} ></div>
                )}
            </div>
            <div className="thumbnail__body">
                <div class="thumbnail__heading">{props.heading}</div>
                <p className="thumbnail__description">{props.description}</p>
                {props.btn && (
                    <div className="btn-container">
                        <Button small icon fullWidthMobile href={props.href}>{props.btn}</Button>
                    </div>
                )}
                {props.tags && (
                    props.tags.map((tag) =>
                        <div className="tag">{tag}</div>
                    )
                )}
            </div>
        </div>
    )


    if (props.fade) {
        return (
            <Fade {...props.fade}>
                {thumbnailContents}
            </Fade>
        )
    } else {
        return (
            <>
                {thumbnailContents}
            </>
        )
    }
}

export default Thumbnail;
