import React from 'react';
import styled from 'styled-components/macro';
import * as gvars from '../../styles2/GlobalVariables';
import QuoteMarks from '../../Images/icons/quotes.svg';
import Fade from 'react-reveal/Fade';

const TestimonialBlockStyled = styled.div`
	padding: ${gvars.spacing.xlSpace};

	.quote-text {
		font-style: italic;
		font-weight: bold;
	}

	//For quote mark graphic
	.regular-mark {
		margin-left: -1.7rem;
		margin-bottom: 0.5rem;
	}
	.upside-down {
		transform: rotate(180deg);
		margin-top: -0.6rem;
		margin-right: -1.7rem;
		float: right;
		margin-top: -5rem;
	}
	.name {
		color: ${gvars.colours.orange};
	}

	@media ${gvars.device.laptop} {
		.quote-text {
			font-size: 1.2rem;
			line-height: 1.9rem;
		}
	}
	@media ${gvars.device.desktopL} {
		.quote-text {
			font-size: 1.5rem;
			line-height: 2.1rem;
		}
	}
`;

export default class TestimonialBlock extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<TestimonialBlockStyled>
				<Fade>
					<div className="outer-grid desktop-inner-grid">
						<img src={QuoteMarks} className="regular-mark" alt="Quotation marks" />

						{this.props.children /* this injects the content from wherever its used as a HOC, see bespoke.js as an example or remove this to see */}
						<img src={QuoteMarks} className="upside-down" alt="Quotation marks" />
					</div>
				</Fade>
			</TestimonialBlockStyled>
		);
	}
}

// export default OpeningSection;
