import React from 'react';
import geometric from '../Images/geometric-bg.png';
import Fade from 'react-reveal/Fade';

import { Header } from "../Components/Header";
import Heading from "../Components/SubComponents/Heading";
import Highlight from "../Components/SubComponents/Highlight";
import Subheading from "../Components/SubComponents/Subheading";
import Row from "../Components/Row";
import Col from "../Components/Col";
import Section from "../Components/Section";
import Button from "../Components/Button";
import OpeningSection from "../Components/New/OpeningSection";

import { Helmet } from 'react-helmet';

import DevicesProductMockups from '../Images/illustrations/home-opener-illustration2.png';
import ClearComunication from '../Images/illustrations/clear-communication.svg';

const PropertyManagementInspectionApp = () => {
  return (
    <>
      <Header />
      <OpeningSection bgImage={geometric}>
        <h1 className="orange-border">Property Management Inspection <span className="orange">App</span></h1>
        <p>
          Dwellant’s software and property management inspection app is a
          holistic, cloud-based system for the 21st century. Like no other
          building maintenance software program like it- it brings
          together all stakeholders in a residential building or
          development with intelligence and elegance.
        </p>
      </OpeningSection>
      <Section>
        <Row>
          <Heading>Property Management Inspection App for iPad</Heading>
          <p>
            Property management inspection apps are the to-go tools for anyone
            in property and building maintenance. To provide comprehensive,
            diligent block management services the industry is looking for
            seamless platforms and apps to be able to manage all areas within
            a building safely and efficiently.
          </p>
          <p>
            At Dwellant we have spent some valuable time with property
            managers to ask them about tools and apps that would make their
            life easier. As a result we have created a property management
            inspection app that is
          </p>
          <ul>
            <li>Easy to use</li>
            <li>Simple to implement</li>
            <li>Shares data quickly</li>
            <li>Downloads reports instantly</li>
            <li>Converts surveys into works orders</li>
            <li>Keeps accurate records</li>
          </ul>
          <p>
            Dwellant’s management inspection app has been designed for
            companies and individuals who manage residential blocks,
            developments or schemes. It is equally intuitive and easy to use
            for all property managers, onsite staff and surveyors. Its
            functionality has been developed after many years spent with
            property managers and surveyors to understand what is important to
            them. Our solution is a tool that makes the life of the property
            manager that little bit easier.
          </p>
          <p>
            Residential block management requires a wealth of specific skills; it
            requires specialist expertise, first-class communication,
            consistently high energy levels, and in depth knowledge of
            property maintenance and its underlying legal framework. If there
            is a tool that makes some of these complex tasks swifter to
            complete and efficiently to manage then it is Dwellant’s property
            management inspection app.
          </p>
          <div>
            <h3>Csite property management inspection app</h3>
          </div>
          <p>
            With its interactive Csite property management inspection app
            Dwellant provides the building managers with a tool that is
            accurate and saves time.
          </p>

          <img
            src={DevicesProductMockups}
            alt="Dwellant’s property management inspection app is a holistic, cloud-based system for the 21st century."
          />

          <p>There are three simple steps to complete.</p>
          <ol>
            <li>
              Survey- when you carry out a building visit, you can now
              complete your survey via phone or tablet, adding photos directly
              to your notes.
            </li>
            <li>
              Synchronise- when online, synchronise your report and share with
              the Dwellant Core (free version), colleagues, clients,
              consultants.
            </li>
            <li>
              Report- the report that is created by the property management
              inspection app is available in Word, PDF and CSV files. The
              documents and photos are always available for audit checks.
            </li>
          </ol>
          <p>
            The Csite property management inspection app operates anywhere
            with offline use. The app is downloaded on a phone or tablet and
            is ready to use anytime. Using the app saves valuable time for the
            site surveyor. The survey and report are geo-location tracked and
            logged immediately, saving 90% office admin time. All data be
            stored where you can make it work for you and your clients.
          </p>
          <p>
            The management tasks following on from your site visit are simple.
            The Csite property management inspection app turns tasks into work
            orders there and then. The app and its functions are easy to learn
            and use. New staff are swiftly trained to use the tool. Csite
            ensures consistency of data across all teams and buildings. The
            property management inspection app provides safe and secure
            storage of surveys and reliable end-to-end reporting.
          </p>
          <p>
            Csite is a property management inspection app developed by
            Dwellant for the building management market. It works on any
            Android and IOS device; it removes all need for site inspection
            write ups. The surveyor using the property management inspection
            app can work offline and synchronise the report and all photos and
            data once back in range or back in the office. Questions linked to
            assets and historic asset data can be saved and followed up
            easily, any work orders that result from the queries can be issued
            immediately. The property management inspection app allows to
            create template content and questions to increase continuity in
            the business. This helps due diligence and best practice in all
            operational areas. Task setting can be linked to specific assets
            and a digital audit trail created.
          </p>

          <img
            src={ClearComunication}
            alt="Dwellant’s property management inspection app for iPad is a holistic, cloud-based system for the 21st century."
          />
          <p>
            A simple link to Dwellant’s core software allows integration and
            seamless, automatic reporting of all onsite issues. Defects
            management has never been so easy. Geo and time stamp validate the
            report and make it usable for explicit uses including witness
            statements. Once the site survey is finalised instructions are
            ready to be assigned to the contractor. The synchronised content
            from the site inspections is kept ready on the property management
            inspection app and can be expanded on or shared with others.
          </p>
          <p>
            Dwellant’s property management inspection app is ideal to use for
            any building survey. Be that for routine inspections, risk
            assessments, insurance reports or due diligence surveys. The tool
            is set up for each individual building and carrie sits individual
            features in a bespoke template. The property management inspection
            app allows the surveyors to vary templates and add, edit, cut as
            needs be during an inspection. The property management inspection
            app can be a perfect guide through a property for a person who has
            not yet seen all areas or is new to the building’s management. The
            app’s templates show all areas in a logical sequence, for example
            top to bottom or front to back and it highlights previous problem
            areas which may need to be surveyed in more detail.
          </p>
          <div>
            <h3>The property management inspection app as a training tool</h3>
          </div>
          <p>
            All onsite staff will need to be aware of the requirements for a
            building’s maintenance. To teach knowledge and awareness the team
            will require guidance and training. Dwellant’ s property
            management inspection app can serve as a training instrument that
            gets engagement and attention from all staff. The app can
            facilitate the on-site staff’s genuine interested in the
            building’s good maintenance and provision of services and their
            positive input in the overall management of the building. The use
            of the property management inspection app will bring together the
            efforts of both on-site and office based staff for the benefit of
            the property and its stakeholders. It means office based property
            managers can through the use of the property management inspection
            app delegate tasks to maintenance focussed site staff, who can
            then liaise directly with the contractors as and when needed. In
            this day and age of on-site staff being the heart and soul of a
            development, the greater the investment in the site staff, the
            less stressed the property manager becomes and the better service
            the residents and the landlord will receive. The Csite property
            management inspection app is your tool for achieving this goal.
          </p>
          <div>
            <h3>Security and Data Protection </h3>
          </div>
          <p>
            Software security is a top priority at Dwellant. Our ISO 27001
            certification gives our clients and users of the property
            management inspection app the assurance that all data and user
            functionality is protected by the highest security features. Our
            in-house developers maintain Dwellant’s suite of products,
            ensuring security remains paramount.
          </p>
          <p>
            Data Protection is addressed via a signed agreement between
            Dwellant and the client. This states that the client has the legal
            right to disclose all personal data that it discloses to Dwellant
            under or in connection with this agreement and further that the
            client shall fully comply with the Data Protection Act 1998 and
            GDPR Act 2016.{" "}
          </p>
          <p>
            Dwellant warrants that it will act only on instructions of the
            client in relation to the processing of any Personal Data
            performed by Dwellant on behalf of the client. That it has in
            place reasonably appropriate security measures (both technical and
            organisational) against unlawful or unauthorised processing of
            Personal Data. The web service is protected using security systems
            and technologies which include a Hardware Firewall configured with
            the minimum standard rule set and a software firewall configured
            to protect systems and restrict traffic to the minimum protocols.
            Further is administrative access given through a secure site and
            appropriate rules for password security enabled. Regular security
            monitoring and configuration checks are carried out.
          </p>
          <p>
            Dwellant provides market-leading property management software and
            an intuitive property management inspection app to ensure your and
            your clients’ data is handled correctly.{" "}
          </p>
          <p>
            Dwellant’s software and property management inspection app is a
            holistic, cloud-based system for the 21st century. Like no other
            building maintenance software program like it- it brings together
            all stakeholders in a residential building or development with
            intelligence and elegance.
          </p>
        </Row>
      </Section>
      <Helmet>
        <title>Property Management Inspection App || Dwellant</title>
        <meta
          name="description"
          content="Dwellant’s software and property management inspection app is a holistic, cloud-based system for the 21st century. Like no other building maintenance software program like it- it brings together all stakeholders in a residential building or development with intelligence and elegance."
        />
      </Helmet>
    </>
  );
};

export default PropertyManagementInspectionApp;
