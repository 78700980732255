import { createGlobalStyle } from "styled-components/macro";
import * as gvars from "../styles2/GlobalVariables";
const GlobalStyle = createGlobalStyle`

*{
    box-sizing:border-box;

}
html{
    font-size:100%;
}

body{
    font-size:16px;		font-family: 'Cabin', sans-serif;

    overflow-x: hidden;
}
body.menu-expanded{
    overflow:hidden;
}

div#hs-eu-cookie-confirmation {
  
    display: none;
}

/**********
 * FONTS
 ********/

h1,h2,h3,h4,h5,p,li,span,ul{
    margin-top:0;

}

h1{
    text-align:center;
    color: ${gvars.colours.black};
    margin-bottom: ${gvars.spacing.xlSpace};
    font-size: ${gvars.sizes.xlSize};
  
}

h2{
    font-weight:bold;
    font-size: ${gvars.sizes.lSize};line-height: 1.9rem;

}

h3{
    font-weight:bold;
    text-transform:uppercase;
    font-size:${gvars.sizes.mSize};
}

h4{
    font-weight:bold;
  text-transform:none;
    font-size:${gvars.sizes.sSize};
}

p.large{
    font-size:  ${gvars.sizes.mSize};
}
p,li{
    line-height: 1.6rem;
    font-size:  ${gvars.sizes.sSize};
}

.orange{
    color:${gvars.colours.orange};

}

.footer{padding-top:0;}

header li a.nav__link{
    transition: color 0.2s;
    text-transform: initial;
    margin-bottom: 1.8rem;
    @media (max-width: ${gvars.size.mobileM}){
        margin-bottom: 1rem;
    }
    @media (max-width: ${gvars.size.desktopL}){
        margin-bottom: 1.6rem;
    }

    margin-bottom: 1.6rem;
}
header li a.nav__link:hover{
    color:${gvars.colours.orange};
    
}

.header{
  .btn__login.desktop{
      display:none;
  }
  .btn__login.mobile{
    margin: 0;
    margin-bottom: 2rem;
  }

}



@media ${gvars.device.tablet}{
    .header{

        .header__right a {
            margin-right: 1.6rem;  
        }

        .btn__login.desktop {
            display: inline-flex;
      
            font-size: 1rem;
            padding: 0.7rem 1.1rem;
        }
        .btn__login.mobile {
            display: none;
        }
    }
	
}

@media ${gvars.device.desktopL}{
    .header{

     

        .btn__login.desktop {
  
      
            font-size: 0.9rem;
            padding: 0.65rem 0.8rem;
        }
     
    }
	
}



/**********
 * GRIDS
 ********/

.outer-grid{
    max-width:500px;
    margin-left:auto;
    margin-right:auto;
    padding-left:1.25rem;
    padding-right:1.25rem;
}



.footer{
    ul.socials{
        flex-wrap: wrap;
        justify-content: flex-start;
        li{
            width: 5rem;
            margin-bottom: 16px;
            margin-right: 32px;
            &:last-child{
                margin-right: 0;
            }
            a.social{
                justify-content: center;
            }
        }
    }
}


/*************
 * TABLET QUERIES
*************/

@media ${gvars.device.tablet} {
    html{font-size: 2.2vw;}
h1{   font-size: 1.9rem;}
h2{font-size: 1.52rem;line-height:2.3rem;}


    .outer-grid{
        width: 79%;
        max-width: 768px;
    
    }

    .footer{
        ul.socials{
            justify-content: flex-end;
            flex-wrap: nowrap;
            li{
          
                &:last-child{
                
                }
                a.social{
              
                }
            }
        }
    }
}
/*************
 * DESKTOP QUERIES
*************/
@media ${gvars.device.laptop} {

    html{
        font-size: 1.1vw;
    }
    
h1{
    text-align:center;
    
    margin-bottom: ${gvars.spacing.xlSpace};
    font-size: 2.3rem;
}

h2{
    font-weight:bold;
    font-size: 1.775rem;
    line-height: 2.3rem;
}

p.desktop-large{
    font-size:1.25rem;
    line-height:2rem;
}


    .outer-grid{
        width: 76%;
        max-width:unset;
        margin-left:auto;
        margin-right:auto;
        padding-left:0;
        padding-right:0;
    }

    .desktop-inner-grid{
        width: 54vw;
        max-width: 760px;
    }

    .footer{
        ul.socials{
         
            li{
                width:auto;
                &:last-child{
                
                }
                a.social{
              
                }
            }
        }
    }

}

/*****
1440x QUERIES
 */

@media ${gvars.device.laptopL} {
    html{
        font-size:100%;
    }
}

/*************
 * 1080p QUERIES
*************/

@media ${gvars.device.desktopL} {
    html{
        font-size: 1vw;
    }

p.large{
    font-size:  ${gvars.sizes.mSize}; 
}
    .outer-grid{
        max-width: 67%;

    }

    .desktop-inner-grid{
        max-width: 880px;


    }

    .container, .container--full-width{
        max-width: 1236px;
        padding-left: 0;
        padding-right: 0;
    }

    .font-size-3, .nav__link, .social{
     
    }

    .header .header__container{
        height: 6rem;
    }
    .header--sticky .header__container{
        height:4.3rem;
    }
    .header .header__logo {
        height: 2.9rem;
    width: auto !important;
    }
    .header--sticky .header__logo {
        height: 2.7rem;
    }

    .font-size-6, h6, .h6, .btn, .btn--small, .section--logos h1, .section--logos h4, .logo__caption, .footer__copyright{
    font-size:0.9rem !important;
}
.contact__label{
    margin-right:1rem;
}

.btn, .btn--small{
    padding: 0.9rem 1.6rem;
}


}

/****
ULTRA LARGE QUERIES
 */
@media ${gvars.device.desktopLL} {
html{
    font-size: 0.9vw;
    
}


.outer-grid{
    width: 67%;
    max-width: 1400px;

}
.container, .container--full-width{
    max-width: 1400px;
    padding-left: 0;
    padding-right: 0;
}
}


`;

export default GlobalStyle;
