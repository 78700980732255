import React from "react";
import Fade from "react-reveal/Fade";

import { Header } from "../Components/Header";
import Heading from "../Components/SubComponents/Heading";
import Highlight from "./../Components/SubComponents/Highlight";
import Subheading from "./../Components/SubComponents/Subheading";
import Row from "../Components/Row";
import Col from "./../Components/Col";
import Section from "./../Components/Section";
import Button from "./../Components/Button";
import Logo from "./../Components/Logo";
import Logos from "./../Components/Logos";

import knightFrank2 from "../Images/Client Logos/knightfrank2.png";
import Iphone from "../Components/Iphone";
import iphoneContent from "../Images/iphone-content.jpg";

const SamplePage = () => {
  return (
    <>
      <Header />
      <Fade>
        <Section>
          <Row>
            <Col width="10" mobile="12">
              <Heading size="h1">
                Sample H1
                <Highlight noTextShadow>Software</Highlight>
              </Heading>
              <Subheading>Nice copy</Subheading>
              <Subheading>
                Keywords:
                <Highlight>
                  <ul>
                    <li>key words</li>
                    <li>here</li>
                  </ul>
                </Highlight>
              </Subheading>
              <Button icon>Speak to us</Button>
            </Col>
          </Row>
        </Section>

        <Section slim>
          <Row>
            <Col width="4" mobile="12">
              <img
                alt="Placeholder"
                src="https://source.unsplash.com/random/400x1000"
              />
            </Col>
            <Col width="8" mobile="12">
              <Heading>
                <Highlight>A nice</Highlight> Title
              </Heading>
              <p>Opening paragraph</p>
              <img
                alt="Placeholder"
                src="https://source.unsplash.com/random/1280x720"
              />
            </Col>
          </Row>
        </Section>

        <Section>
          <Row>
            <Col width="8" mobile="12">
              <Heading>
                <Highlight>Normal</Highlight> Section
              </Heading>
              <p>Paragraph</p>
            </Col>
            <Col width="4" mobile="12">
              <img
                alt="Placeholder"
                src="https://source.unsplash.com/random/400x570"
              />
            </Col>
          </Row>
        </Section>

        <Section bg="offwhite" type="logos">
          <Logos heading="Integrations">
            <Logo
              href="#"
              image={knightFrank2}
              caption="Go to Knight Frank's website"
            />
            <Logo
              href="#"
              image={knightFrank2}
              caption="Go to Knight Frank's website"
            />
            <Logo
              href="#"
              image={knightFrank2}
              caption="Go to Knight Frank's website"
            />
            <Logo
              href="#"
              image={knightFrank2}
              caption="Go to Knight Frank's website"
            />
            <Logo
              href="#"
              image={knightFrank2}
              caption="Go to Knight Frank's website"
            />
            <Logo
              href="#"
              image={knightFrank2}
              caption="Go to Knight Frank's website"
            />
            <Logo
              href="#"
              image={knightFrank2}
              caption="Go to Knight Frank's website"
            />
            <Logo
              href="#"
              image={knightFrank2}
              caption="Go to Knight Frank's website"
            />
          </Logos>
        </Section>

        <Section>
          <Row>
            <Col width="8" mobile="12">
              <Heading>
                <Highlight>Title</Highlight> One
              </Heading>
              <p>Paragraph</p>

              <Heading>
                <Highlight>Title</Highlight> Two
              </Heading>
              <p>Paragraph</p>
            </Col>
            <Col width="4" mobile="12">
              <Iphone
                image={iphoneContent}
                alt="How Dwellant looks for residents on their mobile devices"
              />
            </Col>
          </Row>
        </Section>
      </Fade>
    </>
  );
};

export default SamplePage;
