import React from 'react';
import { Header } from '../Components/Header';
import styled from 'styled-components/macro';
import { Helmet } from 'react-helmet';

import * as gvars from '../styles2/GlobalVariables';
import ProductFeatureRow from '../Components/New/ProductFeatureRow';
import ContactUsSection from '../Components/New/ContactUsSection';

const StyleWrapper = styled.div`
	h1 {
		margin-bottom: ${gvars.spacing.ssSpace};
	}

	@media (min-width: 1030px) {
		.desktop-inner-grid {
			width: 89%;
		}
	}

	.contact-info-large {
		font-size: 1.5rem;
		color: ${gvars.colours.orange};
	}

	.core-section {
		margin-bottom: ${gvars.spacing.xlSpace};
		padding-bottom: ${gvars.spacing.xlSpace};
		border-bottom: 1px solid ${gvars.colours.offwhite};
		.text-col {
			span {
				margin-right: 0;
			}
		}
		.intro-block {
			margin-bottom: ${gvars.spacing.xlSpace};

			h1 {
				margin-bottom: ${gvars.spacing.lSpace};
			}
			.dwellant-logo {
				max-width: 267px;
				margin-bottom: ${gvars.spacing.sSpace};
			}
			h2 {
				margin-bottom: ${gvars.spacing.ssSpace};
			}
			p {
				margin-bottom: ${gvars.spacing.mSpace};
			}
		}
		text-align: center;
	}
`;

const XeroSignup = () => {
	return (
		<>
			<Header fixedTopNav={true} />
			<StyleWrapper>
				<div style={{ paddingTop: '100px' }}></div>
				<div className="core-section">
					<div className="intro-block outer-grid desktop-inner-grid">
						<h2>Manage property efficiently with Dwellant</h2>
						<p>
							Dwellant is a state-of-the-art tool specially crafted to ease communication between managing agents and residents, simplify task management and solve problems that occur when managing a
							building.
						</p>
					</div>

					<ProductFeatureRow rightWidth="700px">
						<div className="text-col">
							<h2>
								Ready to get <span className="orange">started?</span>
							</h2>
							<p>
								Xero + Dwellant creates an unbeatable combination of Xeros simplicity with Property Management expertise built into the Dwellant platform.
								<br />
								<br />
								Simply complete your contact information, and let us speak to you about your business needs.
							</p>
							<div className="block">
								<h2>Contact our dedicated sales team on</h2>
								<div className="contact-info-large">020 3397 2211</div>
							</div>
							<div className="block">
								<h2>Or Email us at</h2>
								<div className="contact-info-large">
									<a href="mailto:sales@dwellant.com" target="_blank">
										sales@dwellant.com
									</a>
								</div>
							</div>
						</div>

						<div className="image-col">
							<ContactUsSection></ContactUsSection>
						</div>
					</ProductFeatureRow>
				</div>

				<Helmet>
					<title>Xero SignUp || Dwellant</title>
					<meta name="title" content="Xero SignUp || Dwellant" />
				</Helmet>
			</StyleWrapper>
		</>
	);
};

export default XeroSignup;
