import React from "react";
import { Header } from "../Components/Header";
import styled from "styled-components/macro";
import Fade from "react-reveal/Fade";
import * as gvars from "../styles2/GlobalVariables";
import OpeningSection from "../Components/New/OpeningSection";
import ProductFeatureRow from "../Components/New/ProductFeatureRow";
import BannerRow from "../Components/New/BannerRow";
import { Helmet } from "react-helmet";

import PackageCalculator from "./../Components/PackageCalculator";

import Button from "../Components/Button";
import TriColumnFeaturesBlock from "../Components/New/TriColumnFeaturesBlock";
import TriColumnFeaturesSingleColumn from "../Components/New/TriColumnFeaturesSingleColumn";
import TestimonialBlock from "../Components/New/TestimonalBlock";
import ContactUsSection from "../Components/New/ContactUsSection";

/***
 * IMAGES
 */
import background from "../Images/bitmaps/contractor.jpg";

import QuotationsList from "./../Images/illustrations/quotation-list.svg";
import WorkInstructions from "./../Images/illustrations/work-description.svg";
import PayTracking from "./../Images/illustrations/pay-tracking.svg";
import WorkNotification from "./../Images/illustrations/work-notification.svg";
import ContractorList from "./../Images/illustrations/contractor-list.svg";
import SafeContractor from "./../Images/illustrations/secure.svg";
import DocVault from "./../Images/illustrations/docvault.svg";
import InvoiceTracking from "./../Images/illustrations/invoice-tracking.svg";

import TrainingIcon from "./../Images/icons/training.svg";
import StaffAccessIcon from "./../Images/icons/staff-access.svg";
import DocumentationIcon from "./../Images/icons/documentation.svg";
import SupportIcon from "./../Images/icons/support.svg";
import FlexibilityIcon from "./../Images/icons/flexibility.svg";
import CooperationIcon from "./../Images/icons/cooperation.svg";

const ContractorsWrapper = styled.div`
  .post-opening-text-container {
    text-align: center;
    margin-bottom: ${gvars.spacing.xlSpace};
    h1 {
      margin-bottom: 1rem;
    }
  }
  .dwellant-team-title {
    color: ${gvars.colours.grey};
  }
  @media ${gvars.device.laptop} {
    .dwellant-team-title {
      font-size: 3rem;
    }
  }
`;

const IconGridContainer = styled.div`
  color: ${gvars.colours.grey};
  margin-bottom: ${gvars.spacing.xlSpace};
  .block {
    text-align: center;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: ${gvars.spacing.lSpace};

    img {
      margin-bottom: 0.7rem;
      width: 112px;
    }
    h2 {
      margin-bottom: 0.65rem;
    }
  }

  @media ${gvars.device.tablet} {
    display: flex;
    flex-wrap: wrap;
    .block {
      flex: 0 1 50%;
      max-width: 327px;
    }
    .inner-content {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  @media ${gvars.device.laptop} {
  }
`;

const Contractors = () => {
  return (
    <>
      <Header />
      <Fade>
        <ContractorsWrapper>
          <OpeningSection bgImage={background}>
            <h1 className="orange-border">
              We Love <span className="orange">Contractors</span>
            </h1>

            <p>
              Contractors and service partners are the life and soul for the
              property industry. Dwellant is a proven platform that assures
              grounds for successful projects and flourishing business.
            </p>
            <Button
              icon
              href="#see-how-this-works"
              options={{ verticalOffset: -80 }}
            >
              See How This Works
            </Button>
          </OpeningSection>

          <div
            id="see-how-this-works"
            className="outer-grid desktop-inner-grid post-opening-text-container"
          >
            <h1 className="orange">What can Dwellant's platform do for you?</h1>
            <p className="large">
              An easy to access one-stop-gap for all the information you need to
              know about the site and the job and the people you need to
              interact with
            </p>
          </div>

          <ProductFeatureRow right>
            <div className="text-col">
              <h2>Quotations</h2>
              <p className="desktop-large">
                Receive quotation request for remedial works and tenders
              </p>
            </div>

            <img
              className="image-col"
              src={QuotationsList}
              alt="Quotations List Screen"
            />
          </ProductFeatureRow>
          <ProductFeatureRow>
            <div className="text-col">
              <h2>Work instructions</h2>
              <p className="desktop-large">
                Receive work instructions with detail and speed, ability to
                update on works and provide information and documents relevant
                to the job
              </p>
            </div>

            <img
              className="image-col"
              src={WorkInstructions}
              alt="Work Instructions Screen"
            />
          </ProductFeatureRow>
          <ProductFeatureRow right>
            <div className="text-col">
              <h2>Payments</h2>
              <p className="desktop-large">
                You can follow the payment process and have visibility of
                timelines in a streamlined fashion.
              </p>
            </div>

            <img
              className="image-col"
              src={PayTracking}
              alt="Payment Tracking Screen"
            />
          </ProductFeatureRow>

          <ProductFeatureRow>
            <div className="text-col">
              <h2>System notifications of upcoming site visits</h2>
              <p className="desktop-large">
                A reliable tool for notifications in your job calendar
              </p>
            </div>

            <img
              className="image-col"
              src={WorkNotification}
              alt="Work Notification Screen"
            />
          </ProductFeatureRow>
          <ProductFeatureRow right>
            <div className="text-col">
              <h2>Contractor list</h2>
              <p className="desktop-large">
                Your access to the client's approved contractor base to provide
                your services to a range of customers
              </p>
            </div>

            <img
              className="image-col"
              src={ContractorList}
              alt="Contractor List"
            />
          </ProductFeatureRow>
          <ProductFeatureRow>
            <div className="text-col">
              <h2>Safe contractor</h2>
              <p className="desktop-large">
                Join the safe contractor scheme and provide best practice and
                diligence
              </p>
            </div>

            <img
              className="image-col"
              src={SafeContractor}
              alt="Safe Contractor Icon"
            />
          </ProductFeatureRow>

          <ProductFeatureRow right>
            <div className="text-col">
              <h2>Safety documents</h2>
              <p className="desktop-large">
                Upload safety documents for ease and transparency and smooth
                running of a job, download all site-specific information for job
                preparation
              </p>
            </div>

            <img
              className="image-col"
              src={DocVault}
              alt="Vault Illustration"
            />
          </ProductFeatureRow>
          <ProductFeatureRow>
            <div className="text-col">
              <h2>Invoices</h2>
              <p className="desktop-large">
                Upload your invoices against work orders online to avoid delays
              </p>
            </div>

            <img
              className="image-col"
              src={InvoiceTracking}
              alt="Invoice Tracking UI"
            />
          </ProductFeatureRow>

          <h1 className="outer-grid desktop-inner-grid dwellant-team-title">
            What Dwellant's team can provide you with
          </h1>

          <IconGridContainer className="outer-grid">
            <Fade bottom distance="50px">
              <div className="block">
                <div className="inner-content">
                  <img src={TrainingIcon} alt="Training Icon" />
                  <h2>Training for contractors</h2>
                  <p className="large">
                    Dwellant will provide you with simply to follow step by step
                    training how to use the system
                  </p>
                </div>
              </div>

              <div className="block">
                <div className="inner-content">
                  <img src={StaffAccessIcon} alt="Staff Access Icon" />
                  <h2>Staff Access</h2>
                  <p className="large">
                    Logins provided for your staff to be interactive on the
                    system and service your customer with ease and speed
                  </p>
                </div>
              </div>
              <div className="block">
                <div className="inner-content">
                  <img src={DocumentationIcon} alt="Documentation Icon" />
                  <h2>Documentation</h2>
                  <p className="large">
                    'How to guides' to look up any questions that may occur
                  </p>
                </div>
              </div>

              <div className="block">
                <div className="inner-content">
                  <img src={SupportIcon} alt="Support Icon" />
                  <h2>Constant Support</h2>
                  <p className="large">
                    Dwellant customer support team to call when there are any
                    queries{" "}
                  </p>
                </div>
              </div>

              <div className="block">
                <div className="inner-content">
                  <img src={FlexibilityIcon} alt="Flexibility Icon" />
                  <h2>Flexibility</h2>
                  <p className="large">
                    A team with an open mind for suggestions
                  </p>
                </div>
              </div>

              <div className="block">
                <div className="inner-content">
                  <img src={CooperationIcon} alt="Cooperation Icon" />
                  <h2>Cooperation</h2>
                  <p className="large">
                    Liaison for ongoing improvement of the supplier chain{" "}
                  </p>
                </div>
              </div>
            </Fade>
          </IconGridContainer>

          <ContactUsSection></ContactUsSection>
          <Helmet>
            {/* <!-- Primary Meta Tags --> */}
            <title>Contractors || Dwellant</title>
            <meta name="title" content="Contractors || Dwellant" />
            <meta
              name="description"
              content="Contractors and service partners are the life and soul for the property industry. Dwellant is a proven platform that assures grounds for successful projects and flourishing business."
            />

            {/* <!-- Open Graph / Facebook --> */}
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://www.dwellant.com/" />
            <meta property="og:title" content="Contractors || Dwellant" />
            <meta
              property="og:description"
              content="Contractors and service partners are the life and soul for the property industry. Dwellant is a proven platform that assures grounds for successful projects and flourishing business."
            />
            <meta
              property="og:image"
              content="https://i.ibb.co/JyFcFXW/ogimage.jpg"
            />

            {/* <!-- Twitter --> */}
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content="https://www.dwellant.com/" />
            <meta property="twitter:title" content="Contractors || Dwellant" />
            <meta
              property="twitter:description"
              content="Contractors and service partners are the life and soul for the property industry. Dwellant is a proven platform that assures grounds for successful projects and flourishing business."
            />
            <meta
              property="twitter:image"
              content="https://i.ibb.co/JyFcFXW/ogimage.jpg"
            />
          </Helmet>
        </ContractorsWrapper>
      </Fade>
    </>
  );
};

export default Contractors;
