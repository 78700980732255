import React, { useEffect } from "react";
import { Header } from "../Components/Header";
import Fade from "react-reveal/Fade";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet";

import objectFitImages from "object-fit-images";

import * as gvars from "../styles2/GlobalVariables";
import GlobalStyle from "../styles2/GlobalStyles";
import OpeningSection from "../Components/New/OpeningSection";
import ProductFeatureRow from "../Components/New/ProductFeatureRow";

import MainProductSection from "../Components/New/MainProductSection";

import Button from "../Components/Button";
import ContactUsSection from "../Components/New/ContactUsSection";
import MockupIntroSection from "../Components/New/MockupIntroSection";

/***
 * IMAGES
 */
import logo from "../Images/Product Logos/dwellant-logo.svg";
import background from "../Images/bitmaps/person-using-dwellant.jpg";
import DevicesProductMockups from "../Images/illustrations/home-opener-illustration2.png";

// Integration logos
import EstateSoftware from "../Images/integration-logos/mri_logo_new.svg";
import PropMan from "../Images/integration-logos/propman.png";
import RiverLake from "../Images/integration-logos/riverlake.png";
import SafeContractor from "../Images/integration-logos/safecontractor.jpg";
import Sage50 from "../Images/integration-logos/sage50.png";
import Salesforce from "../Images/integration-logos/salesforce.png";
import TraceSolutions from "../Images/integration-logos/tracesolutions.png";
import Xero from "../Images/integration-logos/xero.png";

// Core section
import CoreIntroImage from "../Images/illustrations/core-intro-image.svg";
import ConnectingEveryone from "../Images/illustrations/connecting-everyone.svg";
import ClearComunication from "../Images/illustrations/clear-communication.svg";
import TaskManagement from "../Images/illustrations/simplified-task-management.svg";

// Stats Stats Section
import Stat1 from "../Images/illustrations/stat1.svg";
import Stat2 from "../Images/illustrations/stat2.svg";
import Stat3 from "../Images/illustrations/stat3.svg";

// Dwellant's products sections
import BespokeLogo from "../Images/Product Logos/bespoke-logo.png";
import BesokpokeIllustration from "../Images/illustrations/bespoke-home-illustration.png";

import ConciergeLogo from "../Images/Product Logos/conciergehv-logo.png";
import ConciergeIllustration from "../Images/illustrations/concierge-home-illustration.svg";

import CSiteLogo from "../Images/Product Logos/csite-logo.svg";
import CSiteIllustration from "../Images/illustrations/csite-home-illustration.png";

import PRStoLogo from "../Images/Product Logos/prsto-logo.png";
import PRStoIllustration from "../Images/illustrations/prsto-home-section.png";

const HomeWrapper = styled.div`
  h1 {
    margin-bottom: ${gvars.spacing.ssSpace};
  }

  .integrations-section {
    margin-bottom: ${gvars.spacing.xlSpace};
    padding-bottom: ${gvars.spacing.xlSpace};
    border-bottom: 1px solid ${gvars.colours.offwhite};
    text-align: center;
    p {
      margin-bottom: ${gvars.spacing.lSpace};
    }
    .inner-content {
    }
    img {
      margin-left: auto;
      margin-right: auto;
      display: block;
      max-height: 65px;
      max-width: 200px;
      object-fit: contain;

      margin-bottom: ${gvars.spacing.mSpace};

      &:last-of-type {
        margin-bottom: 0;
      }
    }
    @media ${gvars.device.tablet} {
      .inner-content {
        display: flex;
        flex-wrap: wrap;
      }
    }

    @media ${gvars.device.laptop} {
      .inner-content {
        justify-content: center;
      }

      img {
        margin-left: 2rem;
        margin-right: 2rem;
      }
    }
  }

  .core-section {
    margin-bottom: ${gvars.spacing.xlSpace};
    padding-bottom: ${gvars.spacing.xlSpace};
    border-bottom: 1px solid ${gvars.colours.offwhite};
    .text-col {
      span {
        margin-right: 0;
      }
    }
    h1.products-title {
      margin-bottom: ${gvars.spacing.xlSpace};
      padding-bottom: ${gvars.spacing.xlSpace};
      border-bottom: 1px solid ${gvars.colours.offwhite};
    }
    .intro-block {
      margin-bottom: ${gvars.spacing.xlSpace};

      .dwellant-logo {
        max-width: 267px;
        margin-bottom: ${gvars.spacing.sSpace};
      }
      h2 {
        margin-bottom: ${gvars.spacing.ssSpace};
      }
      p {
        margin-bottom: ${gvars.spacing.mSpace};
      }
    }
    text-align: center;

    @media ${gvars.device.laptop} {
      h1 {
        margin-bottom: ${gvars.spacing.xlSpace};
      }
      p {
        margin-bottom: ${gvars.spacing.lSpace};
      }
    }
  }

  .stats-section {
    text-align: center;

    border-bottom: 1px solid ${gvars.colours.offwhite};
    padding-bottom: 2.6rem;
    margin-bottom: 2.4rem;
    h1 {
      margin-bottom: ${gvars.spacing.lSpace};
    }
    img {
      max-height: 326px;
      margin-bottom: ${gvars.spacing.lSpace};
      display: block;
      margin-left: auto;
      margin-right: auto;
      &:last-of-type {
        margin-bottom: 0;
      }
      @media ${gvars.device.laptop} {
        height: 22vw;
        max-height: 340px;
      }
    }

    @media ${gvars.device.laptop} {
      .inner-content {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .company-logo-bar {
    text-align: center;
    margin-bottom: 4.9rem;
    h3 {
      color: #a2a2a2;
      margin-bottom: ${gvars.spacing.ssSpace};
    }
    .inner-content {
      background: #f1f1f1;
      padding-top: 2rem;
      padding-bottom: 2rem;
      img {
        max-height: 52px;
        width: auto;
        margin-left: auto;
        margin-right: auto;
      }
      button {
        display: none !important;
      }
    }
  }
`;

const Home = () => {
  useEffect(() => {
    objectFitImages();
  });

  return (
    <>
      <Header />
      <Fade>
        <HomeWrapper>
          <OpeningSection page={"home"} bgImage={background}>
            <img className="dwellant-logo" src={logo} alt="Dwellant Logo" />
            <div className="orange-border"></div>
            <h2>
              Dwellant: Your complete in-the-cloud Property
              <br className="line-break-desktop"></br> Management Platform
            </h2>
          </OpeningSection>

          <MockupIntroSection offset="true">
            <img
              id="device-mockup-illustrations"
              src={DevicesProductMockups}
              alt="3 devices showing Dwellant's different product dashboards"
            />
            <h2>
              <span className="orange">Dwellant’s</span> cloud-based platform
              will revolutionise how you deliver high volume property management
              services to your customers.
            </h2>
            <Button href="#contact-us-section">Contact Us Now</Button>
          </MockupIntroSection>

          <div className="outer-grid integrations-section">
            <Fade>
              <h1>Seamlessly integrate with your existing software</h1>
              <p>Whatever your accounting package, we can integrate with it.</p>
            </Fade>

            <Fade bottom distance="40px">
              <div className="inner-content">
                <img src={EstateSoftware} alt="Estate software logo" />
                <img src={RiverLake} alt="RiverLake logo" />
                <img src={Sage50} alt="Sage50 logo" />

                <img src={SafeContractor} alt="SafeContractor logo" />
                <img src={Salesforce} alt="Salesforce logo" />

                <img src={TraceSolutions} alt="TraceSolutions logo" />
                <img src={PropMan} alt="PropMan logo" />
                <img src={Xero} alt="Xero logo" />
              </div>
            </Fade>
          </div>

          <div className="core-section">
            <Fade>
              <h1 className="products-title outer-grid">
                Property Solutions to Suit{" "}
                <span className="orange">Your Company's Needs.</span>
              </h1>
              <div className="intro-block outer-grid desktop-inner-grid">
                <img className="dwellant-logo" src={logo} alt="Dwellant Logo" />
                <h2>Manage property efficiently with Dwellant</h2>
                <p>
                  Dwellant is a state-of-the-art tool specially crafted to ease
                  communication between managing agents and residents, simplify
                  task management and solve problems that occur when managing a
                  building.
                </p>
              </div>
            </Fade>
            <ProductFeatureRow left home>
              <img
                className="image-col"
                src={ConnectingEveryone}
                alt="Dwellant logo surrounded by various members of the residential housing industry"
              />

              <div className="text-col">
                <h2>
                  Connecting <span className="orange">Everyone</span>
                </h2>
                <p>
                  The Dwellant experience has been designed to bring everyone
                  together. Residents can explore an interactive community,
                  raise maintenance requests, access resident guides, personal
                  documents and finances, using fully branded building portals.
                  <br />
                  <br />
                  Property Managers and On-Site Teams can seamlessly share tasks
                  and updates, raise and manage work orders and interact with
                  contractors, all while providing transparent, relevant and
                  instant communications and updates to everyone involved.
                </p>
              </div>
            </ProductFeatureRow>

            <ProductFeatureRow right home>
              <img
                className="image-col"
                src={CoreIntroImage}
                alt="UI of Dwellant's 'add invoice' and 'invocie list' screens"
              />

              <div className="text-col">
                <h2>
                  Intelligent <span className="orange">Invoice Processing</span>
                </h2>
                <p>
                  Take the hassle out of invoicing using our intelligent invoice
                  processing tools. Using AI technology, supplier invoices can
                  be auto-approved and fed directly into your accounting package
                  for payment.
                  <br />
                  <br />
                  When suppliers upload their invoice directly into the property
                  managers workflow, the Dwellant app will automatically pair
                  invoices to original work orders while checking set
                  tolerances, watching expected amounts and auto-flagging
                  exceptions to the Property Manager. No data entry. No paper.
                  No lost invoices!
                </p>
              </div>
            </ProductFeatureRow>

            <ProductFeatureRow left home>
              <img
                className="image-col"
                src={TaskManagement}
                alt="ui of simplified task management"
              />

              <div className="text-col">
                <h2>
                  Simple and Effective{" "}
                  <span className="orange">Task Management</span>
                </h2>
                <p>
                  Our management dashboard allows you to manage resident
                  requests, instantly raise work orders and quotations, manage
                  contractors, and communicate with residents. Use Dwellant to
                  keep an eye on planned maintenance, schedule major works and
                  automatically check Section 20 expenditure limits. Fully
                  cloud-based and mobile-ready, access Dwellant anywhere, at any
                  time, and supercharge your task management.
                  <br />
                  <br />
                  With our Health & Safety dashboard, uploading actions from
                  fire risk assessments or on-site surveys is simple and
                  straightforward, our easy reporting tools put the information
                  you need directly into your hands.
                </p>
              </div>
            </ProductFeatureRow>

            <ProductFeatureRow right home>
              <img
                className="image-col"
                src={ClearComunication}
                alt="iphone showing SMS message from residential housing"
              />

              <div className="text-col">
                <h2>
                  Proactive <span className="orange">Communication</span>Tools
                </h2>
                <p>
                  Dwellant puts powerful tools into a highly intuitive system
                  and ensures everyone is communicating using the same platform.
                  From building news to custom forms for everything from
                  requesting a new key fob, to insurance claims, and license to
                  alter requests, Dwellant makes things easy, secure and
                  transparent.
                  <br />
                  <br />
                  Secure and compliant communications mean residents only ever
                  see the information that is relevant to them, and in
                  emergencies issuing a building-wide broadcast is simple,
                  effective and fast.
                </p>
              </div>
            </ProductFeatureRow>
          </div>

          <div className="stats-section outer-grid">
            <Fade>
              <h1>We have served over</h1>
            </Fade>
            <Fade bottom distance="50px" cascade>
              <div className="inner-content">
                <img src={Stat1} alt="35 residential buildings" />
                <img src={Stat2} alt="15000 residents" />
                <img src={Stat3} alt="450 contractors" />
              </div>
            </Fade>
          </div>

          {/* <div className="company-logo-bar">
						<h3>Our happy customers include:</h3>
						<div className="inner-content">
							<LogoCarousel>
								<div>
									<img src={Knightfrank} alt="Knight Frnak logo" />
								</div>
								<div>
									<img src={Knightfrank} alt="Knight Frnak logo" />
								</div>
								<div>
									<img src={Knightfrank} alt="Knight Frnak logo" />
								</div>
								<div>
									<img src={Knightfrank} alt="Knight Frnak logo" />
								</div>

								<div>
									<img src={Knightfrank} alt="Knight Frnak logo" />
								</div>
								<div>
									<img src={Knightfrank} alt="Knight Frnak logo" />
								</div>
								<div>
									<img src={Knightfrank} alt="Knight Frnak logo" />
								</div>
								<div>
									<img src={Knightfrank} alt="Knight Frnak logo" />
								</div>
							</LogoCarousel>
						</div>
					</div> */}
          <div>
            <MainProductSection>
              <img
                className="product-logo"
                src={BespokeLogo}
                alt="Bespoke logo"
              />
              <h2>
                Fully branded portals for{" "}
                <span className="orange">your residents</span>
              </h2>
              <p>
                Help your residents unlock the full value and identity of their
                home by giving them anytime access to their building and useful
                local knowledge.
              </p>

              <Button href="/Bespoke">Learn More</Button>
              <img
                src={BesokpokeIllustration}
                alt="Illustrations of buildings + UI"
              />
            </MainProductSection>

            <MainProductSection>
              <img
                className="product-logo"
                src={ConciergeLogo}
                alt="Concierge logo"
              />
              <h2>
                Handle deliveries <span className="orange">with ease</span>
              </h2>
              <p>
                Concierge HV is a fast package and key management system, built
                with security and speed in mind when dealing with high volumes
                of packages and keys.
              </p>

              <Button href="/Concierge-HV">Learn More</Button>
              <img
                src={ConciergeIllustration}
                alt="Illustrations of boxes and ConciergeHV UI screens"
              />
            </MainProductSection>

            <MainProductSection>
              <img className="product-logo" src={CSiteLogo} alt="CSite logo" />
              <h2>
                Site inspections via your phone or tablet to{" "}
                <span className="orange">save time</span>
              </h2>
              <p>
                Bin the paper-to-PC survey lag and start using CSite: our
                offline site survey tool that facilitates instant reporting and
                immediate digital audit trails.
              </p>

              <Button href="/cSite">Learn More</Button>
              <img
                src={CSiteIllustration}
                alt="Tablet + mobile device mockup with CSite screens"
              />
            </MainProductSection>

            <MainProductSection>
              <img className="product-logo" src={PRStoLogo} alt="PRSto logo" />
              <h2>
                Manage and guide your tenants{" "}
                <span className="orange">with ease</span>
              </h2>
              <p>
                Manage new and existing tenants’ journeys with ease, through a
                fully digital, mobile ready, end-to-end experience designed to
                simplify and fast track the tenancy process.
              </p>

              <Button href="/PRSto">Learn More</Button>
              <img
                src={PRStoIllustration}
                alt="device mockup with PRSto screens"
              />
            </MainProductSection>
          </div>
          <ContactUsSection>
            <>
              <div className="block">
                <h2>Contact our dedicated sales team on</h2>
                <div className="contact-info-large">020 3397 2211</div>
              </div>
              <div className="block">
                <h2>Or Email us at</h2>
                <div className="contact-info-large">
                  <a href="mailto:sales@dwellant.com" target="_blank">
                    sales@dwellant.com
                  </a>
                </div>
              </div>
            </>
          </ContactUsSection>
          <GlobalStyle />
          <Helmet>
            {/* <!-- Primary Meta Tags --> */}
            <title>
              Dwellant: Your complete in-the-cloud Property Management Platform
            </title>
            <meta
              name="title"
              content="Dwellant: Your complete in-the-cloud Property Management Platform"
            />
            <meta
              name="description"
              content="Dwellant’s cloud-based platform will revolutionise how you deliver high volume property management services to your customers."
            />

            {/* <!-- Open Graph / Facebook --> */}
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://www.dwellant.com/" />
            <meta
              property="og:title"
              content="Dwellant: Your complete in-the-cloud Property Management Platform"
            />
            <meta
              property="og:description"
              content="Dwellant’s cloud-based platform will revolutionise how you deliver high volume property management services to your customers."
            />
            <meta
              property="og:image"
              content="https://i.ibb.co/JyFcFXW/ogimage.jpg"
            />

            {/* <!-- Twitter --> */}
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content="https://www.dwellant.com/" />
            <meta
              property="twitter:title"
              content="Dwellant: Your complete in-the-cloud Property Management Platform"
            />
            <meta
              property="twitter:description"
              content="Dwellant’s cloud-based platform will revolutionise how you deliver high volume property management services to your customers."
            />
            <meta
              property="twitter:image"
              content="https://i.ibb.co/JyFcFXW/ogimage.jpg"
            />
          </Helmet>
        </HomeWrapper>
      </Fade>
    </>
  );
};

export default Home;
