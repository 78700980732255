import React from "react";
import { Header } from "../Components/Header";
import styled from "styled-components/macro";
import Fade from "react-reveal/Fade";
import * as gvars from "../styles2/GlobalVariables";
import geometric from "../Images/geometric-bg.png";
import Section from "./../Components/Section";
import OpeningSection from "../Components/New/OpeningSection";
import ProductFeatureRow from "../Components/New/ProductFeatureRow";
import BannerRow from "../Components/New/BannerRow";
import Button from "../Components/Button";
import TriColumnFeaturesBlock from "../Components/New/TriColumnFeaturesBlock";
import TriColumnFeaturesSingleColumn from "../Components/New/TriColumnFeaturesSingleColumn";
import TestimonialBlock from "../Components/New/TestimonalBlock";
import ContactUsSection from "../Components/New/ContactUsSection";
import MockupIntroSection from "../Components/New/MockupIntroSection";
import { Helmet } from "react-helmet";

import Iphone from "../Components/Iphone";
import iphoneContent from "../Images/iphone-content.jpg";

/***
 * IMAGES
 */
import background from "../Images/bitmaps/people-dining.jpg";
import DevicesProductMockups from "../Images/illustrations/bespoke-opening-illustration.png";
import logo from "../Images/Product Logos/bespoke-logo.png";
import BookingLoungeUI from "../Images/illustrations/booking-business-lounge.png";
import BookingLoungeUI2x from "../Images/illustrations/booking-business-lounge@2x.png";
import IssuesUI from "../Images/illustrations/issues.svg";
import BespokeUI from "./../Images/illustrations/bespoke.png";
import SiteDesignPhoto from "./../Images/bitmaps/site-design.jpg";
import CalendarPhoto from "./../Images/bitmaps/calendar.jpg";
import PeopleDiningPhoto2 from "./../Images/bitmaps/people-dining2.jpg";

import Residents from "./../Images/illustrations/residents.svg";
import Staff from "./../Images/illustrations/staff.svg";
import Freeholders from "./../Images/illustrations/freeholder.svg";

import Lock from "./../Images/bitmaps/lock.jpg";
import Lock2x from "./../Images/bitmaps/lock@2x.jpg";
import PeopleUsingComputer from "./../Images/bitmaps/people-using-computer.jpg";
import PeopleUsingComputer2x from "./../Images/bitmaps/people-using-computer@2x.jpg";
import PersonUsingTablet from "./../Images/bitmaps/responsive.jpg";
import PersonUsingTablet2x from "./../Images/bitmaps/responsive@2x.jpg";
import ISOCertificate from "./../Images/bitmaps/iso-certificate.png";

const BespokeWrapper = styled.div`
  .iphone {
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  .iphone__content-image {
    left: 0;
  }

  @media ${gvars.device.tablet} {
    .iphone {
      max-width: 50%;
    }
  }
  @media ${gvars.device.laptop} {
    .iphone {
      width: 17rem;

      margin-right: 6rem;
      max-width: 430px;
    }
  }
`;

const Bespoke = () => {
  return (
    <>
      <Header />
      <Fade>
        <BespokeWrapper>
          <OpeningSection bgImage={background} page={"bespoke"}>
            <div className="orange-border">
              <img src={logo} />
            </div>
            <Fade>
              <h2 className="orange-border">
                Delivering an elevated digital experience for your developments
                with an engaging mobile platform.
              </h2>
            </Fade>

            <Fade>
              <p>
                Help your residents unlock the full value and identity of their
                home by giving them anytime access to their building and
                priceless local knowledge.
              </p>
            </Fade>
          </OpeningSection>

          <MockupIntroSection>
            <img
              id="device-mockup-illustrations"
              src={DevicesProductMockups}
              alt="3 devices showing Dwellant's different product dashboards"
            />
            <h2>
              Developed to deliver residents a continuous high-end digital home
              experience.
            </h2>
            <Button href="#contact-us-section">Contact Us Now</Button>
          </MockupIntroSection>

          <ProductFeatureRow>
            <div className="text-col">
              <h2>
                <span>1.</span>Automated functionality
              </h2>
              <p className="desktop-large">
                Control activity bookings, reserve moving-in slots and access
                online forms.
              </p>
            </div>

            <img
              className="image-col"
              src={BookingLoungeUI}
              srcSet={`${BookingLoungeUI2x} 2x`}
              alt="Booking Business Lounge UI"
            />
          </ProductFeatureRow>

          <ProductFeatureRow right>
            <div className="text-col">
              <h2>
                <span>2.</span>Pre-set work flows
              </h2>
              <p className="desktop-large">
                Automated replies help your team to deliver a great resident
                experience.
              </p>
            </div>

            <img
              className="image-col"
              src={IssuesUI}
              alt="Issues requests UI"
            />
          </ProductFeatureRow>
          <ProductFeatureRow>
            <div className="text-col">
              <h2>
                <span>3.</span>Their home in their hands
              </h2>
              <p className="desktop-large">
                Allow residents to unlock their home, neighbourhood and unique
                features.
              </p>
            </div>
            <Iphone
              animating={false}
              image={iphoneContent}
              alt="How Dwellant looks for residents on their mobile devices"
            />
          </ProductFeatureRow>

          <BannerRow>
            <h2>
              A space designed to deliver an engaging, personalised digital
              experience.
            </h2>
            <Button
              icon
              href="#contact-us-section"
              options={{ verticalOffset: -40 }}
              color="orange"
            >
              Contact Us Now
            </Button>
          </BannerRow>

          <TriColumnFeaturesBlock>
            <TriColumnFeaturesSingleColumn>
              <img
                src={SiteDesignPhoto}
                className="img-row"
                alt="UI sketches"
              />

              <div className="text-container">
                <h2>Site Design</h2>
                <p className="large">
                  Designed to be engaging, modern and mobile-friendly.
                </p>

                <div className="text-row">
                  <h3>Key Functions</h3>
                  <p>
                    Fully opted-in content; residents profile, apartment spec,
                    leisure activities type of resident, spec of apartment, core
                    etc
                  </p>
                  <p>Responsive design to suit Android, iOS and PC</p>
                  <p>
                    Structure and content bespoke to each development and its
                    residents
                  </p>
                </div>
                <div className="text-row">
                  <h3>Product features</h3>
                  <p>Integrates with external services e.g. Salesforce</p>
                  <p>
                    Communication is set up according to residents’ preferences
                  </p>
                </div>
              </div>
            </TriColumnFeaturesSingleColumn>

            <TriColumnFeaturesSingleColumn>
              <img
                src={CalendarPhoto}
                className="img-row"
                alt="Photo of calendar"
              />

              <div className="text-container">
                <h2>Facilities calendar</h2>
                <p className="large">
                  Residents can make bookings from the comfort of their home.
                </p>

                <div className="text-row">
                  <h3>Key Functions</h3>
                  <p>
                    Forms and booking facilities are customised for each
                    development
                  </p>
                  <p>
                    Locked content for special features tailored to residents’
                    profile
                  </p>
                  <p>
                    Residents can raise requests directly to the Property
                    Management team
                  </p>
                </div>
                <div className="text-row">
                  <h3>Product features</h3>
                  <p>Easy to set up and manage</p>
                  <p>
                    Provides a user-friendly interface for the on-site building
                    team
                  </p>
                  <p>
                    Fully integrates with door entry system, providing residents
                    with control of their home
                  </p>
                </div>
              </div>
            </TriColumnFeaturesSingleColumn>

            <TriColumnFeaturesSingleColumn>
              <img
                src={PeopleDiningPhoto2}
                className="img-row"
                alt="Photo of calendar"
              />

              <div className="text-container">
                <h2>Location guide</h2>
                <p className="large">
                  We create local content that unlocks your residents’ homes.
                </p>

                <div className="text-row">
                  <h3>Key Functions</h3>
                  <p>
                    Provides a guide on where to eat, exercise, drink, relax and
                    get the most from their location
                  </p>
                  <p>Allows access to home-related documents</p>
                  <p>Access up-to-date local transport information</p>
                </div>
                <div className="text-row">
                  <h3>Product features</h3>
                  <p>Easy to upload unit-specific documents and manuals</p>
                  <p>
                    Provides a user-friendly interface for the on-site building
                    team
                  </p>
                  <p>
                    Landmarks and businesses are identified via linked Google
                    pages
                  </p>
                </div>
              </div>
            </TriColumnFeaturesSingleColumn>
          </TriColumnFeaturesBlock>

          <TestimonialBlock>
            <p class="quote-text">
              We now deliver a seamless switch from our high-end physical
              experience to a digital platform that allows our brand to shine.
              Our residents can access the cinema, personal data and our
              property manager directly from their smart device.
            </p>

            <p class="name">
              <strong>Danielle Foster-Brown</strong>
              <br></br>
              Development Manager
            </p>
          </TestimonialBlock>

          <BannerRow orange margin>
            <h2>
              Bespoke Buildings provides valuable benefits to both residents and
              employees.
            </h2>
          </BannerRow>

          <ProductFeatureRow customerBenefits right>
            <img
              className="residents-image image-col"
              src={Residents}
              alt="Residents illustration"
            />

            <div className="text-col">
              <h2>Residents</h2>
              <ul>
                <li>Any device, any place, anytime access</li>
                <li>Instant notifications</li>
                <li>Residents control their own data</li>

                <li>Provides a valuable local guide</li>
              </ul>
            </div>
          </ProductFeatureRow>

          <ProductFeatureRow customerBenefits left>
            <img className="image-col" src={Staff} alt="Staff illustration" />

            <div className="text-col">
              <h2>Staff/Building Management</h2>
              <ul>
                <li>Reduction in low-value tasks via enhanced self-service</li>
                <li>Effective task management</li>
                <li>Provides automated messages</li>
                <li>GDPR compliant</li>
              </ul>
            </div>
          </ProductFeatureRow>

          <ProductFeatureRow customerBenefits right>
            <img
              className="image-col"
              src={Freeholders}
              alt="Freeholers illustration shaking hands"
            />

            <div className="text-col">
              <h2>Freeholder/Developer</h2>
              <ul>
                <li>Consistent and positive brand experience</li>
                <li>Satisfied homeowners</li>
                <li>Efficient use of property management time</li>
                <li>Defect reporting direct to property manager</li>
              </ul>
            </div>
          </ProductFeatureRow>

          <BannerRow orange>
            <h2>
              Choose a fully approved system that’s easy to install and simple
              to use.
            </h2>
          </BannerRow>

          <TriColumnFeaturesBlock>
            <TriColumnFeaturesSingleColumn>
              <img
                src={Lock}
                srcSet={`${Lock2x} 2x`}
                className="img-row"
                alt="Photo of padlock"
              />

              <div className="text-container">
                <h2>Security & Data Protection</h2>

                <div className="text-row">
                  <p>
                    A system you can trust, Bespoke Buildings is part of our ISO
                    27001 certified network.
                  </p>
                  <p>
                    Along with being GDPR compliant, it meets the highest
                    requirements of European security, giving you full peace of
                    mind for secure audit trails.
                  </p>
                  <img
                    className="ISOCertificate"
                    src={ISOCertificate}
                    alt="ISO certficate"
                  />
                </div>
              </div>
            </TriColumnFeaturesSingleColumn>

            <TriColumnFeaturesSingleColumn>
              <img
                src={PeopleUsingComputer}
                srcSet={`${PeopleUsingComputer2x} 2x`}
                className="img-row"
                alt="People using a computer"
              />

              <div className="text-container">
                <h2>Implementation & Training</h2>

                <div className="text-row">
                  <p>
                    It’s quick and easy to get your team up and running. Members
                    of our Dwellant team will deliver training on-site early and
                    late to cover shift changes, so no-one is left out. A
                    special video provides training for new staff as they join.
                  </p>
                </div>
              </div>
            </TriColumnFeaturesSingleColumn>

            <TriColumnFeaturesSingleColumn>
              <img
                src={PersonUsingTablet}
                srcSet={`${PersonUsingTablet2x} 2x`}
                className="img-row"
                alt="Person using a tablet"
              />

              <div className="text-container">
                <h2>Choose iOS or Android</h2>

                <div className="text-row">
                  <p>
                    There’s no need to invest in new software as Dwellant will
                    run with your existing Windows PC or wireless Android tablet
                    and/or phone.
                  </p>
                  <p>
                    It’s reassuring to know your residents can use the bespoke
                    page on both iOS and Android tablets.
                  </p>
                </div>
              </div>
            </TriColumnFeaturesSingleColumn>
          </TriColumnFeaturesBlock>

          <ContactUsSection></ContactUsSection>
          <Helmet>
            {/* <!-- Primary Meta Tags --> */}
            <title>Bespoke Buildings || Dwellant</title>
            <meta name="title" content="Bespoke Buildings || Dwellant" />
            <meta
              name="description"
              content="Delivering an elevated digital experience for your developments with an engaging mobile platform. Help your residents unlock the full value and identity of their home by giving them anytime access to their building and priceless local knowledge."
            />

            {/* <!-- Open Graph / Facebook --> */}
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://www.dwellant.com/" />
            <meta property="og:title" content="Bespoke Buildings || Dwellant" />
            <meta
              property="og:description"
              content="Delivering an elevated digital experience for your developments with an engaging mobile platform. Help your residents unlock the full value and identity of their home by giving them anytime access to their building and priceless local knowledge."
            />
            <meta
              property="og:image"
              content="https://i.ibb.co/JyFcFXW/ogimage.jpg"
            />

            {/* <!-- Twitter --> */}
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content="https://www.dwellant.com/" />
            <meta
              property="twitter:title"
              content="Bespoke Buildings || Dwellant"
            />
            <meta
              property="twitter:description"
              content="Delivering an elevated digital experience for your developments with an engaging mobile platform. Help your residents unlock the full value and identity of their home by giving them anytime access to their building and priceless local knowledge."
            />
            <meta
              property="twitter:image"
              content="https://i.ibb.co/JyFcFXW/ogimage.jpg"
            />
          </Helmet>
        </BespokeWrapper>
      </Fade>
    </>
  );
};

export default Bespoke;
