import React from "react";
import styled from "styled-components/macro";
import * as gvars from "../../styles2/GlobalVariables";
import GlobalStyle from "../../styles2/GlobalStyles";

const OpeningSectionStyled = styled.div`
  /*************
 * CUSTOM EXTRA SMALL MOBILE STYLES
*************/
  @media (max-width: 360px) {
    p {
      ${(props) => props.page == "csite" && "display:none"};
      ${(props) => props.page == "bespoke" && "display:none"};
    }
  }

  /*************
	 * REGULAR BASE STYLES
	*************/
  height: 100vh;
  background-image: url(${(props) => props.bgImage});
  background-size: cover;
  background-position: center;
  background-position: ${(props) => props.page == "home" && "left"};
  display: flex;
  -ms-flex-flow: column;
  justify-content: center;
  align-items: center;
  color: white;
  position: relative;
  padding-top: 2rem;
  margin-bottom: ${gvars.spacing.xlSpace};

  .inner-content {
    position: relative;
    text-align: center;
    z-index: 3;
  }

  .orange-border {
    border-bottom: 1px solid ${gvars.colours.orange};

    padding-bottom: 1.7rem;
    margin-bottom: 1.7rem;
  }
  h1 {
    color: white;
    font-size: 3.5rem;

    line-height: 3.4rem;
  }

  .orange-text {
    color: ${gvars.colours.orange};
  }
  button {
    margin-top: 1rem;
  }

  img {
    width: 13rem;
    ${(props) => props.page == "csite" && "width: 10rem"};
    ${(props) => props.orangeBorder && "border-bottom:1px solid orage"};
  }
  img.dwellant-logo {
    width: 17rem;
  }

  .button-section {
    margin-top: 2rem;
  }

  br.line-break-desktop {
    display: none;
  }

  /*************
 * TABLET QUERIES
*************/
  @media ${gvars.device.tablet} {
    h1 {
      line-height: 4rem;
      font-size: 4.5rem;
    }
    .button-section {
      button {
        margin-right: ${gvars.spacing.sSpace};
      }
    }
  }

  /*************
 * DESKTOP QUERIES
*************/
  @media ${gvars.device.laptop} {
    background-attachment: fixed;
    p {
      font-size: 1.25rem;
      line-height: 1.7rem;
    }

    .inner-content {
      ${(props) => props.page == "home" && "margin-top: -10rem;"}
    }

    img {
      width: 15rem;
    }
    img.dwellant-logo {
      width: 23rem;
    }

    br.line-break-desktop {
      display: block;
    }
  }

  /*************
 * 1080p QUERIES
*************/

  @media ${gvars.device.desktopL} {
    img.dwellant-logo {
      width: 21rem;
    }

    .inner-content {
      ${(props) => props.page == "home" && "margin-top: -8rem;"}
    }
    .orange-border {
      padding-bottom: 1.4rem;
      margin-bottom: 1.4rem;
    }
    p {
      // line-height: 2.1rem;
      // font-size: 1.4rem;
    }
    img {
      // width: 22rem;
    }
  }
`;

const BlackOverlay = styled.div`
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  position: absolute;
  background-color: black;
  background-color: #000306;
  opacity: 0.8;
  // ${(props) => props.page == "conciergehv" && "opacity: 0.85;"}
  @media ${gvars.device.laptop} {
    ${(props) => props.page == "home" && "opacity: 0.82;"}
  }
`;

export default class OpeningSection extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {}
  render() {
    return (
      <OpeningSectionStyled page={this.props.page} bgImage={this.props.bgImage}>
        <BlackOverlay page={this.props.page} />
        <div className="inner-content outer-grid desktop-inner-grid">
          {
            this.props
              .children /* this injects the content from wherever its used as a HOC, see bespoke.js as an example or remove this to see */
          }
        </div>
      </OpeningSectionStyled>
    );
  }
}

// export default OpeningSection;
