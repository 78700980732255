import React from "react";
import Fade from "react-reveal/Fade";
import styled from "styled-components/macro";
import * as gvars from "../../styles2/GlobalVariables";
import GlobalStyle from "../../styles2/GlobalStyles";

/*
This is used in the homepage (index.js) to showcase each of Dwellant's products in one 'section'
*/

const MainProductSectionStyled = styled.div`
  margin-bottom: ${gvars.spacing.xlSpace};
  padding-bottom: ${gvars.spacing.xlSpace};
  border-bottom: 1px solid ${gvars.colours.offwhite};
  text-align: center;
  .product-logo {
    max-width: 203px;
    margin-bottom: ${gvars.spacing.mSpace};
  }
  h2 {
    margin-bottom: ${gvars.spacing.ssSpace};
  }
  p {
    margin-bottom: ${gvars.spacing.mSpace};
  }
  a {
    margin-bottom: ${gvars.spacing.xlSpace};
  }
  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  &:last-of-type {
    border-bottom: none;
  }

  @media ${gvars.device.desktopL} {
    .product-logo {
      max-width: 13rem;
    }
  }
`;

export default class MainProductSection extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {}
  render() {
    return (
      <MainProductSectionStyled>
        <Fade bottom distance="50px">
          <div className="outer-grid desktop-inner-grid">
            {
              this.props
                .children /* this injects the content from wherever its used as a HOC, see bespoke.js as an example or remove this to see */
            }
          </div>
        </Fade>
      </MainProductSectionStyled>
    );
  }
}

// export default OpeningSection;
