import React from 'react';
import styled from 'styled-components/macro';
import Icon from '../Icon';
import Fade from 'react-reveal/Fade';

const ContactsStyled = styled.div`
	li {
		margin-bottom: 0.6rem;
	}
`;

const Contacts = props => {
	return (
		<ContactsStyled>
			<Fade bottom distance="50px" delay={props.delay} cascade>
				<ul className="contacts no-list-styles">
					<li>
						<a className="contact" href="tel:+442033972243">
							<span className="contact__label">
								<Icon name="phone" size="1rem" margin="right" /> Support:
							</span>{' '}
							<span>020 3397 2243</span>
						</a>
					</li>
					<li>
						<a className="contact" href="mailto:customerservice@dwellant.com">
							<span className="contact__label">
								<Icon name="envelope" size="1rem" margin="right" /> Support:
							</span>{' '}
							<span>customerservice@dwellant.com</span>
						</a>
					</li>

					<li>
						<a className="contact" href="tel:+442033972211">
							<span className="contact__label">
								<Icon name="phone" size="1rem" margin="right" /> Sales:
							</span>{' '}
							<span>020 3397 2211</span>
						</a>
					</li>
				</ul>
			</Fade>
		</ContactsStyled>
	);
};

export default Contacts;
