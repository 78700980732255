import React from 'react';
import geometric from '../Images/geometric-bg.png';
import Fade from 'react-reveal/Fade';

import { Header } from "../Components/Header";
import Heading from "../Components/SubComponents/Heading";
import Highlight from "../Components/SubComponents/Highlight";
import Subheading from "../Components/SubComponents/Subheading";
import Row from "../Components/Row";
import Col from "../Components/Col";
import Section from "../Components/Section";
import Button from "../Components/Button";

import { Helmet } from 'react-helmet';

import DevicesProductMockups from "../Images/illustrations/home-opener-illustration2.png";
import ConnectingEveryone from "../Images/illustrations/connecting-everyone.svg";
import OpeningSection from "../Components/New/OpeningSection";

const SoftwareForPropertyMaintenanceCompanies = () => {
  return (
    <>
      <Header />
      <OpeningSection bgImage={geometric}>
        <h1 className="orange-border">Software for <span className="orange">Property Maintenance Companies</span></h1>
            <p>
              Property maintenance is a demanding task. Those managing
              property maintenance require specialist knowledge and expertise.
              The nature of the job is that many matters are dealt with all at
              once. The manager relies on others to complete their work,
              safely and on time and in the set price range. With a property
              maintenance software the complex job of property management can
              be achieved efficiently and competently. Dwellant’s bespoke
              software for property maintenance companies provides peace of
              mind and security that your building and its maintenance is in
              the best of hands.
            </p>
      </OpeningSection>
      <Section>
        <Row>
          <Heading>Property Management Computer Programs</Heading>
          <p>
            Property Maintenance Companies experience ever increasing demand
            on their capacities and expertise in a fast paced and ever
            changing landscape. Property maintenance is a multi-facetted field
            which requires expert knowledge and the handling of many different
            tasks simultaneously. To keep track of the maintenance management
            an adequate software is needed that supports the maintenance
            companies in their work.
          </p>

          <p>
            Dwellant know how challenging property maintenance can be and have
            created an online software for all property managers to use. It is
            simple and effective and created to make the life of the property
            maintenance company this much easier.
          </p>

          <p>
            With Dwellant’s software for property maintenance companies it
            becomes easy to manage your contractor data-base, place works
            orders, follow all works instructed and pay invoices swiftly.
          </p>

          <img
            src={DevicesProductMockups}
            alt="Dwellant’s software for property maintenance companies is a holistic, cloud-based system for the 21st century."
          />

          <div>
            <h3>Supply chain management </h3>
          </div>

          <p>
            Contractor management is only one of the tools provided to the
            manager or agent when using Dwellant’s software for property
            maintenance companies. The process starts with the selection of
            property maintenance companies which have been previously vetted,
            that are nearby and who have knowledge of the property or estate
            and the task at hand. A simple drop down menu allows the user to
            choose from the maintenance companies that are known to the
            business and building and whom you trust to do the work.
          </p>

          <p>
            Quotes can be sought from different suppliers. Dwellant’s software
            for property maintenance companies makes it easy to request a
            quote from multiple providers and receive proposals back with
            relevant cost and details related to the work. The property
            manager can then easily choose the quote they wish to accept and
            the software then automatically sends a notification to the
            contractors that have not been chosen. A works instruction is
            quickly created for the contractors whose quote you wish to
            accept.
          </p>

          <p>
            The property maintenance company, the building staff and residents
            and contractor can follow the work-flow live and online. The
            contractor can update on work progress, and finally, once the job
            is complete, submit his invoice very swiftly. Dwellant’s software
            for property maintenance companies promotes positive work
            relations and offers solutions for swift, intelligence work
            processes.
          </p>

          <p>
            With submission of the invoice the maintenance task is completed.
            The invoice can be viewed online and checked against the
            maintenance instruction. Once the invoice is accepted, the payment
            process simply completed on the software dashboards. There are
            multi level sign off stages available and the contractor knows at
            all times where in the process the payment of his invoice is at.
          </p>

          <img
            src={ConnectingEveryone}
            alt="Dwellant’s property management computer programs are a holistic, cloud-based system for the 21st century."
          />

          <p>
            The property management team can also use Dwellant’s software for
            property maintenance companies to create annual contract and
            contract instructions for routine maintenance contracts.
          </p>

          <p>
            On instruction of both works orders and contracts there is the
            ability to manage the required health and safety of the
            maintenance work. As needed risk assessments and method statements
            can be requested, and the review process of the H&S documentation
            managed.
          </p>

          <p>
            Payment of invoices process shows the invoice, has a link to the
            instruction for cross referencing. It provides the pre populated
            fields linking the invoice to the relevant budget. The relevant
            tax can be added, even split tax amounts calculated.
          </p>

          <p>
            User dashboards allow for simple overview of invoices and
            maintenance tasks outstanding. Reports can be run for the
            outstanding tasks and utilised in management reports, team and
            client meetings.
          </p>

          <p>
            Dwellant’s software for property maintenance companies makes
            communication with property owners and tenants a priority. All
            residents can with registration receive maintenance news and
            updates regularly. Secured registration gives leaseholders the
            ability to view service charges together with all documentation
            related to the annual maintenance budgets set by the management
            company. Those who wish can pay the fees online.
          </p>

          <p>
            The portal allows for documents holding and publication for the
            groups who require to see them or download copies. Access to
            upload documents and files and pictures is given to both agents
            and Managing Companies.
          </p>

          <p>
            Where a Board of Directors is elected to run the Residential
            Management Company a special folder is dedicated to the Board’s
            activities. Access to the management files is also provided at any
            time. Dwellant’s software for property maintenance companies
            allows to create a ‘Director's corner’. This area within the
            platforms allows to save, store, update files relevant to the
            building and the residential board’s responsibilities.
          </p>

          <p>
            Individuals living in the property or property owners can report
            any maintenance requests for the property via the portal. Any
            inquiry is logged and the manager is alerted to the inquiry
            through the portal dashboard and via email alert. Once a request
            is logged it can be picked up by the manager and converted into a
            work order; any communication that follows form the original
            request is recorded to progress is transparent and instantly
            accessible.
          </p>

          <p>
            It is recognised that different groups require different levels of
            access to the platform and its files. For example will the
            leaseholder be able to view all financial information, relating to
            the property as a whole or one’s individual apartment(s) only.
            Tenants who rent will be able to access vital information needed
            in relation to living at the property, access arrangements,
            community and communal living. Residential Board Directors,
            developers, property managers as well as their administrators have
            further, higher access level to the platform. Dwellant’s software
            for property maintenance companies permits varying degrees of
            access and sign off levels, to suit the individual business and
            property.
          </p>

          <p>
            Dwellant’s software for property maintenance companies is
            versatile and easy to use. The property manager and those who
            maintain the property can make use of the software’s features to
            organise and automate tasks. The system sends out reminders to
            external entities suppliers, consultants, advisors or others
            engaged in the upkeep of the property. This is to remind of a work
            instruction, a maintenance date or required and related paperwork.
            Where a planned maintenance programme is established for the
            property repeat event dates are set and reminders are automated to
            ensure all parties are kept up to date and act as agreed.
          </p>

          <p>
            Dwellant’s software for property maintenance companies has
            easy-to-use dashboards that aid the task management and flow. Task
            counts show the tasks which have come in new. A log is kept of
            those tasks that are outstanding or become due to complete.
          </p>

          <div>
            <h3>Health & safety built in</h3>
          </div>
          <p>
            Health & Safety plays an ever increasing role in the maintenance
            of a building. Due diligence and good management principles govern
            the day to day management of health and safety. Dwellant’s
            software for property maintenance companies provides the manager
            with tools to provide sound health and safety management. Frequent
            reminders can be set for regular checks and maintenance. Risk
            assessments and method statements can be integrated in the work
            order process. Health and safety reports can be downloaded from
            the system, sent via email or provided as part of a larger system
            report.
          </p>
          <p>
            Without in-built health and safety and compliance capabilities may
            lead to unsafe working practices with potentially catastrophic
            results. Dwellants’ software for property maintenance companies
            has an integrated option to link risk assessments and method
            statements to the work order process. This way on-site staff,
            office staff and contractors can all access and review documents
            as needed, and relevant permit to work documents can be issued
            quickly and accurately.
          </p>
          <p>
            Our platform allows maintenance contractors to view any identified
            health and safety hazards on site, including the presence of
            asbestos and any unprotected areas. The health and safety
            information is held on the building maintenance software program
            for all relevant parties to see and share.
          </p>
          <p>
            The ‘frequent event’ function for routine surveys and patrol
            checks provides the team on-site with structure to their working
            day. Whether these are for common parts site inspections, security
            patrols, or health and safety checks, site managers can download a
            monthly report for onward transmission to head office management
            and ultimately to the client as needed. The results of such
            inspections and surveys provide useful data for regular tool-box
            talks and training. Any reports are immediately accessible and
            downloadable from Dwellant’s bespoke software for property
            maintenance companies. Thus it is ideal to be used for team or
            client or contractor meetings.
          </p>
          <p>
            A health and safety dashboard allows a building manager to see
            outstanding health and safety tasks and actions, whether they
            relate to fire safety, water hygiene, asbestos, utility safety or
            working at height. Actions resulting from risk assessments,
            surveys and inspection reports are extracted by the portal and the
            necessary parties can view them, delegate them, mark them as ‘in
            progress’ and close them off once completed. The use of one system
            for on-site as well as off-site management means delegation to
            those at the coal face helps to spread the workload and accelerate
            its completion. As necessary, risk assessors themselves, the
            Health & Safety Executive (HSE), the fire service and others can
            be given access to this Dwellant dashboard to view the courses of
            action prior to a health and safety incident or an accident.
            Near-misses as well as accidents can be recorded on Dwellant, to
            aid safer working practices in the future.
          </p>
          <p>
            Automated, repeat messages from Dwellant’s software for property
            maintenance companies are sent to team members at set frequencies.
            This facilitates the collation of such data for internal
            statistical analysis, safety reporting and prompting of actions
            outstanding.
          </p>

          <p>
            Dwellant’s software for property maintenance companies facilitates
            the connection between onsite staff and the management office and
            manager. Where onsite staff are employed the system acts as a
            bridge between the agent’s back office and the front desk serving
            the residents and leaseholders of a property. Staff use the
            website’s functionality to ensure excellent communication with
            residents, report on maintenance matters, record routine checks
            and health and safety inspections. Data is securely kept on the
            website as the system replaces previous, unsecure and unreliable
            paper based systems. On site staff have access to the wealth of
            documents and information stored on the website. It facilitates
            the team’s daily tasks and assist in the smooth running of the
            concierge or front desk operation.
          </p>

          <div>
            <h3>On-site Tools</h3>
          </div>
          <p>
            Dwellant’s team has created various tools to make the work of the
            onsite team easier to manage. It’s software for property
            maintenance companies is well thought out and has the manager’s
            daily tasks in mind.
          </p>
          <p>
            Dwellant’s plug-in CSite allows property managers and on-site
            staff to conduct site surveys on a tablet. Actions populate
            Dwellant upon synchronisation. Concierge HV and MV seamlessly
            manage your on-site key, package and visitor obligations. All
            Dwellant plug-ins fully integrate with our central cloud-based
            software.
          </p>

          <div>
            <h3>Csite</h3>
          </div>
          <p>
            Csite is the ultimate building inspection tool embedded in
            Dwellant’s software for property maintenance companies. It has
            been developed especially for property and estate managers who
            wish to carry out swift and accurate site inspections.
          </p>
          <p>
            Once you have set up a template for each building, there are three
            simple steps to complete.
          </p>
          <ol>
            <li>
              Survey: when you carry out a site visit, you can now complete
              your survey via smartphone or tablet, adding photos directly to
              your notes.
            </li>
            <li>
              Synchronise: when online, synchronise with Dwellant and actions
              appear, ready for delegation.
            </li>
            <li>
              Report: a site visit report is created and available in Word,
              PDF and CSV formats. The documents and photos are always
              available for audit checks.
            </li>
          </ol>
          <p>
            The Csite site inspector embedded in Dwellant’s software for
            property maintenance companies operates anywhere with offline use.
            The app is downloaded on a phone or tablet and is ready to use.
            Using the app saves valuable time for the site surveyor. The
            survey and report are geo-location tracked and logged immediately,
            saving 90% office admin time. All data be stored where you can
            make it work for you and your clients.
          </p>
          <p>
            The management tasks following on from your site visit are simple.
            The Csite app turns tasks into work orders there and then. The app
            and its functions are easy to learn and use. New staff are swiftly
            trained to use the tool. Csite ensures consistency of data across
            all teams and buildings. It provides safe and secure storage of
            surveys and reliable end-to-end reporting.
          </p>
          <p>
            Csite works on any Android and IOS device; it removes all need for
            site inspection write ups. The inspector can work offline and
            synchronise the report and all photos and data once back in
            reception range or back in the office. Questions linked to assets
            and historic asset data can be saved and followed up easily, and
            any work orders that result from the queries can be issued
            immediately. The app allows the creation of template content and
            specific questions to ensure continuity across your portfolios.
          </p>
          <p>
            A simple link to the Dwellant’s software for property maintenance
            companies allows integration and seamless, automatic reporting of
            all on-site issues. Defects management has never been so easy. Geo
            and time stamp validate the report and make it usable for explicit
            uses including witness statements. Once the site survey is
            finalised, instructions are ready to be assigned to the
            contractor. The synchronised content from the site inspections is
            kept ready on Dwellant to be expanded on or shared with others.
          </p>

          <div>
            <h3>Security is our highest priority</h3>
          </div>

          <p>
            Dwellant’s software for property maintenance companies is based in
            the Cloud so it can be accessessed any time, anywhere.
          </p>

          <p>
            Software security is a top priority at Dwellant. Our ISO 20071
            certification gives our clients and software users the assurance
            that all data and user functionality is protected by the highest
            security features. It is our qualified staff who develop all
            features, they maintain it and improve the software over time.
          </p>
          <p>
            Data protection is addressed via a signed agreement between
            Dwellant and the client . This states that the client has the
            legal right to disclose all personal data that it does in fact
            disclose to Dwellant under or in connection with this agreement
            and further that the client shall fully comply with the Data
            Protection Act 1998 and GDPR Act 2016. Dwellant warrants that it
            will act only on instructions of the client in relation to the
            processing of any Personal Data performed by Dwellant’s software
            for property maintenance companies on behalf of the client. That
            it has in place reasonably appropriate security measures (both
            technical and organisational) against unlawful or unauthorised
            processing of Personal Data. The web service is protected using
            security systems and technologies which include a Hardware
            Firewall configured with the minimum standard rule set and a
            software firewall configured to protect systems and restrict
            traffic to the minimum protocols. Further is administrative access
            given through a secure site and appropriate rules for password
            security enabled. Regular security monitoring and configuration
            checks are carried out.
          </p>

          <p>
            Property maintenance is a demanding task. Those managing property
            maintenance require specialist knowledge and expertise. The nature
            of the job is that many matters are dealt with all at once. The
            manager relies on others to complete their work, safely and on
            time and in the set price range. With a property maintenance
            software the complex job of property management can be achieved
            efficiently and competently. Dwellant’s bespoke software for
            property maintenance companies provides peace of mind and security
            that your building and its maintenance is in the best of hands.
          </p>
        </Row>
      </Section>
      <Helmet>
        <title>Software For Property Maintenance Companies || Dwellant</title>
        <meta
          name="description"
          content="Dwellant’s bespoke software for property maintenance companies provides peace of mind and security that your building and its maintenance is in the best of hands."
        />
      </Helmet>
    </>
  );
};

export default SoftwareForPropertyMaintenanceCompanies;
