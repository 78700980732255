import React from "react";
import Fade from "react-reveal/Fade";

const Col = props => {
    let classNames = props.className ? [props.className] : [];
    let styles = props.style ? props.style : {};

    if (props.width) {
        classNames.push(`col-${props.width}`);
    } else if (props.shrink || props.grow) {
        classNames.push(`col-`);
    } else {
        classNames.push(`col-12`);
    }

    if (props.tablet) {
        classNames.push(`col-tablet-${props.tablet}`);
    }

    if (props.mobile) {
        classNames.push(`col-mobile-${props.mobile}`);
    }

    if (props.order) {
        classNames.push(`col-order-${props.order}`);
    }

    if (props.orderTablet) {
        classNames.push(`col-order-tablet-${props.order}`);
    }

    if (props.orderMobile) {
        classNames.push(`col-order-mobile-${props.orderMobile}`);
    }

    if (props.bg) {
        classNames.push(`bg-${props.bg}`);
    }

    if (props.bgImage) {
        styles.backgroundImage = `url('${props.bgImage}')`;
        classNames.push("col--bg");
    }

    if (props.bgPosition) {
        styles.backgroundSize = "auto";
        styles.backgroundPosition = props.bgPosition;
    }

    if (props.bgSize) {
        styles.backgroundSize = props.bgSize;
    }

    if (props.bgRepeat) {
        styles.backgroundRepeat = props.bgRepeat;
    }

    if (props.color) {
        classNames.push(`color-${props.color}`)
    }

    if (props.shrink) {
        classNames.push("flex-shrink")
    }

    if (props.grow) {
        classNames.push("flex-grow")
    }

    const classNamesString = classNames.join(" ");
    const fadeProps = {
        bottom: true,
        distance: '50px',
        delay: props.delay,
        onReveal: props.onReveal,
        ...props.fade,
    };

    if (props.noFade) {
        return (
            <div className={`col ${classNamesString}`} style={styles}>
                {props.children}
            </div>
        )
    } else {
        return (
            <div className={`col ${classNamesString}`} style={styles}>
                <Fade {...fadeProps}>
                    {props.children}
                </Fade>
            </div>
        );
    }
};

export default Col;
