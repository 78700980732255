import React from 'react';
import styled from 'styled-components/macro';
import * as gvars from '../../styles2/GlobalVariables';

const TriColumnFeaturesSingleColumnStyled = styled.div`
	background-color: white;
	padding-bottom: ${gvars.spacing.mSpace};
	margin-left: auto;
	margin-right: auto;
	margin-bottom: ${gvars.spacing.mSpace};
	box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.09);
	.img-row {
		clip-path: polygon(0 0, 100% 0, 100% 75%, 0% 100%);
		margin-bottom: ${gvars.spacing.lSpace};
		height: 200px;
		object-fit: cover;
		width: 100%;
	}
	.text-container {
		padding: 0 ${gvars.spacing.mSpace};
	}
	h2 {
		text-transform: uppercase;
		color: ${gvars.colours.orange};
		margin-bottom: ${gvars.spacing.sSpace};
	}
	h3 {
		color: ${gvars.colours.orange};
		margin-bottom: ${gvars.spacing.mSpace};
	}
	h4 {
		color: ${gvars.colours.darkgrey};
		margin-bottom: 0.4rem;
	}
	p:not(.large) {
		color: ${gvars.colours.darkgrey};
	}
	p.large {
		margin-bottom: ${gvars.spacing.lSpace};
	}
	.text-row {
		margin-bottom: ${gvars.spacing.mSpace};
		padding-bottom: ${gvars.spacing.mSpace};
		border-bottom: 1px solid ${gvars.colours.offwhite};
		p:last-of-type {
			margin-bottom: 0;
		}
		&:last-of-type {
			margin-bottom: 0;
			border-bottom: none;
			padding-bottom: 0;
		}
	}
	.text-row.multiple-p {
		p {
			margin-bottom: 1.5rem;
		}
	}
	img.icon {
		vertical-align: middle;
		margin-right: 0.3rem;
	}
	img.ISOCertificate {
		margin-top: ${gvars.spacing.mSpace};
	}

	/*************
 * DESKTOP QUERIES
*************/
	@media ${gvars.device.laptop} {
		margin-bottom: 0;
		h2 {
			font-size: 1.25rem;
			line-height: 1.6rem;
		}
		h3 {
		}
	}
`;

export default class TriColumnFeaturesSingleColumn extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return <TriColumnFeaturesSingleColumnStyled>{this.props.children /* this injects the content from wherever its used as a HOC, see bespoke.js as an example or remove this to see */}</TriColumnFeaturesSingleColumnStyled>;
	}
}

// export default OpeningSection;
